export interface AppException {
    id: string;
    title: string;
    description: string;
    status: 'Approved' | 'Pending' | 'Rejected';
    severity: 'Critical' | 'High' | 'Medium' | 'Low';
    createdDate: string;
    expirationDate: string;
    category: string;
    cwe?: string;
    justification: string;
}

export interface AppWithExceptions {
    id: string;
    name: string;
    description: string;
    policyName: string;
    lastScanDate: string;
    riskLevel: 'High' | 'Medium' | 'Low';
    exceptions: AppException[];
    stats: {
        totalExceptions: number;
        activeExceptions: number;
        expiringSoon: number;
        byStatus: {
            approved: number;
            pending: number;
            rejected: number;
        };
        bySeverity: {
            critical: number;
            high: number;
            medium: number;
            low: number;
        };
    };
}

export const mockAppExceptions: AppWithExceptions = {
    id: "APP-001",
    name: "Payment Processing Service",
    description: "Core payment processing application handling all financial transactions",
    policyName: "Financial Services Security Policy",
    lastScanDate: "2024-03-15T10:00:00Z",
    riskLevel: "High",
    stats: {
        totalExceptions: 5,
        activeExceptions: 3,
        expiringSoon: 2,
        byStatus: {
            approved: 12,
            pending: 2,
            rejected: 1
        },
        bySeverity: {
            critical: 1,
            high: 5,
            medium: 1,
            low: 1
        }
    },
    exceptions: [
        {
            id: "EXC-001",
            title: "Legacy Authentication Mechanism",
            description: "Exception for maintaining legacy authentication system during migration period",
            status: "Approved",
            severity: "High",
            createdDate: "2024-01-15T00:00:00Z",
            expirationDate: "2024-06-15T00:00:00Z",
            category: "Authentication",
            cwe: "287",
            justification: "Required for legacy system integration during phased migration"
        },
        {
            id: "EXC-002",
            title: "TLS 1.1 Support",
            description: "Temporary support for TLS 1.1 for legacy client systems",
            status: "Pending",
            severity: "Critical",
            createdDate: "2024-02-01T00:00:00Z",
            expirationDate: "2024-04-30T00:00:00Z",
            category: "Encryption",
            cwe: "326",
            justification: "Critical business clients require time for upgrade"
        },
        {
            id: "EXC-003",
            title: "Unencrypted Cache Storage",
            description: "Temporary exception for unencrypted cache storage",
            status: "Rejected",
            severity: "Medium",
            createdDate: "2024-02-15T00:00:00Z",
            expirationDate: "2024-05-15T00:00:00Z",
            category: "Data Protection",
            cwe: "311",
            justification: "Performance optimization for high-traffic periods"
        },
        {
            id: "EXC-004",
            title: "Missing Rate Limiting",
            description: "Exception for API rate limiting implementation",
            status: "Approved",
            severity: "Low",
            createdDate: "2024-03-01T00:00:00Z",
            expirationDate: "2024-08-01T00:00:00Z",
            category: "API Security",
            justification: "Legacy clients require unrestricted access"
        },
        {
            id: "EXC-005",
            title: "Outdated Dependencies",
            description: "Running with known vulnerable dependencies",
            status: "Pending",
            severity: "High",
            createdDate: "2024-03-10T00:00:00Z",
            expirationDate: "2024-05-10T00:00:00Z",
            category: "Dependencies",
            justification: "Testing required before upgrading critical components"
        }
    ]

};