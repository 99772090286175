import PolicyItem from "./PolicyItem";
import {Policy} from "./PolicyTypes";
import React from "react";

type PoliciesProps = {
    policies: Policy[]
}

const PolicyList: React.FC<PoliciesProps> = (
    {
        policies
    }) => {
    return (
        <div className={"table-list-wrapper"}>
            <table id={"policies"}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Active Scanner</th>
                    <th>Scan Configurations</th>
                    <th>Grace Periods</th>
                    <th className={"table-column-actions"}>Actions</th>
                </tr>
                </thead>
                {
                    policies.map(p => <PolicyItem policy={p} key={p.name}/>)
                }
            </table>
        </div>
    );
}

export default PolicyList;