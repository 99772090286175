import React from "react";
import {EnhancedSecurityRule} from "./RuleTypes";
import {MdEdit, MdDeleteForever} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import axios from "axios";

type Props = {
    rule: EnhancedSecurityRule,
    key?: string
};

export const RuleItem: React.FC<Props> = ({rule, key}) => {
    const navigate = useNavigate();

    const handleEdit = () => {
        navigate(`/rules/${rule.RuleId}`);
    };

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this rule?')) {
            try {
                await axios.delete(`/rules/${rule.RuleId}`);
                // You might want to trigger a refresh of the rules list here
                window.location.reload();
            } catch (error) {
                console.error('Error deleting rule:', error);
            }
        }
    };

    return (
        <tr>
            <td>{rule.RuleName}</td>
            <td>{rule.RuleExpressionType}</td>
            <td>
                <div className="expression-preview">
                    {rule.Expression.length > 50
                        ? `${rule.Expression.substring(0, 50)}...`
                        : rule.Expression}
                </div>
            </td>
            <td>
                <span className={`status-badge status-${rule.Status.toLowerCase()}`}>
                    {rule.Status}
                </span>
            </td>
            <td>
                {rule.LastModifiedDate
                    ? new Date(rule.LastModifiedDate).toLocaleDateString()
                    : new Date(rule.CreatedDate).toLocaleDateString()}
            </td>
            <td>
                {rule.NextReviewDate
                    ? new Date(rule.NextReviewDate).toLocaleDateString()
                    : 'Not scheduled'}
            </td>
            <td>
                <span className="action action-edit" title="Edit" onClick={handleEdit}>
                    <MdEdit/>
                </span>
                <span className="action action-delete" title="Delete" onClick={handleDelete}>
                    <MdDeleteForever/>
                </span>
            </td>
        </tr>
    );
};