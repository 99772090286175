import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

interface SectionCardProps {
    title: string;
    children: React.ReactNode;
    className?: string;
    defaultOpen?: boolean;
}

const SectionCard: React.FC<SectionCardProps> = ({ 
    title, 
    children, 
    className = '', 
    defaultOpen = true 
}) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);
    
    return (
        <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className={`section-card ${className}`}
        >
            <div className="section-header" onClick={() => setIsOpen(!isOpen)}>
                <h3>{title}</h3>
                {isOpen ? <MdExpandLess size={24} /> : <MdExpandMore size={24} />}
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="section-content"
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
};

export default SectionCard;