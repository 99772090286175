import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertTitle } from '../components/Alert';
import { MdSave } from 'react-icons/md';

type FieldType = 'text' | 'number' | 'date' | 'multiSelect' | 'boolean';
type Operator = string;
type GroupOperator = 'AND' | 'OR';

interface Field {
    value: string;
    label: string;
    type: FieldType;
    options?: { value: string; label: string }[];
    required?: boolean;
    validation?: (value: any) => string | null;
}

interface Condition {
    id: string;
    field: string;
    operator: Operator;
    value: string;
    valueEnd?: string;
    isValid: boolean;
    errorMessage?: string;
}

interface ConditionGroup {
    id: string;
    operator: GroupOperator;
    conditions: Condition[];
    groups: ConditionGroup[];
}


const VisualRuleBuilder: React.FC = () => {
    const navigate = useNavigate();
    const [rootGroup, setRootGroup] = useState<ConditionGroup>({
        id: 'root',
        operator: 'AND',
        conditions: [{
            id: '1',
            field: '',
            operator: '',
            value: '',
            isValid: true
        }],
        groups: []
    });
    const [errors, setErrors] = useState<string[]>([]);
    const [generatedExpression, setGeneratedExpression] = useState<string>('');

    const fields: Field[] = [
        {
            value: 'severity',
            label: 'Severity',
            type: 'multiSelect',
            required: true,
            options: [
                { value: 'critical', label: 'Critical' },
                { value: 'high', label: 'High' },
                { value: 'medium', label: 'Medium' },
                { value: 'low', label: 'Low' }
            ],
            validation: (value) => value ? null : 'Severity is required'
        },
        {
            value: 'cwe',
            label: 'CWE',
            type: 'number',
            validation: (value) => {
                if (!value) return 'CWE is required';
                if (isNaN(value)) return 'CWE must be a number';
                if (value < 0) return 'CWE must be positive';
                return null;
            }
        },
        {
            value: 'createdDate',
            label: 'Created Date',
            type: 'date',
            validation: (value) => {
                if (!value) return null;
                const date = new Date(value);
                return isNaN(date.getTime()) ? 'Invalid date' : null;
            }
        },
        { value: 'isExpired', label: 'Is Expired', type: 'boolean' },
        {
            value: 'category',
            label: 'Category',
            type: 'text',
            validation: (value) => value ? null : 'Category is required'
        },
        {
            value: 'justification',
            label: 'Justification',
            type: 'text'
        },
        {
            value: 'riskAssessment',
            label: 'Risk Assessment',
            type: 'text'
        },
        {
            value: 'proposedMitigation',
            label: 'Proposed Mitigation',
            type: 'text'
        },
        {
            value: 'expirationDate',
            label: 'Expiration Date',
            type: 'date',
            validation: (value) => {
                if (!value) return null;
                const date = new Date(value);
                return isNaN(date.getTime()) ? 'Invalid date' : null;
            }
        }
    ];

    const validateCondition = (condition: Condition): string[] => {
        const errors: string[] = [];
        const field = fields.find(f => f.value === condition.field);

        if (!field) return errors;

        if (field.required && !condition.value) {
            errors.push(`${field.label} is required`);
        }

        if (field.validation) {
            const error = field.validation(condition.value);
            if (error) errors.push(error);
        }

        if (condition.operator === 'between' && !condition.valueEnd) {
            errors.push(`End value is required for ${field.label}`);
        }

        return errors;
    };

    const updateGroup = (groupId: string, updates: Partial<ConditionGroup>) => {
        const updateGroupRecursive = (group: ConditionGroup): ConditionGroup => {
            if (group.id === groupId) {
                return { ...group, ...updates };
            }

            return {
                ...group,
                groups: group.groups.map(updateGroupRecursive)
            };
        };

        setRootGroup(updateGroupRecursive(rootGroup));
    };

    const addGroup = (parentGroupId: string) => {
        const newGroup: ConditionGroup = {
            id: Math.random().toString(36).substr(2, 9),
            operator: 'AND',
            conditions: [{
                id: Math.random().toString(36).substr(2, 9),
                field: '',
                operator: '',
                value: '',
                isValid: true
            }],
            groups: []
        };

        const addGroupRecursive = (group: ConditionGroup): ConditionGroup => {
            if (group.id === parentGroupId) {
                return {
                    ...group,
                    groups: [...group.groups, newGroup]
                };
            }

            return {
                ...group,
                groups: group.groups.map(addGroupRecursive)
            };
        };

        setRootGroup(addGroupRecursive(rootGroup));
    };

    const removeGroup = (groupId: string) => {
        const removeGroupRecursive = (group: ConditionGroup): ConditionGroup => {
            return {
                ...group,
                groups: group.groups
                    .filter(g => g.id !== groupId)
                    .map(removeGroupRecursive)
            };
        };

        setRootGroup(removeGroupRecursive(rootGroup));
    };

    const getOperators = (fieldType: FieldType): { value: string; label: string }[] => {
        switch (fieldType) {
            case 'number':
                return [
                    { value: 'eq', label: 'Equals' },
                    { value: 'gt', label: 'Greater Than' },
                    { value: 'lt', label: 'Less Than' },
                    { value: 'between', label: 'Between' }
                ];
            case 'date':
                return [
                    { value: 'eq', label: 'Equals' },
                    { value: 'before', label: 'Before' },
                    { value: 'after', label: 'After' },
                    { value: 'between', label: 'Between' }
                ];
            // ... other operator types
            default:
                return [
                    { value: 'eq', label: 'Equals' },
                    { value: 'contains', label: 'Contains' }
                ];
        }
    };

    const renderCondition = (condition: Condition, groupId: string) => {
        const field = fields.find(f => f.value === condition.field);

        return (
            <div key={condition.id} className="flex flex-wrap gap-2 items-center p-2 bg-gray-700 rounded">
                <select
                    className="bg-gray-600 p-2 rounded"
                    value={condition.field}
                    onChange={(e) => updateCondition(groupId, condition.id, 'field', e.target.value)}
                >
                    <option value="">Select Field</option>
                    {fields.map(field => (
                        <option key={field.value} value={field.value}>
                            {field.label}
                        </option>
                    ))}
                </select>

                {field && (
                    <>
                        <select
                            className="bg-gray-600 p-2 rounded"
                            value={condition.operator}
                            onChange={(e) => updateCondition(groupId, condition.id, 'operator', e.target.value)}
                        >
                            <option value="">Select Operator</option>
                            {getOperators(field.type).map(op => (
                                <option key={op.value} value={op.value}>
                                    {op.label}
                                </option>
                            ))}
                        </select>

                        {renderValueInput(condition, field, groupId)}
                    </>
                )}

                <button
                    onClick={() => removeCondition(groupId, condition.id)}
                    className="bg-red-600 p-2 rounded hover:bg-red-700"
                >
                    Remove
                </button>

                {!condition.isValid && condition.errorMessage && (
                    <div className="w-full mt-2">
                        <Alert variant="destructive">
                            <AlertTitle>{condition.errorMessage}</AlertTitle>
                        </Alert>
                    </div>
                )}
            </div>
        );
    };

    const renderGroup = (group: ConditionGroup, isRoot: boolean = false) => {
        return (
            <div className="p-4 border border-gray-600 rounded mt-4">
                {!isRoot && (
                    <div className="flex justify-between items-center mb-4">
                        <select
                            className="bg-gray-600 p-2 rounded"
                            value={group.operator}
                            onChange={(e) => updateGroup(group.id, { operator: e.target.value as GroupOperator })}
                        >
                            <option value="AND">AND</option>
                            <option value="OR">OR</option>
                        </select>

                        <button
                            onClick={() => removeGroup(group.id)}
                            className="bg-red-600 p-2 rounded hover:bg-red-700"
                        >
                            Remove Group
                        </button>
                    </div>
                )}

                {group.conditions.map(condition => renderCondition(condition, group.id))}

                {group.groups.map(subGroup => renderGroup(subGroup))}

                <div className="mt-4 flex gap-2">
                    <button
                        onClick={() => addCondition(group.id)}
                        className="bg-blue-600 p-2 rounded hover:bg-blue-700"
                    >
                        Add Condition
                    </button>
                    <button
                        onClick={() => addGroup(group.id)}
                        className="bg-green-600 p-2 rounded hover:bg-green-700"
                    >
                        Add Group
                    </button>
                </div>
            </div>
        );
    };

    const renderValueInput = (condition: Condition, field: Field, groupId: string) => {
        switch (field.type) {
            case 'date':
                return (
                    <input
                        type="date"
                        className="bg-gray-600 p-2 rounded"
                        value={condition.value}
                        onChange={(e) => updateCondition(groupId, condition.id, 'value', e.target.value)}
                    />
                );
            case 'number':
                return (
                    <input
                        type="number"
                        className="bg-gray-600 p-2 rounded"
                        value={condition.value}
                        onChange={(e) => updateCondition(groupId, condition.id, 'value', e.target.value)}
                    />
                );
            case 'boolean':
                return (
                    <select
                        className="bg-gray-600 p-2 rounded"
                        value={condition.value}
                        onChange={(e) => updateCondition(groupId, condition.id, 'value', e.target.value)}
                    >
                        <option value="">Select</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                );
            case 'multiSelect':
                return (
                    <select
                        className="bg-gray-600 p-2 rounded"
                        value={condition.value}
                        onChange={(e) => updateCondition(groupId, condition.id, 'value', e.target.value)}
                    >
                        <option value="">Select</option>
                        {field.options?.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                );
            default:
                return (
                    <input
                        type="text"
                        className="bg-gray-600 p-2 rounded"
                        value={condition.value}
                        onChange={(e) => updateCondition(groupId, condition.id, 'value', e.target.value)}
                    />
                );
        }
    };


    const addCondition = (groupId: string) => {
        const newCondition: Condition = {
            id: Math.random().toString(36).substr(2, 9),
            field: '',
            operator: '',
            value: '',
            isValid: true
        };

        updateGroup(groupId, {
            conditions: [...(rootGroup.conditions || []), newCondition]
        });
    };

    const removeCondition = (groupId: string, conditionId: string) => {
        updateGroup(groupId, {
            conditions: rootGroup.conditions.filter(c => c.id !== conditionId)
        });
    };

    const updateCondition = (groupId: string, conditionId: string, field: keyof Condition, value: string) => {
        const updatedConditions = rootGroup.conditions.map(condition => {
            if (condition.id === conditionId) {
                const updatedCondition = { ...condition, [field]: value };
                const validationErrors = validateCondition(updatedCondition);
                return {
                    ...updatedCondition,
                    isValid: validationErrors.length === 0,
                    errorMessage: validationErrors[0]
                };
            }
            return condition;
        });

        updateGroup(groupId, { conditions: updatedConditions });
    };

    const generateExpression = () => {
        const buildConditionString = (condition: Condition): string => {
            let expression = '';

            if (!condition.field || !condition.operator) return '';

            const operatorMap = {
                'eq': '==',
                'gt': '>',
                'lt': '<',
                'contains': '.Contains',
                'between': 'between'
            };

            const field = fields.find(f => f.value === condition.field);
            if (!field) return '';

            switch (field.type) {
                case 'boolean':
                    expression = `${condition.field} == ${condition.value}`;
                    break;
                case 'number':
                    if (condition.operator === 'between' && condition.valueEnd) {
                        expression = `(${condition.field} >= ${condition.value} && ${condition.field} <= ${condition.valueEnd})`;
                    } else {
                        expression = `${condition.field} ${operatorMap[condition.operator]} ${condition.value}`;
                    }
                    break;
                case 'date':
                    if (condition.operator === 'between' && condition.valueEnd) {
                        expression = `(${condition.field} >= DateTime.Parse("${condition.value}") && ${condition.field} <= DateTime.Parse("${condition.valueEnd}"))`;
                    } else {
                        expression = `${condition.field} ${operatorMap[condition.operator]} DateTime.Parse("${condition.value}")`;
                    }
                    break;
                default:
                    if (condition.operator === 'contains') {
                        expression = `${condition.field}.Contains("${condition.value}")`;
                    } else {
                        expression = `${condition.field} ${operatorMap[condition.operator]} "${condition.value}"`;
                    }
            }

            return expression;
        };

        const buildGroupString = (group: ConditionGroup): string => {
            const conditions = group.conditions
                .map(buildConditionString)
                .filter(Boolean);

            const subGroups = group.groups
                .map(buildGroupString)
                .filter(Boolean);

            const allParts = [...conditions, ...subGroups];

            if (allParts.length === 0) return '';
            if (allParts.length === 1) return allParts[0];

            return `(${allParts.join(` ${group.operator} `)})`;
        };

        let expression = buildGroupString(rootGroup);
        setGeneratedExpression(expression);
        return expression;
    };

   
    const handleCreateRule = () => {
        const expression = generateExpression();
        if (!expression) {
            setErrors(['Please build a valid rule before creating']);
            return;
        }

        // Navigate to create rule form with the generated expression
        navigate('/rules/create', {
            state: {
                expression,
                ruleType: 'LambdaExpression'
            }
        });
    };

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl mb-4">Build Your Rule</h2>
                <button
                    onClick={handleCreateRule}
                    className="inline-flex items-center gap-2 px-4 py-2 bg-[#4f5e80] text-black rounded-lg hover:bg-opacity-90 transition-colors"
                >
                    <MdSave size={20}/>
                    Create Rule
                </button>
            </div>

            {renderGroup(rootGroup, true)}

            <div className="mt-6 p-4 bg-gray-700 rounded">
                <h3 className="text-lg mb-2">Generated Expression:</h3>
                <code className="block p-4 bg-gray-800 rounded text-green-400 whitespace-pre-wrap">
                    {generatedExpression || 'Expression will appear here'}
                </code>
            </div>

            {errors.length > 0 && (
                <div className="mt-4">
                    {errors.map((error, index) => (
                        <Alert key={index} variant="destructive">
                            <AlertTitle>{error}</AlertTitle>
                        </Alert>
                    ))}
                </div>
            )}
            <div
                className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 flex items-center justify-center cursor-not-allowed">
                <span className="text-lg font-medium">Coming Soon: </span>
                <span className="text-sm ml-2">Test and debug your rules</span>
            </div>

        </div>
    );
};

export default VisualRuleBuilder;