import React, {useEffect} from "react";
import axios from "../common/axios-client";
import {useQuery, useQueryClient} from "react-query";
import {Link, useLocation} from "react-router-dom";
import ScanConfigurationList from "./ScanConfigurationList";
import {ScanConfiguration} from "./ScanCongifurationTypes";
import "./ScanConfigurations.scss";

const fetchScanConfigurations = async (): Promise<ScanConfiguration[]> => {
    let r = await axios.get("/scanConfigurations");
    console.log(r.data)
    return r.data.scanConfigurations
}

const ScanConfigurations : React.FC = () => {
    const {
        data,
        isLoading,
        isSuccess
    } = useQuery<ScanConfiguration[]>({
        queryKey: "scanConfigurations",
        queryFn: fetchScanConfigurations
    });

    const queryClient = useQueryClient()
    const location = useLocation()

    useEffect(() => {
        queryClient.invalidateQueries("scanConfigurations")
    }, [location, queryClient]);

    if (isLoading) {
        return <>Loading</>
    }

    if (!isSuccess) {
        return <>Loading failed, please, refresh the page</>
    }

    if (data.length === 0) {
        return (<>
            There are no Scan Configurations, you can <Link to={"/scanConfigurations/create"} className={"btn-main-inline"} >
            <span>create</span>
        </Link> one
        </>)
    }

    return (
        <>
            <div className={"wrap-btn-create"}>
                <Link to={"/scanConfigurations/create"} id={"btnCreateScanConfiguration"} className={"btn-with-icon btn-main"}>
                    <span>Create&nbsp;</span>
                </Link>
            </div>
            <ScanConfigurationList scanConfigurations={data} />
        </>
    )
}

export default ScanConfigurations