import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  TableSortLabel,
  IconButton,
  Menu
} from '@mui/material';
import axios from 'axios';
import { NavLink, useNavigate } from "react-router-dom";
import { AssetRecord } from './AssetRecord';
import { FaPlus } from 'react-icons/fa';
import { FaGithub } from "react-icons/fa6";
import { SiJira } from "react-icons/si";
import { BsShieldFillCheck, BsShieldFillX, BsShieldFillExclamation, BsShieldSlash, BsShieldFillMinus } from 'react-icons/bs';
import { MdStar, MdStarOutline } from 'react-icons/md';
import { LuSettings2 } from "react-icons/lu";
import { RiLockPasswordLine } from "react-icons/ri";
import './AssetRecords.scss';

import NewAssetModal from './NewAssetModal';

interface Props {
  items: AssetRecord[];
}

type SortConfig = {
  field: keyof AssetRecord | '';
  direction: 'asc' | 'desc';
};

interface ColumnConfig {
  field: keyof AssetRecord | 'actions';
  label: string;
  canHide: boolean;
}

const COLUMNS: ColumnConfig[] = [
  { field: 'canonicalId', label: 'Canonical Id', canHide: false },
  { field: 'productFamilyName', label: 'Product Family', canHide: true },
  { field: 'applicationName', label: 'Application', canHide: true },
  { field: 'componentName', label: 'Component', canHide: true },
  { field: 'psArchitects', label: 'ProdSec Architects', canHide: true },
  { field: 'productSecurityPM', label: 'ProdSec PM', canHide: true },
  { field: 'policyComplianceStatus', label: 'Policy Compliance', canHide: true },
  { field: 'lastCompletedScanDate', label: 'Last Scan', canHide: true },
  { field: 'actions', label: 'Actions', canHide: false }
];

const parseEmailToName = (email: string | null): string => {
  if (!email) return '';
  
  const namePart = email.split('@')[0];
  
  const parts = namePart.split(/[._]/).map(part => 
    part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  );
  
  if (parts.length >= 2) {
    const lastName = parts.pop();
    const firstName = parts.join(' ');
    return `${firstName} ${lastName}`;
  }
  
  return parts[0] || '';
};

const formatNames = (emails: (string | null)[] | null): string => {
  if (!emails || !Array.isArray(emails)) return '';
  
  return emails
    .filter(email => email)
    .map(parseEmailToName)
    .filter(name => name)
    .join(', ');
};

export const AssetRecords: React.FC<Props> = ({ items: initialItems }) => {
  const [items, setItems] = useState(initialItems);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchQuery, setSearchQuery] = useState(() => {
    const saved = localStorage.getItem('searchQuery');
    return saved || '';
  });
  const [sortConfig, setSortConfig] = useState<SortConfig>({ field: 'canonicalId', direction: 'asc' });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [columnMenuAnchor, setColumnMenuAnchor] = useState<null | HTMLElement>(null);
  
  const [visibleColumns, setVisibleColumns] = useState<Set<string>>(() => {
    const saved = localStorage.getItem('visibleColumns');
    return saved ? new Set(JSON.parse(saved)) : new Set(COLUMNS.map(col => col.field));
  });

  const [activeFilters, setActiveFilters] = useState<Set<string>>(() => {
    const saved = localStorage.getItem('activeFilters');
    return saved ? new Set(JSON.parse(saved)) : new Set();
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchQuery(newValue);
    localStorage.setItem('searchQuery', newValue);
  };

  const handleSort = (field: keyof AssetRecord) => {
    setSortConfig(prev => ({
      field,
      direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const toggleColumn = (field: string) => {
    setVisibleColumns(prev => {
      const newSet = new Set(prev);
      if (newSet.has(field)) {
        newSet.delete(field);
      } else {
        newSet.add(field);
      }
      localStorage.setItem('visibleColumns', JSON.stringify(Array.from(newSet)));
      return newSet;
    });
  };

  const toggleFilter = (field: string) => {
    setActiveFilters(prev => {
      const newSet = new Set(prev);
      if (newSet.has(field)) {
        newSet.delete(field);
      } else {
        newSet.add(field);
      }
      localStorage.setItem('activeFilters', JSON.stringify(Array.from(newSet)));
      return newSet;
    });
  };

  const getPolicyComplianceIcon = (status: string) => {
    switch (status) {
      case "PASSED":
        return <BsShieldFillCheck size={15} />;
      case "DID_NOT_PASS":
        return <BsShieldFillX size={15} />;
      case "CONDITIONAL_PASS":
        return <BsShieldFillExclamation size={15} />;
      case "NOT_ASSESSED":
        return <BsShieldSlash size={15} />;
      case "DETERMINING":
        return <BsShieldFillMinus size={15} />;
      default:
        return null;
    }
  };

  const getScanDateColor = (scanDate: string) => {
    const currentDate = new Date();
    const lastCompletedScanDate = new Date(scanDate);
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
    
    return lastCompletedScanDate < thirtyDaysAgo ? "#fd4433" : "#1EB980";
  };

  const addRemoveToFavorites = async (applicationId: string | undefined, assetId: string) => {
    try {
      await axios.post(`/favorites/${applicationId}`);
      const appResponse = await axios.get(`/assets/${assetId}`);
      setItems(prev => prev.map(item => 
        item.id === assetId ? appResponse.data : item
      ));
    } catch (error) {
      console.error('Error updating favorites:', error);
    }
  };

  const handleSecretsScanner = (repoUrl) => {
    const repo = repoUrl.split('/').pop() || '';
    navigate(`/secrets-scanner?repo=${encodeURIComponent(repo)}`);
  };

  const filteredAndSortedData = useMemo(() => {
    let processed = [...items];

    if (searchQuery) {
      const searchLower = searchQuery.toLowerCase();
      processed = processed.filter(item =>
        item.canonicalId?.toLowerCase().includes(searchLower) ||
        item.productFamilyName?.toLowerCase().includes(searchLower) ||
        item.applicationName?.toLowerCase().includes(searchLower) ||
        item.componentName?.toLowerCase().includes(searchLower)
      );
    }

    if (activeFilters.has('isProduction')) {
      processed = processed.filter(item => item.isProduction);
    }
    if (activeFilters.has('isFlagship')) {
      processed = processed.filter(item => item.isFlagship);
    }
    if (activeFilters.has('isDraft')) {
      processed = processed.filter(item => item.isDraft);
    }

    if (sortConfig.field) {
      processed.sort((a, b) => {
        if (sortConfig.field === 'psArchitects' || sortConfig.field === 'productSecurityPM') {
          const aValue = formatNames([a[sortConfig.field][0] || '']);
          const bValue = formatNames([b[sortConfig.field][0] || '']);
          return sortConfig.direction === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }
        
        const aValue = String(a[sortConfig.field] || '');
        const bValue = String(b[sortConfig.field] || '');
        return sortConfig.direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
    }

    return processed;
  }, [items, searchQuery, sortConfig, activeFilters]);

  return (
    <div className="application-table-container">
      <div className="application-table-search">
        <button onClick={() => setIsAddModalOpen(true)} className="add-threat-model-btn">
          <FaPlus /> Add New Asset
        </button>
        <button onClick={(e) => setColumnMenuAnchor(e.currentTarget)} className="add-threat-model-btn">
          <LuSettings2 /> Filters
        </button>
        <TextField
          placeholder="Search by CanID, Family or App"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>

      <Menu
        anchorEl={columnMenuAnchor}
        open={Boolean(columnMenuAnchor)}
        onClose={() => setColumnMenuAnchor(null)}
        PaperProps={{
          className: "column-menu-paper"
        }}
      >
        <div className="filter-section">
          <h3>Columns</h3>
          {COLUMNS.filter(col => col.canHide).map((column) => (
            <div key={column.field} className="checkbox-container">
              <input
                type="checkbox"
                id={`col-${column.field}`}
                checked={visibleColumns.has(column.field)}
                onChange={() => toggleColumn(column.field)}
              />
              <span>{column.label}</span>
            </div>
          ))}
        </div>
        <div className="filter-section">
          <h3>Asset Type</h3>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="filter-production"
              checked={activeFilters.has('isProduction')}
              onChange={() => toggleFilter('isProduction')}
            />
            <span>Production</span>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="filter-flagship"
              checked={activeFilters.has('isFlagship')}
              onChange={() => toggleFilter('isFlagship')}
            />
            <span>Flagship</span>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="filter-draft"
              checked={activeFilters.has('isDraft')}
              onChange={() => toggleFilter('isDraft')}
            />
            <span>Draft</span>
          </div>
        </div>
      </Menu>

      <TableContainer component={Paper} className="application-table-paper">
        <Table>
          <TableHead>
            <TableRow>
              {COLUMNS.map((column) => (
                visibleColumns.has(column.field) && (
                  <TableCell key={column.field}>
                    {column.field !== 'actions' ? (
                      <TableSortLabel
                        active={sortConfig.field === column.field}
                        direction={sortConfig.direction}
                        onClick={() => handleSort(column.field as keyof AssetRecord)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : column.label}
                  </TableCell>
                )
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id} hover>
                  {COLUMNS.map((column) => (
                    visibleColumns.has(column.field) && (
                      <TableCell key={column.field}>
                        {column.field === 'canonicalId' && (
                          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <NavLink to={`/assets/${row.id}`} className="application-table-link">
                              {row.canonicalId}
                            </NavLink>
                            <div className="status-indicators">
                              {row.isProduction && <span className="status-dot production" title="Production">P</span>}
                              {row.isFlagship && <span className="status-dot flagship" title="Flagship">F</span>}
                              {row.isDraft && <span className="status-dot draft" title="Draft">D</span>}
                            </div>
                          </div>
                        )}
                        {column.field === 'productFamilyName' && row.productFamilyName}
                        {column.field === 'applicationName' && row.applicationName}
                        {column.field === 'componentName' && row.componentName}
                        {column.field === 'psArchitects' && formatNames(row.psArchitects)}
                        {column.field === 'productSecurityPM' && formatNames(row.productSecurityPM)}
                        {column.field === 'policyComplianceStatus' && (
                          <div className={`policyComplianceStatus ${row.policyComplianceStatus}`}>
                            {getPolicyComplianceIcon(row.policyComplianceStatus)}
                            {row.policyComplianceStatus}
                          </div>
                        )}
                        {column.field === 'lastCompletedScanDate' && row.applicationId && (
                          <span style={{ color: getScanDateColor(row.lastCompletedScanDate) }}>
                            {new Date(row.lastCompletedScanDate).toLocaleDateString()}
                          </span>
                        )}
                        {column.field === 'actions' && (
                          <>
                            {row.applicationId && (
                              <IconButton size="small" onClick={() => addRemoveToFavorites(row.applicationId, row.id)} title="Mark/Unmark as Favorite">
                              {row.isInFavorites ? <MdStar size={21}/> : <MdStarOutline size={21}/>}
                              </IconButton>
                            )}
                            {row.canonicalId && (
                              <IconButton
                                size="small"
                                component="a"
                                href={`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${row.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done`}
                                target="_blank"
                                title="View Related JIRA Issues"
                              >
                                <SiJira />
                              </IconButton>
                            )}
                            {(row.repoUrl || row.githubRepoLink) && (
                              <>
                                <IconButton
                                  size="small"
                                  component="a"
                                  href={row.repoUrl || row.githubRepoLink}
                                  target="_blank"
                                  title="View Repository"
                                >
                                  <FaGithub />
                                </IconButton>
                                <IconButton 
                                  size="small"
                                  onClick={() => handleSecretsScanner(row.repoUrl || row.githubRepoLink)}
                                  title="View Discovered Secrets"
                                >
                                  <RiLockPasswordLine />
                                </IconButton>
                              </>
                            )}
                          </>
                        )}
                      </TableCell>
                    )
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={filteredAndSortedData.length}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
        className="application-table-pagination"
      />

      <NewAssetModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />
    </div>
  );
};

export default AssetRecords;