import React, {useEffect, useState} from "react";
import "./Forms.scss"
import axios from "../common/axios-client";
import {ScanConfiguration} from "./ScanCongifurationTypes";
import {useNavigate} from "react-router-dom";


export const PolicyCreate: React.FC = () => {

    const defaultScanConfigurations: ScanConfiguration[] = []

    const [scanConfigurations, setScanConfigurations] = useState(defaultScanConfigurations)
    const [validationFailures, setValidationFailures] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            let r = await axios.get(`/scanConfigurations`);
            console.log(r.data)
            setScanConfigurations(r.data.scanConfigurations)
        }

        // TODO: catch
        fetchData()
    }, [])

    const navigate = useNavigate()

    function handleCancel(e: React.SyntheticEvent) {
        e.preventDefault()
        navigate("/policies")
    }

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const name = e.currentTarget.policyName.value
        const description = e.currentTarget.policyDescription.value

        const scanConfigurationCheckboxes: HTMLInputElement[] | HTMLInputElement = e.currentTarget.policyScanConfigurations
        let checkedScanConfigurationIds: string[] = []

        if (Array.isArray(scanConfigurationCheckboxes))
        {
            checkedScanConfigurationIds = Array.from<HTMLInputElement>(scanConfigurationCheckboxes)
                .filter(x => x.checked)
                .map(x => x.value)
        }
        else if (scanConfigurationCheckboxes.checked) {
            checkedScanConfigurationIds.push(scanConfigurationCheckboxes.value)
        }

        console.log(e)

        const payload = {
            name: name,
            description: description,
            scanConfigurationIds: checkedScanConfigurationIds,
            gracePeriods: {}
        }

        await axios.post("/policies", payload)
            .then(() => {
                navigate("/policies")
            })
            .catch(err => {
                console.error(e)

                if (err.code === "ERR_BAD_REQUEST") {
                    const failures = err.response.data.validationFailures
                    setValidationFailures(failures)
                }
            })
    }

    return (
        <>
            <h1>Create Policy</h1>
            {validationFailures.length > 0 &&
                <table className={"validation-failures"}>
                    <tbody>
                    {validationFailures.map(x => <tr>
                        <td>{x}</td>
                    </tr>)}
                    </tbody>
                </table>
            }
            <form autoComplete={"off"} onSubmit={handleSubmit}>
                <div className={"form-control"}>
                    <label htmlFor="#policyName">Name:</label>
                    <input type="text" id={"policyName"}/>
                </div>
                <div className={"form-control"}>
                    <label htmlFor="#policyDescription">Description:</label>
                    <input type="text" id={"policyDescription"}/>
                </div>
                <div className={"form-control"}>
                    <label htmlFor="#policyScanConfigurations">Scan Configurations</label>
                    <div className={"multiselect-checkbox-wrap"}>
                        {scanConfigurations.map(x =>
                            <div key={x.id}>
                                <input type="checkbox" name={"policyScanConfigurations"}
                                       value={x.id}></input><span>&nbsp;{x.name}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={"form-control"}>
                    <input type="button" id={"policyCreateCancel"} value={"Cancel"} onClick={handleCancel}/>
                    <input type="submit" id={"policyCreate"} value={"Create"}/>
                </div>
            </form>
        </>
    )
};