import React, { useState, useEffect } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import RequestInterceptor from '../common/RequestInterceptor';

type RouteGuardProps = {
    roles: string[],
    children: JSX.Element
}

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const RouteGuard: React.FC<RouteGuardProps> = ({ roles, children }) => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);

    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        const onLoad = async () => {
            if (account && account.idTokenClaims && account.idTokenClaims['roles']) {

                let intersection = roles
                    .filter((role: any) => account.idTokenClaims!['roles']!.includes(role));

                setIsAuthorized(intersection.length > 0);
            }
        };

        onLoad();
    }, [account, roles]);

    return (
        <>{
            isAuthorized ?
                (
                    <div>
                        <RequestInterceptor>
                            {children}
                        </RequestInterceptor>
                    </div>
                ) :
                (
                    <h3>You are unauthorized to view this content.</h3>
                )
        }</>
    );
};
