import React from 'react';
import { RiLockPasswordLine } from "react-icons/ri";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { motion } from "framer-motion";

interface SecretStats {
    total: number;
    keywords: number;
    jwt: number;
    entropy: number;
    other: number;
}

const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
};

const AnimatedNumber: React.FC<{ value: number }> = ({ value }) => {
    return (
        <motion.span
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, type: "spring" }}
        >
            <motion.span
                key={value}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, type: "spring" }}
            >
                {value}
            </motion.span>
        </motion.span>
    );
};

const AnimatedCard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <motion.div 
            className="stat-card"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3 }}
        >
            {children}
        </motion.div>
    );
};

const AnimatedStatItem: React.FC<{ 
    icon?: React.ReactNode;
    label: string;
    value: number;
    index: number;
}> = ({ icon, label, value, index }) => {
    return (
        <motion.div 
            className="stat-item"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3, delay: index * 0.1 }}
        >
            {icon ? icon : <span className="type-indicator"></span>}
            <span>{value} {label}</span>
        </motion.div>
    );
};

export const SecretsScannerCard: React.FC<{ secretStats: SecretStats }> = ({ secretStats }) => {
    return (
        <AnimatedCard>
            <div className="card-header">
                <div className="title">
                    <RiLockPasswordLine size={18} />
                    <span>Secrets Scanner</span>
                </div>
            </div>
            <div className="card-content">
                <div className="main-stat">
                    <span className="number">
                        <AnimatedNumber value={secretStats.total} />
                    </span>
                    <span className="label">Total Secrets Discovered</span>
                    <div className="chart-container" style={{ height: '120px', marginTop: '1rem' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={[
                                        { name: 'Secret Keywords', value: secretStats.keywords, color: '#1EB980' },
                                        { name: 'JWT', value: secretStats.jwt, color: '#FFCF44' },
                                        { name: 'High Entropy', value: secretStats.entropy, color: '#60a5fa' },
                                        { name: 'Other', value: secretStats.other, color: '#6b7280' }
                                    ]}
                                    innerRadius={25}
                                    outerRadius={40}
                                    paddingAngle={2}
                                    dataKey="value"
                                >
                                    {[
                                        { color: '#1EB980' },
                                        { color: '#FFCF44' },
                                        { color: '#60a5fa' },
                                        { color: '#6b7280' }
                                    ].map((entry, index) => (
                                        <Cell key={index} fill={entry.color} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="stat-breakdown">
                    <AnimatedStatItem
                        label="Secret Keywords"
                        value={secretStats.keywords}
                        index={0}
                    />
                    <AnimatedStatItem
                        label="JWT"
                        value={secretStats.jwt}
                        index={1}
                    />
                    <AnimatedStatItem
                        label="High Entropy Strings"
                        value={secretStats.entropy}
                        index={2}
                    />
                    <AnimatedStatItem
                        label="Other"
                        value={secretStats.other}
                        index={3}
                    />
                </div>
            </div>
        </AnimatedCard>
    );
};