export enum ScanType {
    Sast,
    Dast,
    Sca
}

export type ScanConfiguration = {
    id: string,
    name: string,
    scanType: ScanType,
    commonWeaknessEnumerationIds: number[]
}