import React, { useEffect, useState } from 'react';
import { LineChartRequest } from './LineChartRequest';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';
import { Legend, Tooltip, ResponsiveContainer, PieChart, Cell, Pie } from 'recharts';
import JiraStatisticFilter from '../shared/JiraStatisticFilter';
import axios from 'axios';

import './VulnerabilityRemediationExpandedChart.scss';

import { JiraStatisticAppFamilyFilter, JiraStatisticScale } from '../shared/JiraStatisticFilterConstants';
import LineChartBase from './LineChartBase';
import { ReductionGoal } from './ReductionGoal';

interface ReductionGoalChartProps {
    applications: MultiselectOption[];
    productFamilies: MultiselectOption[];
}

const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">{payload[0].name}</p>
                <p style={{ color: payload[0].color }}>
                    {`${payload[0].value} weeks`}
                </p>
            </div>
        );
    }
    return null;
};

const CustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name }: any) => {
   const RADIAN = Math.PI / 180;
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
   const x = cx + radius * Math.cos(-midAngle * RADIAN);
   const y = cy + radius * Math.sin(-midAngle * RADIAN);

   return (
     <text 
       x={x} 
       y={y} 
       fill="white" 
       textAnchor="middle" 
       dominantBaseline="central"
     >
       {name}
     </text>
   );
};

const RatioOfIntroducedResolvedChart: React.FC<ReductionGoalChartProps> = ({ applications, productFamilies }) => {
    const defaultData: ReductionGoal[]= [];
    const [data, setData] = useState<ReductionGoal[]>(defaultData);
    const {selectedNames, selectedScale, selectedDateRange, selectedNameType, appChanged, appFamilyChanged, scaleChanged, dateRangeChanged} = LineChartBase();

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'violet'];

    const requestData = async () => {
        let request: LineChartRequest = {
            scale: selectedScale ?? JiraStatisticScale.Week,
            startDate: selectedDateRange.startDate ?? new Date(),
            endDate: selectedDateRange.endDate ?? new Date(),
            nameType: selectedNameType ?? JiraStatisticAppFamilyFilter.Application,
            selectedNames: selectedNames ?? []
        }
        
        const response = await axios.post('/jirastatistic/getReductionGoal', request)
        setData(response.data.map((x: ReductionGoal) => {
            x.weeksCount = Math.ceil(x.weeksCount);
            return x;
        }));
    }

    useEffect(() => {
        if(selectedNames && selectedNames.length > 0){
            requestData();
        }
        // eslint-disable-next-line
    }, [selectedNames, selectedScale, selectedDateRange, selectedNameType]);

    return (
        <div className='statistic-pane'>
            <div className='chart-title'>
                <h4>Total Weeks Where 15% Reduction Goal Not Met</h4>
            </div>
            <JiraStatisticFilter 
                applications={applications} 
                applicationChangedCallback={appChanged} 
                productFamilies={productFamilies} 
                productFamilyChangedCallback={appFamilyChanged}
                scaleChangedCallback={scaleChanged}
                dateRangeChangedCallback={dateRangeChanged}
                defaultAppFamilyFilter={selectedNameType}
                defaultStartDate={selectedDateRange.startDate}
                defaultEndDate={selectedDateRange.endDate}
                defaultScale={selectedScale}
                showScale={false}
                id="reduction"
            />
            
            {data.length === 0 && 
                <div className='chart'><label className='text-info'>No data available</label></div>
            }
            {data.length > 0 &&
                <div className='chart'>
                    <div className='chart-pair'>
                        <div className='one-chart'>
                            <ResponsiveContainer height={400} width="100%">
                                <PieChart>
                                    <Pie
                                        data={data}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        nameKey="scanType"
                                        label={CustomLabel}
                                        outerRadius={150}
                                        fill="#8884d8"
                                        dataKey="weeksCount"
                                    >
                                        {data.map((entry, index) => (
                                            <Cell 
                                                key={`cell-${index}`} 
                                                fill={COLORS[index % COLORS.length]} 
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend 
                                        height={36}
                                        formatter={(value: string) => (
                                            <span style={{ color: '#666' }}>{value}</span>
                                        )}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default RatioOfIntroducedResolvedChart;