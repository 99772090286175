import React, { useState } from "react";
import { 
    Table, 
    TableContainer, 
    TableHead, 
    TableBody, 
    TableRow, 
    TableCell, 
    Paper,
    TablePagination
} from '@mui/material';
import { VeracodeApplicationItem } from "./VeracodeApplicationItem";
import { VeracodeApplication } from "./VeracodeApplication";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md"; // Using the same icons as ApplicationList

type ApplicationProps = {
    applications: VeracodeApplication[]
}

export const VeracodeApplicationList: React.FC<ApplicationProps> = ({ applications }) => {
    const [page, setPage] = useState(0);
    const rowsPerPage = 15;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    return (
        <div className="application-table-container">
            <TableContainer component={Paper} className="application-table-paper">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Application</TableCell>
                            <TableCell>Policy</TableCell>
                            <TableCell>Last Completed Scan</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {applications
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(x => <VeracodeApplicationItem key={x.id} item={x} />)}
                    </TableBody>
                </Table>
            </TableContainer>
            
            <TablePagination
                component="div"
                count={applications.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[15]}
                className="application-table-pagination"
                labelDisplayedRows={({ from, to, count }) => 
                    `${from}-${to} of ${count}`
                }
                backIconButtonProps={{
                    children: <MdNavigateBefore />
                }}
                nextIconButtonProps={{
                    children: <MdNavigateNext />
                }}
            />
        </div>
    );
}