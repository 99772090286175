import React from 'react';

interface AlertProps {
    children: React.ReactNode,
    variant?: 'default' | 'destructive',
    className?: string,
    key?: number
}

export const Alert: React.FC<AlertProps> = ({
                                                children,
                                                variant = 'default',
                                                className = '',
                                                key
                                            }) => {
    const baseStyles = 'p-4 mb-4 rounded-lg';
    const variantStyles = {
        default: 'bg-gray-700 text-gray-100',
        destructive: 'bg-red-900/50 text-red-300 border border-red-700'
    };

    return (
        <div className={`${baseStyles} ${variantStyles[variant]} ${className}`}>
            {children}
        </div>
    );
};

export const AlertTitle: React.FC<{ children: React.ReactNode }> = ({children}) => {
    return (
        <h5 className="font-medium mb-1">
            {children}
        </h5>
    );
};

export const AlertDescription: React.FC<{ children: React.ReactNode }> = ({children}) => {
    return (
        <div className="text-sm leading-relaxed opacity-90">
            {children}
        </div>
    );
};