import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TextField,
    IconButton
} from '@mui/material';
import { useQuery } from 'react-query';
import { useNavigate } from "react-router-dom";
import { FaPlus } from 'react-icons/fa';
import { MdEdit, MdDelete, MdCheckCircle, MdCancel } from 'react-icons/md';
import axios from 'axios';
import AnimatedShieldLoader from '../../AnimatedShieldLoader';

interface SecurityException {
    id: string;
    title: string;
    description: string;
    applicationId: string;
    createdDate: string;
    expirationDate: string;
    currentStatus: string;
    category: string;
    severity: number;
    justification: string;
}

const ExceptionList: React.FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    const { data: exceptions, isLoading, isError, refetch } = useQuery<SecurityException[]>(
        'exceptions',
        async () => {
            const response = await axios.get('/api/exceptions');
            return response.data;
        }
    );

    const handleDelete = async (id: string) => {
        if (window.confirm('Are you sure you want to delete this exception?')) {
            await axios.delete(`/api/exceptions/${id}`);
            refetch();
        }
    };

    const filteredExceptions = exceptions?.filter(exception =>
        exception.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        exception.description.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

    const getSeverityColor = (severity: number) => {
        switch (severity) {
            case 1: return '#1EB980'; // Low
            case 2: return '#FFCF44'; // Medium
            case 3: return '#FF6859'; // High
            case 4: return '#fd4433'; // Critical
            default: return '#6b7280';
        }
    };

    const getStatusIcon = (status: string) => {
        switch (status) {
            case 'Approved':
                return <MdCheckCircle style={{ color: '#1EB980' }} />;
            case 'Rejected':
                return <MdCancel style={{ color: '#fd4433' }} />;
            default:
                return null;
        }
    };

    if (isLoading) {
        return <div id="loader"><AnimatedShieldLoader /></div>;
    }

    if (isError) {
        return <div>Error loading exceptions</div>;
    }

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold">Security Exceptions</h1>
                <button
                    onClick={() => navigate('/exception-manager/create')}
                    className="flex items-center gap-2 bg-[#4f5e80] text-white px-4 py-2 rounded-lg hover:bg-opacity-90"
                >
                    <FaPlus /> Create Exception
                </button>
            </div>

            <div className="mb-4">
                <TextField
                    placeholder="Search exceptions..."
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="bg-[#444444] rounded-lg"
                />
            </div>

            <TableContainer component={Paper} className="bg-[#373740] rounded-lg">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Severity</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell>Expiration Date</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredExceptions
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((exception) => (
                                <TableRow key={exception.id} hover>
                                    <TableCell>{exception.title}</TableCell>
                                    <TableCell>{exception.category}</TableCell>
                                    <TableCell>
                                        <span
                                            className="px-2 py-1 rounded"
                                            style={{
                                                backgroundColor: `${getSeverityColor(exception.severity)}20`,
                                                color: getSeverityColor(exception.severity)
                                            }}
                                        >
                                            {['Low', 'Medium', 'High', 'Critical'][exception.severity - 1]}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <div className="flex items-center gap-2">
                                            {getStatusIcon(exception.currentStatus)}
                                            {exception.currentStatus}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {new Date(exception.createdDate).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(exception.expirationDate).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>
                                        <div className="flex gap-2">
                                            <IconButton
                                                size="small"
                                                onClick={() => navigate(`/exception-manager/${exception.id}`)}
                                            >
                                                <MdEdit />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleDelete(exception.id)}
                                            >
                                                <MdDelete />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={filteredExceptions.length}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
                className="mt-4"
            />
        </div>
    );
};

export default ExceptionList;