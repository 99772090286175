import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { Favorite } from "./Favorite"
import { FavoriteItem } from "./FavoritesItem";

type Favorites = {
    items: Favorite[]
    refreshFn: Function
}

export const FavoritesList: React.FC<Favorites> = ({ items, refreshFn }) => {
    return (
        <div className="application-table-container">
            <TableContainer component={Paper} className="application-table-paper">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Application</TableCell>
                            <TableCell>GitHub Workflow Name</TableCell>
                            <TableCell>Repo</TableCell>
                            <TableCell>SCA-R Status</TableCell>
                            <TableCell>SAST Status</TableCell>
                            <TableCell>Policy</TableCell>
                            <TableCell>Policy Compliance</TableCell>
                            <TableCell>Last Scan</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map(x => <FavoriteItem key={x.id} item={x} refreshFn={refreshFn} />)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}