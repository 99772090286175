import React from 'react';
import { motion } from 'framer-motion';
import { FaJedi, FaEmpire } from 'react-icons/fa';
import { useTheme } from './ThemeContext';
import './SettingsPage.scss';

export const SettingsPage: React.FC = () => {
  const { isDark, toggleTheme } = useTheme();

  return (
    <div className="settings-page">
      <div className="settings-section">
        <h2>Choose Your Path</h2>
        <div className="theme-control">
          <span className="force-label">
            {isDark ? 'Dark Side' : 'Light Side'}
          </span>
          <button 
            className={`force-switch ${isDark ? 'dark' : 'light'}`}
            onClick={toggleTheme}
            aria-label="Toggle theme"
          >
            <div className="switch-background">
              <div className="stars">
                {[...Array(20)].map((_, i) => (
                  <div key={i} className="star" style={{
                    left: `${Math.random() * 100}%`,
                    top: `${Math.random() * 100}%`,
                    animationDelay: `${Math.random() * 3}s`
                  }}/>
                ))}
              </div>
            </div>
            <motion.div 
              className="force-symbol"
              animate={{
                x: isDark ? 32 : 0,
                rotate: isDark ? 180 : 0
              }}
              transition={{
                duration: 0.3,
                ease: "easeInOut"
              }}
            >
              {isDark ? <FaEmpire /> : <FaJedi />}
            </motion.div>
          </button>
        </div>
      </div>
    </div>
  );
};