import React, { useEffect, useState } from 'react';
import { BsGraphUp } from "react-icons/bs";
import { 
    BarChart, 
    Bar,
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    Legend, 
    ResponsiveContainer,
    Label 
} from 'recharts';
import axios from 'axios';
import { motion } from "framer-motion";
import { VulnerabilityRemediationExpanded } from '../JiraStatistic/VulnerabilityRemediationExpanded';
import { JiraStatisticScale, JiraStatisticAppFamilyFilter } from '../shared/JiraStatisticFilterConstants';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';

interface CombinedDataPoint {
    timeStamp: string;
    [key: string]: string | number;
}

interface CustomTooltipProps {
    active?: boolean;
    payload?: Array<{
        name: string;
        value: number;
        color: string;
    }>;
    label?: string;
}

const COLORS = {
    'SAST': {
        open: '#B7ADD0',
        closed: '#B7ADD0'
    },
    'SCA': {
        open: '#1EB980',
        closed: '#1EB980'
    },
    'DAST': {
        open: '#6F48E3',
        closed: '#6F48E3'
    },
    'Pentest': {
        open: '#FFCF44',
        closed: '#FFCF44'
    },
    'Bug Bounty': {
        open: '#3B82F6',
        closed: '#3B82F6'
    }
};

const SCAN_TYPES = ['SAST', 'SCA', 'DAST', 'Pentest', 'Bug Bounty'];

const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const formatDate = (dateString: string) => {
    return dateString.split('T')[0];
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">{`Date: ${label}`}</p>
                {payload.map((entry, index) => (
                    <p key={index} style={{ color: entry.color }}>
                        {`${entry.name}: ${entry.value}`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

export const VulnerabilitiesChartCard = () => {
    const [data, setData] = useState<CombinedDataPoint[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            try {
                const endDate = new Date();
                const startDate = new Date();
                startDate.setMonth(endDate.getMonth() - 1);

                const familiesResponse = await axios.get('/SecurityMetadata/app-and-families');
                if (!mounted) return;

                const families = familiesResponse.data.families as MultiselectOption[];
                const allFamilies = families.map(f => f.value);

                const vulnerabilityResponse = await axios.post('/jirastatistic/getVulnerabilityRemediationExpanded', {
                    scale: JiraStatisticScale.Week,
                    startDate: startDate,
                    endDate: endDate,
                    nameType: JiraStatisticAppFamilyFilter.ProductFamily,
                    selectedNames: allFamilies
                });

                if (!mounted) return;

                if (!vulnerabilityResponse.data || !Array.isArray(vulnerabilityResponse.data)) {
                    throw new Error('Invalid response data format');
                }

                const combinedData: { [key: string]: CombinedDataPoint } = {};

                vulnerabilityResponse.data.forEach((item: VulnerabilityRemediationExpanded) => {
                    item.statusCountsData.forEach((stat) => {
                        const date = formatDate(stat.timeStamp.toString());
                        
                        if (!combinedData[date]) {
                            combinedData[date] = {
                                timeStamp: date,
                                ...SCAN_TYPES.reduce((acc, type) => ({
                                    ...acc,
                                    [`${type}-open`]: 0,
                                    [`${type}-closed`]: 0
                                }), {})
                            };
                        }
                        
                        combinedData[date][`${item.scanType}-open`] = Math.ceil(stat.open);
                        combinedData[date][`${item.scanType}-closed`] = Math.ceil(stat.closed);
                    });
                });

                const processedData = Object.values(combinedData)
                    .sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());
                
                if (mounted) {
                    setData(processedData);
                    setError(null);
                }
            } catch (error) {
                console.error('Error fetching vulnerability data:', error);
                if (mounted) {
                    setError('Failed to load data');
                }
            } finally {
                if (mounted) {
                    setIsInitialLoad(false);
                }
            }
        };

        fetchData();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        setIsLoading(data.length === 0 && !error);
    }, [data.length, error]);

    return (
        <motion.div 
            className="stat-card"
            style={{ gridColumn: '1 / -1', minHeight: '450px' }}
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3 }}
        >
            <div className="card-header">
                <div className="title">
                    <BsGraphUp size={18} />
                    <span>SECBUGs Weekly Overview</span>
                </div>
            </div>
            <div className="card-content" style={{ display: 'block' }}>
                {isInitialLoad || (isLoading && data.length === 0) ? (
                    <div className="flex items-center justify-center" style={{ height: '400px' }}>
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-200"></div>
                    </div>
                ) : (
                    <div style={{ height: '400px', width: '100%', display: 'flex', gap: '20px' }}>
                        <div style={{ flex: 1 }}>
                            <ResponsiveContainer height={350}>
                                <BarChart
                                    data={data}
                                    margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                                    barSize={15}
                                >
                                    <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                                    <XAxis 
                                        dataKey="timeStamp"
                                        interval={0}
                                        angle={-45}
                                        textAnchor="end"
                                        height={60}
                                    />
                                    <YAxis>
                                        <Label 
                                            angle={-90}
                                            position="insideLeft"
                                            style={{ textAnchor: 'middle' }}
                                            offset={-6}
                                        >
                                            Open Issues
                                        </Label>
                                    </YAxis>
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend 
                                        height={36}
                                        wrapperStyle={{
                                            paddingTop: '20px'
                                        }}
                                    />
                                    {SCAN_TYPES.map(scanType => (
                                        <Bar
                                            key={`${scanType}-open`}
                                            dataKey={`${scanType}-open`}
                                            name={`${scanType}`}
                                            fill={COLORS[scanType].open}
                                        />
                                    ))}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        <div style={{ flex: 1 }}>
                            <ResponsiveContainer height={350}>
                                <BarChart
                                    data={data}
                                    margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
                                    barSize={15}
                                >
                                    <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                                    <XAxis 
                                        dataKey="timeStamp"
                                        interval={0}
                                        angle={-45}
                                        textAnchor="end"
                                        height={60}
                                    />
                                    <YAxis>
                                        <Label 
                                            angle={-90}
                                            position="insideLeft"
                                            style={{ textAnchor: 'middle' }}
                                            offset={-6}
                                        >
                                            Closed Issues
                                        </Label>
                                    </YAxis>
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend 
                                        height={36}
                                        wrapperStyle={{
                                            paddingTop: '20px'
                                        }}
                                    />
                                    {SCAN_TYPES.map(scanType => (
                                        <Bar
                                            key={`${scanType}-closed`}
                                            dataKey={`${scanType}-closed`}
                                            name={`${scanType}`}
                                            fill={COLORS[scanType].closed}
                                        />
                                    ))}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                )}
            </div>
        </motion.div>
    );
};