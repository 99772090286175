import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MetadataConfigurations } from './SecurityMetadataModel';
import { IoIosApps } from 'react-icons/io';
import './SecurityMetadata.scss';

const SecurityMetadata: React.FC = () => {
    const [data, setData] = useState<MetadataConfigurations>({});
    const [searchQuery, setSearchQuery] = useState<string>("");

    useEffect(() => {
        axios.get('/SecurityMetadata').then((response) => {
            setData(response.data.applications);
        });
    }, []);

    const filteredItems = filterConfigurationsByKey(data, searchQuery);
    
    function filterConfigurationsByKey(configs: MetadataConfigurations, filterKey: string): MetadataConfigurations {
        return Object.keys(configs)
            .filter(key => key.toLowerCase().includes(filterKey.toLowerCase()) || configs[key].canonicalId.toLowerCase().includes(filterKey.toLowerCase()))
            .reduce((filteredConfigs, key) => {
                filteredConfigs[key] = configs[key];
                return filteredConfigs;
            }, {} as MetadataConfigurations);
    }

    return (
    <div className="security-metadata">
        <div className="search-container">
            <input
                type="text"
                placeholder="Search by Name or Canonical ID"
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                className="search-input"
            />
        </div>
        {Object.keys(filteredItems).map((key) => (
        <div key={key} className="app-details-card">
            <h2>
                <IoIosApps size={24}/> {key}
            </h2>
            
            <div className="details-grid">
                <div className="details-column">
                    <div className="details-element">
                        <div className="label">Canonical ID</div>
                        <div>{filteredItems[key].canonicalId}</div>
                    </div>
                    <div className="details-element">
                        <div className="label">DAST URL</div>
                        <div>{filteredItems[key].dastUrl}</div>
                    </div>
                    <div className="details-element">
                        <div className="label">Tags</div>
                        <div>{filteredItems[key].tags.join(', ')}</div>
                    </div>
                </div>
                <div className="details-column">
                    <div className="details-element">
                        <div className="label">Veracode name</div>
                        <div>{filteredItems[key].veracodeName}</div>
                    </div>
                    <div className="details-element">
                        <div className="label">Import results</div>
                        <div>{filteredItems[key].importResults}</div>
                    </div>
                    <div className="details-element">
                        <div className="label">Jira destination</div>
                        <div>Jira Project: {filteredItems[key].jiraDestination?.jiraProject}</div>
                        <div>Jira URL: <a href={filteredItems[key].jiraDestination?.jiraUrl} target='blank'>{filteredItems[key].jiraDestination?.jiraUrl}</a></div>
                    </div>
                </div>
                <div className="details-column">
                    <div className="details-element">
                        <div className="label">Production status</div>
                        <div>{filteredItems[key].productionStatus}</div>
                    </div>
                    <div className="details-element">
                        <div className="label">GitHub repo</div>
                        <div><a href={filteredItems[key].githubRepoLink} target='blank'>{filteredItems[key].githubRepoLink}</a></div>
                    </div>
                    <div className="details-element">
                        <div className="label">Stakeholders</div>
                        <div>PS Architect: {filteredItems[key].stakeholders.psArchitect}</div>
                        <div>Security Champions: {filteredItems[key].stakeholders.securityChampions}</div>
                        <div>Dev Lead: {filteredItems[key].stakeholders.devLead}</div>
                    </div>
                </div>
                <div className="details-column">
                    <div className="details-element">
                        <div className="label">Full product name</div>
                        <div>Family: {filteredItems[key].fullProductName?.family}</div>
                        <div>Application: {filteredItems[key].fullProductName?.application}</div>
                        <div>Component: {filteredItems[key].fullProductName?.component}</div>
                    </div>
                    <div className="details-element">
                        <div className="label">Import rules</div>
                        <div>Severity: {filteredItems[key].importRules?.severity}</div>
                    </div>
                </div>
            </div>

            {filteredItems[key].exemptions && filteredItems[key].exemptions.exemptionList && filteredItems[key].exemptions.exemptionList.length > 0 && (
                <div className="exemptions-section">
                    <h3>Exemptions:</h3>
                    <div className="exemptions-grid">
                        {filteredItems[key].exemptions.exemptionList.map((exemption, index) => (
                            <div key={index} className="exemption-item">
                                <div className="details-element">
                                    <div className="label">Risk ID</div>
                                    <div>{exemption.riskId}</div>
                                </div>
                                <div className="details-element">
                                    <div className="label">Scan Type</div>
                                    <div>{exemption.scanType}</div>
                                </div>
                                <div className="details-element">
                                    <div className="label">Attribute CWE ID</div>
                                    <div>{exemption.attributes.cweId}</div>
                                </div>
                                <div className="details-element">
                                    <div className="label">Attribute Module</div>
                                    <div>{exemption.attributes.modules}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
        ))}
    </div>
    );
};

export default SecurityMetadata;