import React, { useEffect, useState } from 'react';
import { LineChartRequest } from './LineChartRequest';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';
import { IntroducedResolved, StatusCounts, VulnerabilityRemediationExpanded } from './VulnerabilityRemediationExpanded';
import { CartesianGrid, Label, Legend, Line, LineChart, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import JiraStatisticFilter from '../shared/JiraStatisticFilter';
import axios from 'axios';

import './VulnerabilityRemediationExpandedChart.scss';
import { JiraStatisticAppFamilyFilter, JiraStatisticScale } from '../shared/JiraStatisticFilterConstants';
import LineChartBase from './LineChartBase';

interface VulnerabilityRemediationExpandedChartProps {
    applications: MultiselectOption[];
    productFamilies: MultiselectOption[];
}

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">{`Date: ${label}`}</p>
                {payload.map((entry: any, index: number) => (
                    <p key={index} style={{ color: entry.color }}>
                        {`${entry.name}: ${entry.value}`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

const VulnerabilityRemediationExpandedChart: React.FC<VulnerabilityRemediationExpandedChartProps> = ({ 
    applications, 
    productFamilies 
}) => {
    const defaultData: VulnerabilityRemediationExpanded[] = [];
    const [data, setData] = useState<VulnerabilityRemediationExpanded[]>(defaultData);
    const {
        selectedNames,
        selectedScale,
        selectedDateRange,
        selectedNameType,
        appChanged,
        appFamilyChanged,
        scaleChanged,
        dateRangeChanged,
        customizedAxisTick
    } = LineChartBase();

    const requestData = async () => {
        let request: LineChartRequest = {
            scale: selectedScale ?? JiraStatisticScale.Day,
            startDate: selectedDateRange.startDate ?? new Date(),
            endDate: selectedDateRange.endDate ?? new Date(),
            nameType: selectedNameType ?? JiraStatisticAppFamilyFilter.Application,
            selectedNames: selectedNames ?? []
        }
        
        const response = await axios.post('/jirastatistic/getVulnerabilityRemediationExpanded', request)
        const tmpData = response.data.map((x: VulnerabilityRemediationExpanded) => {
            x.statusCountsData.forEach((y: StatusCounts) => {
                y.open = Math.ceil(y.open);
                y.closed = Math.ceil(y.closed);
                y.inProgress = Math.ceil(y.inProgress);
                y.blocked = Math.ceil(y.blocked);
                return y;
            });
            x.introducedResolvedData.forEach((y: IntroducedResolved) => {
                y.introduced = Math.ceil(y.introduced);
                y.resolved = Math.ceil(y.resolved);
                return y;
            });
            return x;
        });
        setData(tmpData);
    }

    useEffect(() => {
        if(selectedNames && selectedNames.length > 0){
            requestData();
        }
        // eslint-disable-next-line
    }, [selectedNames, selectedScale, selectedDateRange, selectedNameType]);

    return (
        <div className="statistic-pane">
            <div className="chart-title">
                <h4>Vulnerability Remediation Progress (Expanded)</h4>
            </div>
            <JiraStatisticFilter 
                applications={applications} 
                applicationChangedCallback={appChanged} 
                productFamilies={productFamilies} 
                productFamilyChangedCallback={appFamilyChanged}
                scaleChangedCallback={scaleChanged}
                dateRangeChangedCallback={dateRangeChanged}
                defaultAppFamilyFilter={selectedNameType}
                defaultStartDate={selectedDateRange.startDate}
                defaultEndDate={selectedDateRange.endDate}
                defaultScale={selectedScale}
                showScale={true}
                id="vulnerability"
            />
            
            {data.length === 0 && 
                <div className="chart"><label className="text-info">No data available</label></div>
            }

            {data.length > 0 && data.map((item) => (
                <div className="chart" key={item.scanType}>
                    <div className="chart-container">
                        <div className="chart-header">
                            <h5>{item.scanType} - Status Counts</h5>
                        </div>
                        <ResponsiveContainer height={400} width="100%">
                            <LineChart 
                                data={item.statusCountsData}
                                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                                <Tooltip content={<CustomTooltip />} />
                                <Legend 
                                    height={36}
                                    wrapperStyle={{
                                        paddingTop: '20px'
                                    }}
                                />
                                <XAxis 
                                    dataKey="timeStamp" 
                                    padding="no-gap" 
                                    interval={0} 
                                    tick={customizedAxisTick}
                                    height={60}
                                />
                                <YAxis>
                                    <Label 
                                        angle={-90} 
                                        position="insideLeft"
                                        style={{ textAnchor: 'middle' }}
                                    >
                                        Issues count
                                    </Label>
                                </YAxis>
                                <Line 
                                    name="Open"
                                    strokeWidth={2} 
                                    type="monotone" 
                                    dataKey="open" 
                                    stroke="#fd4433" 
                                    dot={false}
                                    activeDot={{ r: 6 }}
                                />
                                <Line 
                                    name="Closed"
                                    strokeWidth={2} 
                                    type="monotone" 
                                    dataKey="closed" 
                                    stroke="#1EB980" 
                                    dot={false}
                                    activeDot={{ r: 6 }}
                                />
                                <Line 
                                    name="In Progress"
                                    strokeWidth={2} 
                                    type="monotone" 
                                    dataKey="inProgress" 
                                    stroke="#82ca9d" 
                                    dot={false}
                                    activeDot={{ r: 6 }}
                                />
                                <Line 
                                    name="Blocked"
                                    strokeWidth={2} 
                                    type="monotone" 
                                    dataKey="blocked" 
                                    stroke="orange" 
                                    dot={false}
                                    activeDot={{ r: 6 }}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default VulnerabilityRemediationExpandedChart;