import React, {useEffect} from "react";
import axios from "../common/axios-client";

import {useQuery, useQueryClient} from "react-query";
import {Link, useLocation} from "react-router-dom";
import {Policy} from "./PolicyTypes";
import PolicyList from "./PolicyList";
import "./Policies.scss"


const fetchPolicies = async (): Promise<Policy[]> => {
    let r = await axios.get("/policies");
    console.log(r.data)
    return r.data.policies
}

const Policies: React.FC = () => {
    const {
        data,
        isLoading,
        isFetching,
        isSuccess
    } = useQuery<Policy[]>({
        queryKey: "policies",
        queryFn: fetchPolicies
    });

    const queryClient = useQueryClient()
    const location = useLocation()

    useEffect(() => {
        queryClient.invalidateQueries("policies")
    }, [location, queryClient]);

    if (isLoading) {
        return <>Loading</>
    }

    if (isSuccess) {
        return (
            <>
                {isFetching ? <span>Updating</span> : <></>}
                <div className={"wrap-btn-create"}>
                    <Link to={"/policies/create"} id={"btnCreatePolicy"} className={"btn-with-icon btn-main"}>
                        <span>Create</span>
                    </Link>
                </div>
                <PolicyList policies={data}/>
            </>
        )
    }

    return <>Failed to load, check console</>
}

export default Policies