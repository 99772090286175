import React from "react";
import { TableRow, TableCell } from '@mui/material';
import { ScanResult } from "./ScanResult"
import { BsCheckCircle, BsXCircle, BsDashCircle } from "react-icons/bs";

type Props = {
    item: ScanResult
}

export const ScanResultsItem: React.FC<Props> = ({item}) => {
    return (
        <TableRow hover>
            <TableCell>
                <a 
                    href={item.repoUrl + "/actions/runs/" + item.externalRunId} 
                    target="_blank" 
                    rel="noreferrer"
                >
                    {item.externalRunId}
                </a>
            </TableCell>
            <TableCell>{item.workflowName}</TableCell>
            <TableCell>{item.scanerName}</TableCell>
            <TableCell>{item.scanType}</TableCell>
            <TableCell>
                {item.scanStatus === "Completed" && <BsCheckCircle size={15} style={{ color: "green" }} />}
                {item.scanStatus === "Failed" && <BsXCircle size={15} style={{ color: "red" }} />}
                {item.scanStatus === "Canceled" && <BsDashCircle size={15} style={{ color: "grey" }} />}
                {" "}{item.scanStatus}
            </TableCell>
            <TableCell>{new Date(item.startDate).toLocaleString()}</TableCell>
            <TableCell>{new Date(item.endDate).toLocaleString()}</TableCell>
            <TableCell>{item.issuesCount}</TableCell>
        </TableRow>
    );
};