import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import { MdCheck, MdError } from 'react-icons/md';
import AnimatedShieldLoader from '../../AnimatedShieldLoader';

interface ExceptionFormData {
    title: string;
    description: string;
    applicationId: string;
    category: string;
    severity: number;
    justification: string;
    expirationDate: string;
}

const ExceptionForm: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const isEditing = Boolean(id);

    const [formData, setFormData] = useState<ExceptionFormData>({
        title: '',
        description: '',
        applicationId: '',
        category: '',
        severity: 1,
        justification: '',
        expirationDate: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const { data: existingException, isLoading } = useQuery(
        ['exception', id],
        async () => {
            if (id) {
                const response = await axios.get(`/api/exceptions/${id}`);
                return response.data;
            }
            return null;
        },
        {
            enabled: isEditing
        }
    );

    useEffect(() => {
        if (existingException) {
            setFormData({
                title: existingException.title,
                description: existingException.description,
                applicationId: existingException.applicationId,
                category: existingException.category,
                severity: existingException.severity,
                justification: existingException.justification,
                expirationDate: new Date(existingException.expirationDate).toISOString().split('T')[0]
            });
        }
    }, [existingException]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);
        setSuccessMessage(null);

        try {
            if (isEditing) {
                await axios.put(`/api/exceptions/${id}`, formData);
            } else {
                await axios.post('/api/exceptions', formData);
            }

            setSuccessMessage('Exception saved successfully');
            setTimeout(() => {
                navigate('/exception-manager');
            }, 1500);
        } catch (err: any) {
            setError(err.response?.data?.message || 'An error occurred while saving the exception');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isLoading) {
        return <div id="loader"><AnimatedShieldLoader /></div>;
    }

    return (
        <div className="max-w-3xl mx-auto p-4">
            <h1 className="text-2xl font-semibold mb-6">
                {isEditing ? 'Edit Exception' : 'Create New Exception'}
            </h1>

            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label className="block mb-2">Title</label>
                    <input
                        type="text"
                        value={formData.title}
                        onChange={(e) => setFormData(prev => ({...prev, title: e.target.value}))}
                        className="w-full p-2 bg-[#444444] rounded border border-gray-600"
                        required
                    />
                </div>

                <div>
                    <label className="block mb-2">Description</label>
                    <textarea
                        value={formData.description}
                        onChange={(e) => setFormData(prev => ({...prev, description: e.target.value}))}
                        className="w-full p-2 bg-[#444444] rounded border border-gray-600 min-h-[100px]"
                        required
                    />
                </div>

                <div>
                    <label className="block mb-2">Application ID</label>
                    <input
                        type="text"
                        value={formData.applicationId}
                        onChange={(e) => setFormData(prev => ({...prev, applicationId: e.target.value}))}
                        className="w-full p-2 bg-[#444444] rounded border border-gray-600"
                        required
                    />
                </div>

                <div>
                    <label className="block mb-2">Category</label>
                    <input
                        type="text"
                        value={formData.category}
                        onChange={(e) => setFormData(prev => ({...prev, category: e.target.value}))}
                        className="w-full p-2 bg-[#444444] rounded border border-gray-600"
                        required
                    />
                </div>

                <div>
                    <label className="block mb-2">Severity</label>
                    <select
                        value={formData.severity}
                        onChange={(e) => setFormData(prev => ({...prev, severity: Number(e.target.value)}))}
                        className="w-full p-2 bg-[#444444] rounded border border-gray-600"
                        required
                    >
                        <option value={1}>Low</option>
                        <option value={2}>Medium</option>
                        <option value={3}>High</option>
                        <option value={4}>Critical</option>
                    </select>
                </div>

                <div>
                    <label className="block mb-2">Justification</label>
                    <textarea
                        value={formData.justification}
                        onChange={(e) => setFormData(prev => ({...prev, justification: e.target.value}))}
                        className="w-full p-2 bg-[#444444] rounded border border-gray-600 min-h-[100px]"
                        required
                    />
                </div>

                <div>
                    <label className="block mb-2">Expiration Date</label>
                    <input
                        type="date"
                        value={formData.expirationDate}
                        onChange={(e) => setFormData(prev => ({...prev, expirationDate: e.target.value}))}
                        className="w-full p-2 bg-[#444444] rounded border border-gray-600"
                        required
                    />
                </div>

                {error && (
                    <div className="bg-red-900/50 text-red-300 border border-red-700 rounded-lg p-3 flex items-center gap-2">
                        <MdError /> {error}
                    </div>
                )}

                {successMessage && (
                    <div className="bg-green-900/50 text-green-300 border border-green-700 rounded-lg p-3 flex items-center gap-2">
                        <MdCheck /> {successMessage}
                    </div>
                )}

                <div className="flex justify-end gap-4">
                    <button
                        type="button"
                        onClick={() => navigate('/exception-manager')}
                        className="px-4 py-2 border border-gray-600 rounded hover:bg-gray-700"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="px-4 py-2 bg-[#4f5e80] text-white rounded hover:bg-opacity-90 flex items-center gap-2"
                    >
                        {isSubmitting ? (
                            <>
                                <Oval
                                    height={16}
                                    width={16}
                                    color="white"
                                    visible={true}
                                    ariaLabel="loading"
                                    secondaryColor="#60a5fa"
                                />
                                Saving...
                            </>
                        ) : (
                            'Save Exception'
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ExceptionForm;