import React, { useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import { MdRefresh, MdCheck } from 'react-icons/md';

interface RegenerateFeedbackModalProps {
    isOpen: boolean;
    onClose: () => void;
    onRegenerate: (feedback: string) => void;
}

const RegenerateFeedbackModal: React.FC<RegenerateFeedbackModalProps> = ({ isOpen, onClose, onRegenerate }) => {
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const handleRegenerate = async () => {
        setIsSubmitting(true);
        await onRegenerate(feedback);
        setShowSuccess(true);
        
        setTimeout(() => {
            setShowSuccess(false);
            setFeedback('');
            setIsSubmitting(false);
            onClose();
        }, 5000);
    };

    useEffect(() => {
        if (!isOpen) {
            setFeedback('');
            setShowSuccess(false);
            setIsSubmitting(false);
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content regenerate-feedback-modal">
                <h3>
                    <MdRefresh className="icon" />
                    Regenerate Threat Model
                </h3>
                <div className="form-group">
                    <label htmlFor="feedback">
                        Have additional feedback that can be used during regeneration of threat model? (Optional)
                    </label>
                    <textarea
                        id="feedback"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        placeholder="E.g., 'Consider additional authentication vulnerabilities' or 'Focus more on API security'"
                        disabled={isSubmitting || showSuccess}
                    />
                </div>
                
                {showSuccess && (
                    <div className="status-message success">
                        <MdCheck />
                        <span>
                            Regeneration request sent. You will receive an email when it's complete.
                        </span>
                    </div>
                )}

                <div className="button-group">
                    <button 
                        type="button" 
                        onClick={onClose} 
                        className="cancel-btn"
                        disabled={isSubmitting}
                    >
                        Cancel
                    </button>
                    <button 
                        type="submit" 
                        onClick={handleRegenerate} 
                        className="submit-btn"
                        disabled={isSubmitting || showSuccess}
                    >
                        {isSubmitting ? (
                            <>
                                <Oval
                                    visible={true}
                                    height="16"
                                    width="16"
                                    color="#ffffff"
                                    secondaryColor="#ffffff"
                                    ariaLabel="loading"
                                />
                                Sending...
                            </>
                        ) : (
                            <>
                                Regenerate
                            </>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RegenerateFeedbackModal;