import {ScanConfiguration} from "./ScanCongifurationTypes";
import React from "react";
import {MdDeleteForever, MdEdit} from "react-icons/md";
import axios from "axios";
import {useNavigate} from "react-router-dom";

type ScanConfigurationItemProps = {
    scanConfiguration: ScanConfiguration
}

const ScanConfigurationItem: React.FC<ScanConfigurationItemProps> = ({scanConfiguration}) => {
    const navigate = useNavigate()

    async function handleDelete() {
        console.log(`Deleting ${scanConfiguration.id}`)
        await axios.delete(`/scanConfigurations/${scanConfiguration.id}`)

        // TODO: handle errors

        navigate("/scanConfigurations")
    }

    function handleEdit() {
        navigate(`/scanConfigurations/${scanConfiguration.id}`)
    }

    return (
        <tr>
            <td>
                {scanConfiguration.name}
            </td>
            <td>
                {scanConfiguration.scanType}
            </td>
            <td>
                {scanConfiguration.commonWeaknessEnumerationIds.map(x => <span key={x}>{x}&nbsp;</span>)}
            </td>
            <td>
                <span className={"action action-edit"} title={"Edit"} onClick={() => handleEdit()}>
                    <MdEdit />
                </span>
                <span className={"action action-delete"} title={"Delete"} onClick={() => handleDelete()}>
                    <MdDeleteForever />
                </span>
            </td>
        </tr>
    )
}

export default ScanConfigurationItem