import React from 'react';
import "./Root.scss"
import { SideNav } from "./SideNav";
import { Main } from "./Main";
import { ThemeProvider } from './ThemeContext';
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from '../authConfig';

type RootProps = {
    instance: PublicClientApplication
}

const authRequest = {
    ...loginRequest,
};

const Root: React.FC<RootProps> = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <ThemeProvider>
                <MsalAuthenticationTemplate 
                    interactionType={InteractionType.Redirect} 
                    authenticationRequest={authRequest}
                >
                    <SideNav />
                    <div className="main-content">
                        <Main />
                    </div>
                </MsalAuthenticationTemplate>
            </ThemeProvider>
        </MsalProvider>
    )
}

export default Root;