import React from 'react';
import { LineChartRequest } from './LineChartRequest';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';
import JiraStatisticFilter from '../shared/JiraStatisticFilter';
import axios from 'axios';
import { saveAs } from 'file-saver';

import './VulnerabilityRemediationExpandedChart.scss';

import { JiraStatisticAppFamilyFilter, JiraStatisticScale } from '../shared/JiraStatisticFilterConstants';
import LineChartBase from './LineChartBase';
import { Button } from 'react-bootstrap';

interface CsvExportProps {
    applications: MultiselectOption[];
    productFamilies: MultiselectOption[];
}

const CsvExport: React.FC<CsvExportProps> = ({ applications, productFamilies }) => {
    const {selectedNames, selectedScale, selectedDateRange, selectedNameType, appChanged, appFamilyChanged, scaleChanged, dateRangeChanged} = LineChartBase();

    const requestData = async () => {
        let request: LineChartRequest = {
            scale: selectedScale ?? JiraStatisticScale.Day,
            startDate: selectedDateRange.startDate ?? new Date(),
            endDate: selectedDateRange.endDate ?? new Date(),
            nameType: selectedNameType ?? JiraStatisticAppFamilyFilter.Application,
            selectedNames: selectedNames ?? []
        }
        
        const response = await axios.post('/jirastatistic/getCsv', request)

        const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, "JiraStatisticRaw.csv");
    }

    return (
        <div className='statistic-pane'>
            <div className='chart-title'>
                <h4>CSV Export</h4>
            </div>
            <div className='jira-statistic-filter-container'>
                <JiraStatisticFilter 
                    applications={applications} 
                    applicationChangedCallback={appChanged} 
                    productFamilies={productFamilies} 
                    productFamilyChangedCallback={appFamilyChanged}
                    scaleChangedCallback={scaleChanged}
                    dateRangeChangedCallback={dateRangeChanged}
                    defaultAppFamilyFilter={selectedNameType}
                    defaultStartDate={selectedDateRange.startDate}
                    defaultEndDate={selectedDateRange.endDate}
                    defaultScale={selectedScale}
                    showScale={false}
                    id="csvexport"/>
                
                <Button variant="primary" className='csv-button' onClick={requestData}>Export</Button>
            </div>
        </div>
    );
};

export default CsvExport;