import React, { useState, useEffect } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { BsShieldFill, BsShieldFillCheck, BsShieldFillX, BsShieldFillExclamation, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaTools } from "react-icons/fa";
import { MdList, MdSearch, MdExpandMore, MdExpandLess } from "react-icons/md";
import { SecurityException, App, SecurityExceptionStatus, generateExceptions } from './mock-data/sample';

const StatusBadge: React.FC<{ status: SecurityExceptionStatus }> = ({ status }) => {
    const getStatusStyles = () => {
        switch (status) {
            case SecurityExceptionStatus.Approved:
                return {
                    bg: 'bg-green-900/50',
                    text: 'text-green-300',
                    border: 'border-green-700',
                    icon: <BsShieldFillCheck className="text-green-400" />
                };
            case SecurityExceptionStatus.Rejected:
                return {
                    bg: 'bg-red-900/50',
                    text: 'text-red-300',
                    border: 'border-red-700',
                    icon: <BsShieldFillX className="text-red-400" />
                };
            case SecurityExceptionStatus.Pending:
                return {
                    bg: 'bg-yellow-900/50',
                    text: 'text-yellow-300',
                    border: 'border-yellow-700',
                    icon: <BsShieldFillExclamation className="text-yellow-400" />
                };
            default:
                return {
                    bg: 'bg-gray-900/50',
                    text: 'text-gray-300',
                    border: 'border-gray-700',
                    icon: <BsShieldFill className="text-gray-400" />
                };
        }
    };

    const styles = getStatusStyles();
    return (
        <span className={`inline-flex items-center gap-1 px-2 py-1 rounded-full text-sm ${styles.bg} ${styles.text} ${styles.border}`}>
            {styles.icon}
            {SecurityExceptionStatus[status]}
        </span>
    );
};

const SeverityBadge: React.FC<{ severity: number }> = ({ severity }) => {
    const getSeverityStyles = () => {
        switch (severity) {
            case 4:
                return 'bg-red-900/50 text-red-300 border-red-700';
            case 3:
                return 'bg-orange-900/50 text-orange-300 border-orange-700';
            case 2:
                return 'bg-yellow-900/50 text-yellow-300 border-yellow-700';
            case 1:
                return 'bg-green-900/50 text-green-300 border-green-700';
            default:
                return 'bg-gray-900/50 text-gray-300 border-gray-700';
        }
    };

    return (
        <span className={`inline-flex items-center px-2 py-1 rounded-full text-sm ${getSeverityStyles()}`}>
            Severity {severity}
        </span>
    );
};

interface AppWithExceptions extends App {
    exceptions: SecurityException[];
    isExpanded: boolean;
}

const ExceptionManager: React.FC = () => {
    const [appsWithExceptions, setAppsWithExceptions] = useState<AppWithExceptions[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [expandedApps, setExpandedApps] = useState<Set<string>>(new Set());
    const appsPerPage = 5;

    useEffect(() => {
        const apps: AppWithExceptions[] = [
            {
                primary_key: "DAYF-DCORE-000000",
                app_name: "Dayforce HCM Master",
                Description: "Core HCM application for Dayforce",
                AttachedProfile: "enterprise-profile",
                IsSaEnabled: true,
                PolicyName: "Enterprise Policy",
                CreatedDateTime: new Date("2024-01-01"),
                PolicyComplianceStatus: "Compliant",
                ExternalId: "DF-HCM-001",
                LastCompletedScanDate: new Date("2024-03-15"),
                CanonicalId: "DAYF-DCORE-000000",
                AppSecSupportTierLevel: "Tier 1",
                SASTStatus: "Active",
                RepoName: "dayforce-hcm-master",
                RepoUrl: "https://github.com/DayforceGlobal/dayforce-hcm-master",
                ApplicablePolicyIds: ["POL-001"],
                SecurityExceptionIds: [],
                exceptions: generateExceptions("DAYF-DCORE-000000", 3),
                isExpanded: false
            },
            {
                primary_key: "DAYF-DCORE-PAYLUI",
                app_name: "Dayforce-Payroll-UI",
                Description: "Payroll user interface component for Dayforce HCM",
                AttachedProfile: "payroll-profile",
                IsSaEnabled: true,
                PolicyName: "Enterprise Policy",
                CreatedDateTime: new Date("2024-01-15"),
                PolicyComplianceStatus: "Compliant",
                ExternalId: "DF-PAY-001",
                LastCompletedScanDate: new Date("2024-03-10"),
                CanonicalId: "DAYF-DCORE-PAYLUI",
                AppSecSupportTierLevel: "Tier 1",
                SASTStatus: "Completed",
                RepoName: "dayforce-payroll-ui",
                RepoUrl: "https://github.com/DayforceGlobal/dayforce-payroll-ui",
                ApplicablePolicyIds: ["POL-001", "POL-002"],
                SecurityExceptionIds: [],
                exceptions: generateExceptions("DAYF-DCORE-PAYLUI", 2),
                isExpanded: false
            },
            {
                primary_key: "DAYF-RPTNG-RPTUIX",
                app_name: "Reporting UI",
                Description: "Reporting user interface component for Dayforce",
                AttachedProfile: "reporting-profile",
                IsSaEnabled: true,
                PolicyName: "Standard Policy",
                CreatedDateTime: new Date("2024-02-01"),
                PolicyComplianceStatus: "Non-Compliant",
                ExternalId: "DF-RPT-001",
                LastCompletedScanDate: new Date("2024-03-01"),
                CanonicalId: "DAYF-RPTNG-RPTUIX",
                AppSecSupportTierLevel: "Tier 2",
                SASTStatus: "In Progress",
                RepoName: "reporting-ui",
                RepoUrl: "https://github.com/DayforceGlobal/reporting-ui",
                ApplicablePolicyIds: ["POL-003"],
                SecurityExceptionIds: [],
                exceptions: generateExceptions("DAYF-RPTNG-RPTUIX", 4),
                isExpanded: false
            },
            {
                primary_key: "DTAN-ACTVA-000000",
                app_name: "Dayforce Activate",
                Description: "Data and Analytics Activation platform",
                AttachedProfile: "analytics-profile",
                IsSaEnabled: true,
                PolicyName: "Analytics Policy",
                CreatedDateTime: new Date("2024-02-15"),
                PolicyComplianceStatus: "Compliant",
                ExternalId: "DF-ACT-001",
                LastCompletedScanDate: new Date("2024-03-05"),
                CanonicalId: "DTAN-ACTVA-000000",
                AppSecSupportTierLevel: "Tier 1",
                SASTStatus: "Completed",
                RepoName: "dayforce-activate",
                RepoUrl: "https://github.com/DayforceGlobal/dayforce-activate",
                ApplicablePolicyIds: ["POL-004"],
                SecurityExceptionIds: [],
                exceptions: generateExceptions("DTAN-ACTVA-000000", 2),
                isExpanded: false
            },
            {
                primary_key: "DAYF-PLTCH-DFPTUI",
                app_name: "Dayforce-PeopleTech-UI",
                Description: "People Technology user interface for Dayforce",
                AttachedProfile: "peopletech-profile",
                IsSaEnabled: true,
                PolicyName: "Enterprise Policy",
                CreatedDateTime: new Date("2024-01-20"),
                PolicyComplianceStatus: "Compliant",
                ExternalId: "DF-PT-001",
                LastCompletedScanDate: new Date("2024-03-12"),
                CanonicalId: "DAYF-PLTCH-DFPTUI",
                AppSecSupportTierLevel: "Tier 2",
                SASTStatus: "Active",
                RepoName: "people-technology-ui",
                RepoUrl: "https://github.com/DayforceGlobal/people-technology-ui",
                ApplicablePolicyIds: ["POL-001", "POL-005"],
                SecurityExceptionIds: [],
                exceptions: generateExceptions("DAYF-PLTCH-DFPTUI", 3),
                isExpanded: false
            }
        ];
        setAppsWithExceptions(apps);
    }, []);

    const filteredApps = appsWithExceptions.filter(app =>
        app.app_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        app.Description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        app.exceptions.some(ex =>
            ex.Title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            ex.Description.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const totalPages = Math.ceil(filteredApps.length / appsPerPage);
    const paginatedApps = filteredApps.slice(
        (currentPage - 1) * appsPerPage,
        currentPage * appsPerPage
    );

    const toggleAppExpansion = (appId: string) => {
        setExpandedApps(prev => {
            const newSet = new Set(prev);
            if (newSet.has(appId)) {
                newSet.delete(appId);
            } else {
                newSet.add(appId);
            }
            return newSet;
        });
    };

    return (
        <div className="min-h-screen bg-gray-900">
            <div className="px-6 py-4 bg-[#373740] border-b border-gray-700">
                <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-2">
                        <h1 className="text-2xl font-semibold">Security Exception Manager</h1>
                    </div>

                    <div className="flex gap-4">
                        <NavLink
                            to="/rules"
                            className={({ isActive }) => `
                                flex items-center gap-2 px-4 py-2 rounded-lg
                                ${isActive ? 'bg-[#4f5e80] text-white' : 'text-gray-300 hover:bg-[#4f5e80] hover:text-white'}
                                transition-colors duration-200
                            `}
                        >
                            <MdList size={20} />
                            <span>Rules List</span>
                        </NavLink>

                        <NavLink
                            to="rules/builder"
                            className={({ isActive }) => `
                                flex items-center gap-2 px-4 py-2 rounded-lg
                                ${isActive ? 'bg-[#4f5e80] text-white' : 'text-gray-300 hover:bg-[#4f5e80] hover:text-white'}
                                transition-colors duration-200
                            `}
                        >
                            <FaTools size={20} />
                            <span>Rule Builder</span>
                        </NavLink>
                        <NavLink
                            to="exception/create"
                            className={({ isActive }) => `
                                flex items-center gap-2 px-4 py-2 rounded-lg
                                ${isActive ? 'bg-[#4f5e80] text-white' : 'text-gray-300 hover:bg-[#4f5e80] hover:text-white'}
                                transition-colors duration-200
                            `}
                        >
                            <FaTools size={20} />
                            <span>Create Exception</span>
                        </NavLink>
                        <NavLink
                            to="app-exception"
                            className={({ isActive }) => `
                                flex items-center gap-2 px-4 py-2 rounded-lg
                                ${isActive ? 'bg-[#4f5e80] text-white' : 'text-gray-300 hover:bg-[#4f5e80] hover:text-white'}
                                transition-colors duration-200
                            `}
                        >
                            <FaTools size={20} />
                            <span>App Exceptions</span>
                        </NavLink>
                        <NavLink
                            to="jira/jira-exceptions"
                            className={({ isActive }) => `
                                flex items-center gap-2 px-4 py-2 rounded-lg
                                ${isActive ? 'bg-[#4f5e80] text-white' : 'text-gray-300 hover:bg-[#4f5e80] hover:text-white'}
                                transition-colors duration-200
                            `}
                        >
                            <span>Jira Exceptions</span>
                        </NavLink>
                    </div>

                    <div className="flex gap-4 items-center">
                        <div className="relative flex-1">
                            <MdSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                            <input
                                type="text"
                                placeholder="Search applications and exceptions..."
                                className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-200"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-6">
                {paginatedApps.map(app => (
                    <div
                        key={app.primary_key}
                        className="mb-4 bg-[#373740] rounded-lg shadow-lg overflow-hidden"
                    >
                        <div
                            className="p-4 bg-gray-800 flex items-center justify-between cursor-pointer hover:bg-gray-700 transition-colors"
                            onClick={() => toggleAppExpansion(app.primary_key)}
                        >
                            <div className="flex-1">
                                <h2 className="text-lg font-semibold">{app.app_name}</h2>
                                <p className="text-gray-400">{app.Description}</p>
                                <div className="flex gap-4 mt-2 text-sm text-gray-400">
                                    <span>Policy: {app.PolicyName}</span>
                                    <span>Support Tier: {app.AppSecSupportTierLevel}</span>
                                    <span>SAST Status: {app.SASTStatus}</span>
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <span className="text-gray-400">
                                    {app.exceptions.length} Exception{app.exceptions.length !== 1 ? 's' : ''}
                                </span>
                                {expandedApps.has(app.primary_key) ?
                                    <MdExpandLess size={24} className="text-gray-400" /> :
                                    <MdExpandMore size={24} className="text-gray-400" />
                                }
                            </div>
                        </div>

                        {expandedApps.has(app.primary_key) && (
                            <div className="p-4">
                                <div className="overflow-x-auto">
                                    <table className="w-full">
                                        <thead>
                                        <tr className="border-b border-gray-700">
                                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Title</th>
                                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Status</th>
                                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Severity</th>
                                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Category</th>
                                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Created</th>
                                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Expires</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {app.exceptions.map(exception => (
                                            <tr key={exception.Id} className="hover:bg-gray-800 transition-colors">
                                                <td className="px-4 py-3">
                                                    <div className="flex flex-col">
                                                        <span className="text-white font-medium">{exception.Title}</span>
                                                        <span className="text-gray-400 text-sm">{exception.Description}</span>
                                                    </div>
                                                </td>
                                                <td className="px-4 py-3">
                                                    <StatusBadge status={exception.CurrentStatus} />
                                                </td>
                                                <td className="px-4 py-3">
                                                    <SeverityBadge severity={exception.Severity} />
                                                </td>
                                                <td className="px-4 py-3 text-gray-300">{exception.Category}</td>
                                                <td className="px-4 py-3 text-gray-300">
                                                    {exception.CreatedDate.toLocaleDateString()}
                                                </td>
                                                <td className="px-4 py-3 text-gray-300">
                                                    {exception.ExpirationDate.toLocaleDateString()}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                ))}

                {/* Pagination */}
                <div className="flex justify-between items-center mt-6 px-4">
                    <div className="text-gray-400">
                        Showing {((currentPage - 1) * appsPerPage) + 1} to {Math.min(currentPage * appsPerPage, filteredApps.length)} of {filteredApps.length} applications
                    </div>
                    <div className="flex gap-2">
                        <button
                            className="p-2 rounded-lg bg-gray-800 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-700"
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                        >
                            <BsChevronLeft size={20} />
                        </button>
                        <button
                            className="p-2 rounded-lg bg-gray-800 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-700"
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                        >
                            <BsChevronRight size={20} />
                        </button>
                    </div>
                </div>
            </div>
            <Outlet />
        </div>
    );
};

export default ExceptionManager;