import React from 'react';
import { BsJournalText, BsShieldFillCheck, BsShieldFillX, BsShieldFillExclamation, BsShieldSlash, BsShieldFillMinus, BsClock } from "react-icons/bs";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import axios from 'axios';
import { motion } from "framer-motion";

interface Application {
    id: string;
    name: string;
    policyName: string;
    policyComplianceStatus: 'PASSED' | 'DID_NOT_PASS' | 'CONDITIONAL_PASS' | 'NOT_ASSESSED' | 'DETERMINING';
    lastCompletedScanDate: string;
}

const COLORS = {
    PASSED: '#1EB980',
    DID_NOT_PASS: '#FD4433',
    CONDITIONAL_PASS: '#FFCF44',
    NOT_ASSESSED: '#6b7280',
    DETERMINING: '#6b7280',
    SCAN_OVERDUE: '#fd4433'
};

const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
};

const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
};

const fetchApplications = async (): Promise<Application[]> => {
    const response = await axios.get('/applications');
    return response.data.applications;
};

const PolicyIcon = ({ status }: { status: Application['policyComplianceStatus'] }) => {
    switch (status) {
        case "PASSED":
            return <BsShieldFillCheck size={14} color={COLORS.PASSED} />;
        case "DID_NOT_PASS":
            return <BsShieldFillX size={14} color={COLORS.DID_NOT_PASS} />;
        case "CONDITIONAL_PASS":
            return <BsShieldFillExclamation size={14} color={COLORS.CONDITIONAL_PASS} />;
        case "NOT_ASSESSED":
            return <BsShieldSlash size={14} color={COLORS.NOT_ASSESSED} />;
        case "DETERMINING":
            return <BsShieldFillMinus size={14} color={COLORS.DETERMINING} />;
        default:
            return null;
    }
};

const AnimatedNumber: React.FC<{ value: number }> = ({ value }) => {
    return (
        <motion.span
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, type: "spring" }}
        >
            <motion.span
                key={value}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, type: "spring" }}
            >
                {value}
            </motion.span>
        </motion.span>
    );
};

const AnimatedCard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <motion.div 
            className="stat-card"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3 }}
        >
            {children}
        </motion.div>
    );
};

const AnimatedStatItem: React.FC<{ 
    icon?: React.ReactNode;
    label: string;
    value: number;
    index: number;
}> = ({ icon, label, value, index }) => {
    return (
        <motion.div 
            className="stat-item"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3, delay: index * 0.1 }}
        >
            {icon ? icon : <span className="type-indicator"></span>}
            <span>{value} {label}</span>
        </motion.div>
    );
};

export const ApplicationsCard = () => {
    const [applications, setApplications] = React.useState<Application[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
  
    React.useEffect(() => {
        fetchApplications()
            .then(setApplications)
            .finally(() => setIsLoading(false));
    }, []);
  
    const stats = React.useMemo(() => {
        if (!applications.length) return null;
    
        const currentDate = new Date();
        const thirtyDaysAgo = new Date(currentDate);
        thirtyDaysAgo.setDate(currentDate.getDate() - 30);
    
        const policyStats = applications.reduce((acc, app) => {
            acc[app.policyComplianceStatus] = (acc[app.policyComplianceStatus] || 0) + 1;
            return acc;
        }, {} as Record<string, number>);
    
        const scanOverdue = applications.filter(app => 
            new Date(app.lastCompletedScanDate) < thirtyDaysAgo
        ).length;
    
        const orderedStatuses = ['PASSED', 'CONDITIONAL_PASS', 'DID_NOT_PASS', 'NOT_ASSESSED', 'DETERMINING'];
        const policyData = orderedStatuses
            .filter(status => policyStats[status] !== undefined)
            .map(status => ({
                name: status,
                value: policyStats[status] || 0,
            }));
    
        return {
            total: applications.length,
            policyData,
            scanOverdue
        };
    }, [applications]);
  
    if (isLoading || !stats) return null;
  
    return (
        <AnimatedCard>
            <div className="card-header">
                <div className="title">
                    <BsJournalText size={18} />
                    <span>Asset Register</span>
                </div>
            </div>
            <div className="card-content">
                <div className="main-stat">
                    <span className="number">
                        <AnimatedNumber value={stats.total} />
                    </span>
                    <span className="label">Total Assets</span>
                    <div className="chart-container" style={{ height: '120px', marginTop: '1rem' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={stats.policyData}
                                    innerRadius={25}
                                    outerRadius={40}
                                    paddingAngle={2}
                                    dataKey="value"
                                >
                                    {stats.policyData.map((entry, index) => (
                                        <Cell key={index} fill={COLORS[entry.name]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="stat-breakdown">
                    {stats.policyData.map((stat, index) => (
                        <AnimatedStatItem
                            key={stat.name}
                            icon={<PolicyIcon status={stat.name as Application['policyComplianceStatus']} />}
                            label={stat.name.replace(/_/g, ' ')}
                            value={stat.value}
                            index={index}
                        />
                    ))}
                    <AnimatedStatItem
                        icon={<BsClock size={14} color={COLORS.SCAN_OVERDUE} />}
                        label="App Scans Overdue"
                        value={stats.scanOverdue}
                        index={stats.policyData.length}
                    />
                </div>
            </div>
        </AnimatedCard>
    );
};