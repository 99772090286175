import React from 'react';
import {useAccount, useMsal} from '@azure/msal-react';
import axios from 'axios';
import {loginRequest} from "../authConfig"

interface RequestInterceptorProps {
    children: JSX.Element,
}

const RequestInterceptor: React.FC<RequestInterceptorProps> = ({ children }: RequestInterceptorProps) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    /* eslint-disable no-param-reassign */
    axios.interceptors.request.use(async (config) => {
        if (!account) {
            throw Error('No active account! Verify a user has been signed in.');
        }

        const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account,
        });

        config.headers.Authorization = `Bearer ${response.accessToken}`;

        return config;
    });
    /* eslint-enable no-param-reassign */

    return (<>{children}</>);
};

export default RequestInterceptor;
