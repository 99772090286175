import React from "react";

import axios from "axios";

import {MdDeleteForever, MdEdit} from "react-icons/md";
import {useNavigate} from "react-router-dom";

import {Policy} from "./PolicyTypes";

type PolicyItemProps = {
    policy: Policy
}

const PolicyItem: React.FC<PolicyItemProps> = ({policy}) => {
    const navigate = useNavigate()

    function handleEdit() {
        console.log("Not ready yet")
    }

    async function handleDelete() {
        console.log(`Deleting ${policy.id}`)
        await axios.delete(`/policies/${policy.id}`)

        // TODO: handle errors

        navigate("/policies")
    }

    return (
        <tr className={"policy"}>
            <td>{policy.name}</td>
            <td>{policy.description}</td>
            <td>Veracode, Snyc</td>
            {/*<td>{item.scanConfigurationIds.map(x => <span>{x}</span>)}</td>*/}
            <td>{policy.scanConfigurationIds.length}</td>
            <td>TBD{/*{policy.gracePeriods.map(x => <span>{x}</span>)}*/}</td>
            {/*<CweList cwes={item.cweList}/>*/}
            <td>
                <span className={"action action-edit"} title={"Edit"} onClick={() => handleEdit()}>
                    <MdEdit/>
                </span>
                <span className={"action action-delete"} title={"Delete"} onClick={() => handleDelete()}>
                    <MdDeleteForever/>
                </span>
            </td>
        </tr>
    );
}

export default PolicyItem;