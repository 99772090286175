import axios from "axios";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";

import {VscError} from "react-icons/vsc";

import { AssetRecord } from "./AssetRecord";
import AnimatedShieldLoader from "../AnimatedShieldLoader";
import AssetRecordsList from "./AssetRecordsList";

const fetchData = async (): Promise<AssetRecord[]> => {
    let response = await axios.get("/assets")

    return response.data
}

export const AssetRecords: React.FC = () => {
    const {
        data,
        isLoading,
        isSuccess
    } = useQuery("get-assets", fetchData)

    const queryClient = useQueryClient()
    const location = useLocation()
    useEffect(() => {
        queryClient.invalidateQueries("get-assets")
    }, [location, queryClient])

    if (isLoading) {
        return <div id="loader">
            <AnimatedShieldLoader />
        </div>
    }

    if (isSuccess) {
        return (
            <>
                <AssetRecordsList items={data} />
            </>
        )
    }

    return <><VscError /> Failed to load, please, check console</>
}
