import {
    Box,
    Button,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography,
    Tooltip,
    
} from '@mui/material';

import { MenuItem, Chip, ChipProps, Checkbox } from '@mui/material';
//import AddIcon from '@mui/icons-material/Add';
import React, { useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { Alert, AlertTitle } from './components/Alert';
import { generateRules } from './mock-data/sample';

// Mock rules for demonstration
const mockRules = generateRules(10);

/*
const STEPS = [
    {
        id: 1,
        title: 'Basic Information',
        description: 'Enter the basic details for this security exception'
    },
    {
        id: 2,
        title: 'JIRA Authorization',
        description: 'Connect your JIRA account to proceed'
    },
    {
        id: 3,
        title: 'Risk Assessment',
        description: 'Evaluate the security risk and impact'
    },
    {
        id: 4,
        title: 'Mitigation Plan',
        description: 'Describe your mitigation strategy'
    },
    {
        id: 5,
        title: 'Rules Configuration',
        description: 'Set up automation rules for this exception'
    }
];
*/

//interface ExceptionCreationWizardProps {
//    onClose: () => void;
//}
const ExceptionCreationWizard = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState<{
        title: string;
        applicationId: string;
        description: string;
        jiraUsername: string;
        jiraToken: string;
        severity: string;
        category: string;
        justification: string;
        riskAssessment: string;
        proposedMitigation: string;
        expirationDate: string;
        selectedRules: string[]; // or number[] if the ruleId is a number
    }>({
        title: '',
        applicationId: '',
        description: '',
        jiraUsername: '',
        jiraToken: '',
        severity: '',
        category: '',
        justification: '',
        riskAssessment: '',
        proposedMitigation: '',
        expirationDate: '',
        selectedRules: [],
    });
    const [isJiraAuthorized, setIsJiraAuthorized] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };


    const handleJiraAuth = async () => {
        setIsSubmitting(true);
        try {
            // Mock JIRA authentication
            await new Promise(resolve => setTimeout(resolve, 1500));
            setIsJiraAuthorized(true);
            setSuccessMessage('JIRA authorization successful');
        } catch (err) {
            setError('Failed to authorize JIRA credentials');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleRuleToggle = (ruleId) => {
        setFormData(prev => ({
            ...prev,
            selectedRules: prev.selectedRules.includes(ruleId)
                ? prev.selectedRules.filter(id => id !== ruleId)
                : [...prev.selectedRules, ruleId]
        }));
    };

    interface ValidationError {
        field: string;
        message: string;
    }

    const validateStep = (): ValidationError[] => {
        const errors: ValidationError[] = [];

        switch (currentStep) {
            case 1:
                if (!formData.title) {
                    errors.push({ field: 'title', message: 'Title is required' });
                }
                if (!formData.applicationId) {
                    errors.push({ field: 'applicationId', message: 'Application ID is required' });
                }
                break;
            case 2:
                if (!isJiraAuthorized) {
                    errors.push({ field: 'jira', message: 'JIRA authorization is required' });
                }
                break;
            case 3:
                if (!formData.severity) {
                    errors.push({ field: 'severity', message: 'Severity is required' });
                }
                if (!formData.category) {
                    errors.push({ field: 'category', message: 'Category is required' });
                }
                break;
            case 4:
                if (!formData.proposedMitigation) {
                    errors.push({ field: 'proposedMitigation', message: 'Mitigation plan is required' });
                }
                if (!formData.expirationDate) {
                    errors.push({ field: 'expirationDate', message: 'Expiration date is required' });
                }
                break;
        }

        return errors;
    };
/*
    function getChipColor(status: string): OverridableStringUnion<"default" | "primary" | "secondary" | "error" | "info" | "success" | "warning", ChipPropsColorOverrides> {
        switch (status) {
            case 'Active':
                return 'success';
            case 'Inactive':
                return 'error';
            default:
                return 'warning';
        }
    }

 */
    const handleNext = () => {
        const validationErrors = validateStep();
        if (validationErrors.length > 0) {
            setError(validationErrors[0].message);
            return;
        }
        setCurrentStep(prev => prev + 1);
        setError(null);
    };
    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            // Mock API call
            await new Promise(resolve => setTimeout(resolve, 2000));
            setSuccessMessage('Exception created successfully');
            setTimeout(() => {
            }, 1500);
        } catch (err) {
            setError('Failed to create exception');
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <Box>
                        <TextField
                            label="Title"
                            value={formData.title}
                            onChange={(e) => handleInputChange('title', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Application ID"
                            value={formData.applicationId}
                            onChange={(e) => handleInputChange('applicationId', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Description"
                            value={formData.description}
                            onChange={(e) => handleInputChange('description', e.target.value)}
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                        />
                    </Box>
                );
            case 2:
                return (
                    <Box>
                        <TextField
                            label="JIRA Username"
                            value={formData.jiraUsername}
                            onChange={(e) => handleInputChange('jiraUsername', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="JIRA API Token"
                            value={formData.jiraToken}
                            type="password"
                            onChange={(e) => handleInputChange('jiraToken', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <Button
                            onClick={handleJiraAuth}
                            disabled={isSubmitting || !formData.jiraUsername || !formData.jiraToken}
                            variant="contained"
                            color="primary"
                            startIcon={isSubmitting ? <Oval height={16} width={16} color="white" /> : null}
                            fullWidth
                            sx={{ mt: 2 }}
                        >
                            Authorize JIRA
                        </Button>
                    </Box>
                );
            case 3:
                return (
                    <Box>
                        <TextField
                            label="Severity"
                            value={formData.severity}
                            onChange={(e) => handleInputChange('severity', e.target.value)}
                            fullWidth
                            select
                            margin="normal"
                        >
                            <MenuItem value="1">Low</MenuItem>
                            <MenuItem value="2">Medium</MenuItem>
                            <MenuItem value="3">High</MenuItem>
                            <MenuItem value="4">Critical</MenuItem>
                        </TextField>
                        <TextField
                            label="Category"
                            value={formData.category}
                            onChange={(e) => handleInputChange('category', e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Justification"
                            value={formData.justification}
                            onChange={(e) => handleInputChange('justification', e.target.value)}
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                        />
                    </Box>
                );
            case 4:
                return (
                    <Box>
                        <TextField
                            label="Risk Assessment"
                            value={formData.riskAssessment}
                            onChange={(e) => handleInputChange('riskAssessment', e.target.value)}
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                        />
                        <TextField
                            label="Proposed Mitigation"
                            value={formData.proposedMitigation}
                            onChange={(e) => handleInputChange('proposedMitigation', e.target.value)}
                            fullWidth
                            multiline
                            rows={4}
                            margin="normal"
                        />
                        <TextField
                            label="Expiration Date"
                            value={formData.expirationDate}
                            onChange={(e) => handleInputChange('expirationDate', e.target.value)}
                            type="date"
                            fullWidth
                            margin="normal"
                        />
                    </Box>
                );
            case 5:
                return (
                    <Box>
                        <Box display="flex" justifyContent="flex-end" mb={2}>
                            <Button
                                onClick={() => {
                                    // Navigate to Visual Rule Builder
                                }}
                                variant="contained"
                                color="primary"
                                //startIcon={<AddIcon />}
                            >
                                Create New Rule
                            </Button>
                        </Box>
                        <Box maxHeight="400px" overflow="auto">
                            {mockRules.map((rule) => (
                                <Box
                                    key={rule.RuleId}
                                    p={2}
                                    bgcolor="grey.800"
                                    borderRadius={1}
                                    mb={2}
                                    sx={{ '&:hover': { bgcolor: 'grey.700' } }}
                                >
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Box>
                                            <Typography variant="h6">{rule.RuleName}</Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {rule.Description}
                                            </Typography>
                                            <Box mt={1}>
                                                <Chip
                                                    label={rule.Status}
                                                    color={
                                                        rule.Status === 'Active' ? 'success' as ChipProps['color'] :
                                                            rule.Status === 'Inactive' ? 'error' as ChipProps['color'] :
                                                                'warning' as ChipProps['color']
                                                    }
                                                    size="small"
                                                />
                                                <Chip
                                                    label={rule.RuleExpressionType}
                                                    color="primary"
                                                    size="small"
                                                    sx={{ ml: 1 }}
                                                />
                                            </Box>
                                        </Box>
                                        <Checkbox
                                            checked={formData.selectedRules.includes(rule.RuleId)}
                                            onChange={() => handleRuleToggle(rule.RuleId)}
                                            color="primary"
                                        />
                                    </Box>
                                    <Box mt={2}>
                                        <Tooltip title={rule.Expression} arrow>
                                            <Typography variant="body2" noWrap>
                                                {rule.Expression.length > 50
                                                    ? `${rule.Expression.substring(0, 50)}...`
                                                    : rule.Expression}
                                            </Typography>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Box maxWidth="md" mx="auto" mt={4}>
            <Stepper activeStep={currentStep - 1} alternativeLabel>
                {['Basic Information', 'JIRA Authorization', 'Risk Assessment', 'Mitigation Plan', 'Rules Configuration'].map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Box mt={4}>
                {error && (
                    <Alert className="mb-4">
                        <AlertTitle>{error}</AlertTitle>
                    </Alert>
                )}

                {successMessage && (
                    <Alert className="mb-4">
                        <AlertTitle>{successMessage}</AlertTitle>
                    </Alert>
                )}

                {renderStep()}
            </Box>

            <Box display="flex" justifyContent="space-between" mt={4}>
                <Button
                    onClick={() => setCurrentStep(prev => prev - 1)}
                    disabled={currentStep === 1 || isSubmitting}
                >
                    Previous
                </Button>
                <Button
                    onClick={currentStep === 5 ? handleSubmit : handleNext}
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                >
                    {isSubmitting ? (
                        <>
                            <Oval height={16} width={16} color="white" /> Processing...
                        </>
                    ) : currentStep === 5 ? 'Create Exception' : 'Next'}
                </Button>
            </Box>
        </Box>
    );
};

export default ExceptionCreationWizard;