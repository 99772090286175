import React,{ useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { EnhancedSecurityRule } from "./RuleTypes";
import { RulesList } from "./RulesList";
import { generateRules } from '../mock-data/sample';


export const Rules: React.FC = () => {
    const [mockRules, setMockRules] = useState<EnhancedSecurityRule[]>([]);

    useEffect(() => {
        // Generate rules once when component mounts
        setMockRules(generateRules(20));
    }, []); // Empty dependency array means this only runs once

    return (
        <>
            <div className="wrap-btn-create">
                <Link to="../exception-manager/rules/builder" className="btn-with-icon btn-main">
                    <span>Create Rule</span>
                </Link>
            </div>
            <RulesList items={mockRules} />
        </>
    );
};
export default Rules;