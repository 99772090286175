import React, {useEffect, useState} from "react";
import {ScanConfiguration, ScanType} from "./ScanCongifurationTypes";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";

// TODO: move to common place
const scanTypes = Object.keys(ScanType)
    .map(x => parseInt(x))
    .filter(x => !isNaN(x))
    .map(x => {
        return {
            id: x,
            name: Object.values(ScanType)[x]
        }
    })

const ScanConfigurationEdit: React.FC = () => {
    const params = useParams()
    console.log(params)

    // TODO: inline with type
    const defaultScanConfig: ScanConfiguration = {
        id: '',
        name: '',
        scanType: ScanType.Sast,
        commonWeaknessEnumerationIds: []
    }

    const [scanConfiguration, setScanConfiguration] = useState(defaultScanConfig)

    useEffect(() => {
        const fetchData = async () => {
            let r = await axios.get(`/scanConfigurations/${params.id}`);
            console.log(r.data)
            setScanConfiguration(r.data.scanConfiguration)
        }

        // TODO: catch
        fetchData()

    }, [params])

    const navigate = useNavigate()

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const name = e.currentTarget.scanConfigurationName.value
        const scanType = e.currentTarget.scanConfigurationType.value
        const cweIds = e.currentTarget.scanConfigurationCweIds.value.split(",").map((x: string) => Number(x.trim())).filter((x: number) => !isNaN(x))

        // TODO: validate ^^

        const payload = {
            name: name,
            scanType: scanType,
            commonWeaknessEnumerationIds: cweIds
        }

        await axios.put(`/scanConfigurations/${params.id}`, payload)

        // TODO: handle validation errors

        navigate("/scanConfigurations")
    }

    function handleCancel(e: React.SyntheticEvent) {
        e.preventDefault()
        navigate("/scanConfigurations")
    }

    return (
        <>
            <h1>Create Scan Configuration</h1>
            <form autoComplete={"off"} onSubmit={handleSubmit}>
                <div className={"form-control"}>
                    <label htmlFor="#scanConfigurationName">Name:</label>
                    <input
                        type="text"
                        id={"scanConfigurationName"}
                        name={"scanConfigurationName"}
                        defaultValue={scanConfiguration.name}
                    />
                </div>
                <div className={"form-control"}>
                    <label htmlFor="#scanConfigurationType">Type:</label>
                    <select
                        id={"scanConfigurationType"}
                        name={"scanConfigurationType"}
                    >
                        {scanTypes.map(x => <option key={x.name} value={x.name}
                                                    selected={x.name === scanConfiguration.scanType}>{x.name}</option>)}
                    </select>
                </div>
                <div className={"form-control"}>
                    {/*TODO: make something better*/}
                    <label htmlFor="#scanConfigurationCweIds">CWE Ids:</label>
                    <input
                        type="text"
                        id={"scanConfigurationCweIds"}
                        name={"scanConfigurationCweIds"}
                        placeholder={"1, 2, 3"}
                        defaultValue={scanConfiguration.commonWeaknessEnumerationIds.join(", ")}
                    />
                </div>
                <div className={"form-control"}>
                    <input type="button" id={"scanConfigurationCreateCancel"} onClick={handleCancel} value={"Cancel"}></input>
                    <input type="submit" id={"scanConfigurationCreate"} value={"Save"}></input>
                </div>
            </form>
        </>
    );
};

export default ScanConfigurationEdit;