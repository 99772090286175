import React, { useState } from 'react';
import { MultiselectOption } from './Multiselect/MultiselectOption';
import SelectAllMultiselect from './Multiselect/SelectAllMultiselect';
import { DateRangePicker } from 'react-date-range'; // Add this line to import the DateRangePicker component

import { Button } from 'react-bootstrap';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './JiraStatisticFilter.scss';
import { addDays, endOfDay, isSameDay, startOfYear } from 'date-fns';
import Popup from 'reactjs-popup';
import { JiraStatisticAppFamilyFilter, JiraStatisticScale } from './JiraStatisticFilterConstants';


interface Props {
    applications: MultiselectOption[];
    productFamilies: MultiselectOption[];
    applicationChangedCallback: Function;
    productFamilyChangedCallback: Function;
    scaleChangedCallback: Function;
    dateRangeChangedCallback: Function;

    defaultStartDate: Date;
    defaultEndDate: Date;
    defaultScale: string;
    defaultAppFamilyFilter: string;

    showScale: boolean;
    id: string;
}


const JiraStatisticFilter: React.FC<Props> = (props: Props) => {
    const [scale, setScale] = useState(props.defaultScale);
    const [appFamilyFilter, setFilter] = useState(props.defaultAppFamilyFilter);
    const [selectedStartDate, setSelectedStartDate] = useState(props.defaultStartDate);
    const [selectedEndDate, setSelectedEndDate] = useState(props.defaultEndDate);

    const popupButtonId = props.id + 'popupButton';

	const scalegroupName = props.id + 'scale';
    const dayRadioId = props.id + 'dayRadio';
    const weekRadioId = props.id + 'weekRadio';
    const monthRadioId = props.id + 'monthRadio';

    const filtergroupName = props.id + 'filter';
    const appRadioId = props.id + 'appRadio';
    const familyRadioId = props.id + 'familyRadio';


    const scaleChanged = (selected: any) => {
        setScale(selected.target.value);
        props.scaleChangedCallback(selected.target.value);
    }

    const filterChanged = (selected: any) => {
        setFilter(selected.target.value);
    }

    const [dateRange, setDateRange] = useState([
    {
        startDate: props.defaultStartDate,
        endDate: props.defaultEndDate,
        key: 'selection'
    }]);

    const applyDateSelection = () => {
		let buttonId = '#' + popupButtonId;
        if(document.querySelector(buttonId)){
            setSelectedStartDate(dateRange[0].startDate);
            setSelectedEndDate(dateRange[0].endDate);
            (document.querySelector(buttonId) as HTMLButtonElement).click();
            props.dateRangeChangedCallback(dateRange[0].startDate, dateRange[0].endDate);
        }
    }

    const predefinedRanges = [
        {
            label: "Last 30 days",
            range: () => ({
              startDate: addDays(new Date(), -30),
              endDate: new Date()
            }),
            isSelected(range) {
              const definedRange = this.range();
              return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
              );
            }
          },
        {
          label: "Last 90 days",
          range: () => ({
            startDate: addDays(new Date(), -90),
            endDate: new Date()
          }),
          isSelected(range) {
            const definedRange = this.range();
            return (
              isSameDay(range.startDate, definedRange.startDate) &&
              isSameDay(range.endDate, definedRange.endDate)
            );
          }
        },
        {
          label: "YTD",
          range: () => ({
            startDate: startOfYear(new Date()),
            endDate: endOfDay(new Date())
          }),
          isSelected(range) {
            const definedRange = this.range();
            return (
              isSameDay(range.startDate, definedRange.startDate) &&
              isSameDay(range.endDate, definedRange.endDate)
            );
          }
        }
      ]
    return (
        <div className='jira-statistic-filter'>
            {props.showScale &&
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input value={JiraStatisticScale.Day} checked={scale === JiraStatisticScale.Day} type="radio" className="btn-check" name={scalegroupName} id={dayRadioId} autoComplete="off" onChange={scaleChanged}/>
                <label className="btn btn-outline-primary" htmlFor={dayRadioId}>Day</label>

                <input value={JiraStatisticScale.Week}checked={scale === JiraStatisticScale.Week} type="radio" className="btn-check" name={scalegroupName} id={weekRadioId} autoComplete="off" onChange={scaleChanged}/>
                <label className="btn btn-outline-primary" htmlFor={weekRadioId}>Week</label>

                <input value={JiraStatisticScale.Month} checked={scale === JiraStatisticScale.Month} type="radio" className="btn-check" name={scalegroupName} id={monthRadioId} autoComplete="off" onChange={scaleChanged}/>
                <label className="btn btn-outline-primary" htmlFor={monthRadioId}>Month</label>
            </div>}
            <Popup trigger={<Button id={popupButtonId}>{selectedStartDate.toDateString()} - {selectedEndDate.toDateString()}</Button>} position="right top">
            <div className='date-popup'>
                <div>
                    <DateRangePicker
                        onChange={item => setDateRange([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={dateRange}
                        direction="horizontal"
                        staticRanges={predefinedRanges}
                        inputRanges={[]}
                        />
                </div>
                <div>
                    <Button onClick={applyDateSelection}>Apply</Button>
                </div>
            </div>
            </Popup>
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input value={JiraStatisticAppFamilyFilter.Application} checked={appFamilyFilter === JiraStatisticAppFamilyFilter.Application} type="radio" className="btn-check" name={filtergroupName} id={appRadioId} autoComplete="off" onChange={filterChanged}/>
                <label className="btn btn-outline-primary" htmlFor={appRadioId}>Application</label>

                <input value={JiraStatisticAppFamilyFilter.ProductFamily} checked={appFamilyFilter === JiraStatisticAppFamilyFilter.ProductFamily} type="radio" className="btn-check" name={filtergroupName} id={familyRadioId} autoComplete="off" onChange={filterChanged}/>
                <label className="btn btn-outline-primary" htmlFor={familyRadioId}>Product family</label>
            </div>
            {appFamilyFilter === JiraStatisticAppFamilyFilter.Application &&
                <SelectAllMultiselect options={props.applications} onChange={props.applicationChangedCallback} />
            }
            {appFamilyFilter === JiraStatisticAppFamilyFilter.ProductFamily &&
                <SelectAllMultiselect options={props.productFamilies} onChange={props.productFamilyChangedCallback} />
            }
        </div>
    );
};

export default JiraStatisticFilter;