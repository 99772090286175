export interface SecurityException {
    Id: string;
    ApplicationId: string;
    Title: string;
    Description: string;
    CreatedDate: Date;
    ExpirationDate: Date;
    JiraTicketId: string;
    CurrentStatus: SecurityExceptionStatus;
    Category: string;
    Justification: string;
    Severity: number;
}

export enum SecurityExceptionStatus {
    Pending,
    FirstApproval,
    Approved,
    Rejected,
    Expired,
    Canceled
}

export interface App {
    primary_key: string;
    app_name: string;
    Description: string;
    AttachedProfile: string;
    IsSaEnabled: boolean;
    PolicyName: string;
    CreatedDateTime: Date;
    PolicyComplianceStatus: string;
    ExternalId: string;
    LastCompletedScanDate: Date | null;
    CanonicalId: string;
    AppSecSupportTierLevel: string;
    SASTStatus: string;
    RepoName: string;
    RepoUrl: string;
    ApplicablePolicyIds: string[];
    SecurityExceptionIds: string[];
}

export interface EnhancedSecurityRule {
    RuleId: string;
    RuleName: string;
    Description: string;
    Expression: string;
    RuleExpressionType: string;
    Status: string;
    CreatedBy: string;
    CreatedDate: string;
    LastModifiedBy?: string | null;
    LastModifiedDate?: string | null;
    PreviousVersion?: string | null;
    NextReviewDate: string;
    ValidationStatus: string;
    ActionOnSuccess: string;
    ActionOnFailure: string;
    Version: number;
    Metadata?: Record<string, string>;
}

export function generateRules(count: number): EnhancedSecurityRule[] {
    const ruleTypes = ['LambdaExpression', 'JavaScript'];
    const statuses = ['Active', 'Inactive', 'Pending'];
    const validationStatuses = ['Valid', 'Invalid', 'Pending'];
    const actions = ['AutoApprove', 'RequireManualReview', 'RejectAutomatically', 'OutputExpression'];

    const commonExpressions = [
        'Severity == "Critical" && CWE != null',
        'Category == "ThirdParty" && Justification.Contains("Vendor Acknowledgment")',
        'ExpirationDate > CurrentDate.AddMonths(6)',
        'ApplicationType == "Legacy" && RetirementDate != null',
        'ComplianceImpact != null && RiskAssessment.Contains("Regulatory")',
        'Environment == "Development" && ImpactScope == "Limited"',
        'Duration <= 90 && RemediationPlan != null',
        'BusinessCriticality == "High" && ExecutiveApproval == true',
        'Severity == "Low" && RiskAssessment.Contains("Acceptable")',
        'Category == "Deprecated" || Description.Contains("EOL")'
    ];

    return Array.from({ length: count }, (_, i) => {
        const createdDate = new Date(2023, i % 12, i % 28);
        const nextReviewDate = new Date(createdDate);
        nextReviewDate.setMonth(nextReviewDate.getMonth() + 3);

        const lastModified = Math.random() > 0.5 ? new Date(createdDate) : null;
        if (lastModified) {
            lastModified.setDate(lastModified.getDate() + Math.floor(Math.random() * 30));
        }

        return {
            RuleId: `RULE-${i + 1}`,
            RuleName: `Security Rule ${i + 1}`,
            Description: `Automated rule for handling ${commonExpressions[i % commonExpressions.length].split(" ")[0]} conditions`,
            Expression: commonExpressions[i % commonExpressions.length],
            RuleExpressionType: ruleTypes[Math.floor(Math.random() * ruleTypes.length)],
            Status: statuses[Math.floor(Math.random() * statuses.length)],
            CreatedBy: `admin${i % 3 + 1}@example.com`,
            CreatedDate: createdDate.toISOString(),
            LastModifiedBy: lastModified ? `editor${i % 2 + 1}@example.com` : null,
            LastModifiedDate: lastModified?.toISOString() || null,
            PreviousVersion: i % 3 === 0 ? `RULE-${i + 1}-v${i}` : null,
            NextReviewDate: nextReviewDate.toISOString(),
            ValidationStatus: validationStatuses[Math.floor(Math.random() * validationStatuses.length)],
            ActionOnSuccess: actions[Math.floor(Math.random() * actions.length)],
            ActionOnFailure: actions[Math.floor(Math.random() * actions.length)],
            Version: Math.floor(Math.random() * 3) + 1,
            Metadata: {
                priority: ['High', 'Medium', 'Low'][Math.floor(Math.random() * 3)],
                category: ['Security', 'Compliance', 'Performance'][Math.floor(Math.random() * 3)],
                department: ['IT', 'Security', 'Development'][Math.floor(Math.random() * 3)]
            }
        };
    });
}

export function generateExceptions(appId: string, count: number): SecurityException[] {
    const exceptions: SecurityException[] = [];

    for (let i = 0; i < count; i++) {
        const exception: SecurityException = {
            Id: `EXCEPTION#${appId}-${i + 1}`,
            ApplicationId: appId,
            Title: `Exception ${i + 1} for App ${appId}`,
            Description: `Description for Exception ${i + 1} of App ${appId}`,
            CreatedDate: new Date(2023, i % 12, i % 28, i % 24, i % 60, i % 60),
            ExpirationDate: new Date(2024, (i + 1) % 12, (i + 1) % 28, (i + 1) % 24, (i + 1) % 60, (i + 1) % 60),
            JiraTicketId: `TESTPS-${i + 1}`,
            CurrentStatus: [
                SecurityExceptionStatus.Pending,
                SecurityExceptionStatus.FirstApproval,
                SecurityExceptionStatus.Approved,
                SecurityExceptionStatus.Rejected,
                SecurityExceptionStatus.Expired,
                SecurityExceptionStatus.Canceled
            ][Math.floor(Math.random() * 6)],
            Category: ['Security', 'Compliance', 'Performance', 'Functionality'][Math.floor(Math.random() * 4)],
            Justification: `Justification for Exception ${i + 1} of App ${appId}`,
            Severity: Math.floor(Math.random() * 4) + 1
        };

        exceptions.push(exception);
    }

    return exceptions;
}

export function generateApps(count: number): App[] {
    const apps: App[] = [];

    for (let i = 0; i < count; i++) {
        const app: App = {
            primary_key: `APP#${i + 1}`,
            app_name: `Some Dayforce Asset Registered Application ${i + 1}`,
            Description: `Description that describes the application in significant detail ${i + 1}`,
            AttachedProfile: `profile-${i + 1}`,
            IsSaEnabled: Math.random() > 0.5,
            PolicyName: `Policy ${i + 1}`,
            CreatedDateTime: new Date(2022, i % 12, i % 28, i % 24, i % 60, i % 60),
            PolicyComplianceStatus: ['Compliant', 'Non-Compliant'][Math.floor(Math.random() * 2)],
            ExternalId: `external-id-${i + 1}`,
            LastCompletedScanDate: Math.random() > 0.5 ? new Date(2023, i % 12, i % 28, i % 24, i % 60, i % 60) : null,
            CanonicalId: `CANONICAL-ID-${i + 1}`,
            AppSecSupportTierLevel: ['Tier 1', 'Tier 2', 'Tier 3'][Math.floor(Math.random() * 3)],
            SASTStatus: ['Not Executed', 'In Progress', 'Completed'][Math.floor(Math.random() * 3)],
            RepoName: `repo-${i + 1}`,
            RepoUrl: `https://github.com/example/repo-${i + 1}`,
            ApplicablePolicyIds: [`policy-${i + 1}`, `policy-${i + 2}`, `policy-${i + 3}`],
            SecurityExceptionIds: []
        };

        // Generate 2 to 4 security exceptions for each app
        const numExceptions = Math.floor(Math.random() * 3) + 2;
        const exceptions = generateExceptions(app.primary_key, numExceptions);
        app.SecurityExceptionIds = exceptions.map((e) => e.Id);
        apps.push(app);
    }

    return apps;
}
