import { TableRow, TableCell } from '@mui/material';
import { MdClose } from "react-icons/md";
import { BsShieldFillCheck, BsShieldFillX, BsShieldFillExclamation, BsShieldSlash, BsCheckCircle, BsXCircle, BsDashCircle, BsShieldFillMinus } from "react-icons/bs";
import { Favorite } from "./Favorite";
import axios from "axios";
import { NavLink } from "react-router-dom";

type Props = {
    item: Favorite
    refreshFn: Function
}

export const FavoriteItem: React.FC<Props> = ({item, refreshFn}) => {   
    const addRemoveToFavorites = async(appId:string) => {
        await axios.post("/favorites/" + appId);
        refreshFn();
    }

    const currentDate = new Date();
    const lastCompletedScanDate = new Date(item.lastCompletedScanDate);
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    let textColor = "#1EB980";
    if (lastCompletedScanDate < thirtyDaysAgo) {
        textColor = "#fd4433";
    }

    const getPolicyComplianceIcon = (status: string) => {
        switch (status) {
            case "PASSED":
                return <BsShieldFillCheck size={15} />;
            case "DID_NOT_PASS":
                return <BsShieldFillX size={15} />;
            case "CONDITIONAL_PASS":
                return <BsShieldFillExclamation size={15} />;
            case "NOT_ASSESSED":
                return <BsShieldSlash size={15} />;
            case "DETERMINING":
                return <BsShieldFillMinus size={15} />;
            default:
                return null;
        }
    };
    
    return (
        <TableRow>
            <TableCell>
                <NavLink to={`/assets/${item.assetId}`}>{item.applicationName}</NavLink>
            </TableCell>
            <TableCell>{item.workflow}</TableCell>
            <TableCell>
                <a href={item.repoUrl} target="_blank" rel="noreferrer">{item.repoName}</a>
            </TableCell>
            <TableCell>
                {item.scarStatus === "Completed" && <BsCheckCircle size={15} style={{ color: "green" }} />}
                {item.scarStatus === "Failed" && <BsXCircle size={15} style={{ color: "red" }} />}
                {item.scarStatus === "Canceled" && <BsDashCircle size={15} style={{ color: "grey" }} />}
                {" "}{item.scarStatus}
            </TableCell>
            <TableCell>
                {item.sastStatus === "Completed" && <BsCheckCircle size={15} style={{ color: "green" }} />}
                {item.sastStatus === "Failed" && <BsXCircle size={15} style={{ color: "red" }} />}
                {item.sastStatus === "Canceled" && <BsDashCircle size={15} style={{ color: "grey" }} />}
                {" "}{item.sastStatus}
            </TableCell>
            <TableCell>{item.policyName}</TableCell>
            <TableCell>
                <div className={`policyComplianceStatus ${item.policyComplianceStatus}`}>
                    {getPolicyComplianceIcon(item.policyComplianceStatus)}
                    {item.policyComplianceStatus}
                </div>
            </TableCell>
            <TableCell style={{ color: textColor }}>
                {new Date(item.lastCompletedScanDate).toISOString().replace('T', " ").split('.000Z')[0]}
            </TableCell>
            <TableCell>
                <span className="action action-import" title="Remove from favorites" onClick={() => addRemoveToFavorites(item.appId)}>
                    <MdClose size={21} />
                </span>
            </TableCell>
        </TableRow>
    );
};