import React, { useState } from 'react';
import { mockExceptions } from '../mock-data/exceptions';
import { MdLaunch } from 'react-icons/md';
import { BsShieldFillCheck, BsShieldFillX, BsShieldFillExclamation } from 'react-icons/bs';

export default function JiraExceptionsList() {
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('all');

    const getStatusIcon = (status: string) => {
        switch (status) {
            case 'Approved':
                return <BsShieldFillCheck className="text-emerald-500" />;
            case 'Rejected':
                return <BsShieldFillX className="text-red-500" />;
            case 'Pending':
                return <BsShieldFillExclamation className="text-yellow-500" />;
            default:
                return null;
        }
    };

    const getSeverityColor = (severity: string) => {
        switch (severity) {
            case 'Critical':
                return 'bg-red-100 text-red-800';
            case 'High':
                return 'bg-orange-100 text-orange-800';
            case 'Medium':
                return 'bg-yellow-100 text-yellow-800';
            default:
                return 'bg-blue-100 text-blue-800';
        }
    };

    const filteredExceptions = mockExceptions.filter(exception => {
        const matchesSearch =
            exception.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            exception.jiraTicketId.toLowerCase().includes(searchTerm.toLowerCase());

        const matchesStatus =
            statusFilter === 'all' ||
            exception.status.toLowerCase() === statusFilter.toLowerCase();

        return matchesSearch && matchesStatus;
    });

    return (
        <div className="bg-gray-800 rounded-lg shadow p-4">
            <div className="mb-4 flex gap-4">
                <input
                    type="text"
                    placeholder="Search by title or Jira ticket..."
                    className="flex-1 p-2 rounded bg-gray-700 text-white"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <select
                    className="p-2 rounded bg-gray-700 text-white"
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                >
                    <option value="all">All Statuses</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                    <option value="pending">Pending</option>
                </select>
            </div>

            <div className="overflow-x-auto">
                <table className="min-w-full">
                    <thead className="bg-gray-700">
                    <tr>
                        <th className="p-3 text-left text-white">Status</th>
                        <th className="p-3 text-left text-white">Ticket</th>
                        <th className="p-3 text-left text-white">Title</th>
                        <th className="p-3 text-left text-white">Severity</th>
                        <th className="p-3 text-left text-white">Created</th>
                        <th className="p-3 text-left text-white">Expires</th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-700">
                    {filteredExceptions.map((exception) => (
                        <tr key={exception.id} className="hover:bg-gray-700">
                            <td className="p-3">
                                <div className="flex items-center">
                                    {getStatusIcon(exception.status)}
                                    <span className="ml-2 text-gray-300">
                                            {exception.status}
                                        </span>
                                </div>
                            </td>
                            <td className="p-3">
                                <a
                                    href={`https://jira.company.com/browse/${exception.jiraTicketId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="flex items-center text-blue-400 hover:text-blue-300"
                                >
                                    {exception.jiraTicketId}
                                    <MdLaunch className="ml-1" />
                                </a>
                            </td>
                            <td className="p-3 text-gray-300">
                                {exception.title}
                            </td>
                            <td className="p-3">
                                    <span className={`px-2 py-1 rounded-full text-sm ${getSeverityColor(exception.severity)}`}>
                                        {exception.severity}
                                    </span>
                            </td>
                            <td className="p-3 text-gray-300">
                                {new Date(exception.createdDate).toLocaleDateString()}
                            </td>
                            <td className="p-3 text-gray-300">
                                {new Date(exception.expirationDate).toLocaleDateString()}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}