import React, { useState } from 'react';
import ExceptionCreationWizard from './ExceptionCreationWizard';
import AnimatedShieldLoader from '../AnimatedShieldLoader';
import { FaPlus } from 'react-icons/fa';

const CreateExceptionWizard = () => {
    //const navigate = useNavigate();
    const [isLoading] = useState(false);

    if (isLoading) {
        return (
            <div id="loader">
                <AnimatedShieldLoader />
            </div>
        );
    }

    return (
        <div className="container mx-auto p-4">
            <div className="mb-6">
                <div className="flex items-center gap-2">
                    <FaPlus className="text-blue-500" />
                    <h1 className="text-2xl font-semibold">Create Security Exception</h1>
                </div>
                <p className="text-gray-400 mt-2">
                    Create a new security exception and configure its rules and approvals.
                </p>
            </div>

            <ExceptionCreationWizard />
        </div>
    );
};

export default CreateExceptionWizard;