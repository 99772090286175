import React, { useState } from 'react';
import ReactSelect, { ActionMeta, MultiValue } from "react-select";
import { MultiselectOption } from './MultiselectOption';
import './SelectAllMultiselect.scss';

interface Props {
  options: MultiselectOption[];
  onChange: Function;
}

const SelectAllMultiselect: React.FC<Props> = (props: Props) => {
  const allOption: MultiselectOption = {
    label: "All",
    value: "*"
  };
  
  const [selectedOptions, setSelectedOptions] = useState<MultiselectOption[]>([]);

  const handleChange = (
    selected: MultiValue<MultiselectOption>,
    event: ActionMeta<MultiselectOption>
  ) => {
    if (!selected) {
      setSelectedOptions([]);
      props.onChange([]);
      return;
    }

    const selectedArray = Array.from(selected);

    // Handle "All" selection
    if (event.action === "select-option" && 
        event.option?.value === allOption.value) {
      const allSelected = [allOption, ...props.options];
      setSelectedOptions(allSelected);
      props.onChange(allSelected);
      return;
    }

    // Handle "All" deselection
    if (event.action === "remove-value" && 
        event.removedValue?.value === allOption.value) {
      const withoutAll = selectedArray.filter(
        option => option.value !== allOption.value
      );
      setSelectedOptions(withoutAll);
      props.onChange(withoutAll);
      return;
    }

    // Handle normal selection/deselection
    if (selectedArray.length === props.options.length && 
        !selectedArray.find(opt => opt.value === allOption.value)) {
      // If all individual options are selected, add the "All" option
      const withAll = [allOption, ...selectedArray];
      setSelectedOptions(withAll);
      props.onChange(withAll);
      return;
    }

    // Regular selection handling
    setSelectedOptions(selectedArray);
    props.onChange(selectedArray);
  };

  return (
    <ReactSelect
      className="react-select selectall-multiselect"
      classNamePrefix="react-select"
      {...props}
      value={selectedOptions}
      options={[allOption, ...props.options]}
      onChange={handleChange}
      closeMenuOnSelect={false}
      isMulti={true}
      isClearable={true}
      hideSelectedOptions={true}
    />
  );
};

export default SelectAllMultiselect;