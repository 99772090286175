import React, { useState } from "react";
import { 
    Table,
    TableBody, 
    TableRow, 
    TableCell
} from '@mui/material';
import { VeracodeApplication } from "./VeracodeApplication";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdAddLink } from "react-icons/md";

type ApplicationItemProps = {
    item: VeracodeApplication
}

interface ValidationErrorResponse {
    validationFailures: string[];
}

interface ApiError {
    code: string;
    response: {
        data: ValidationErrorResponse;
    };
}

export const VeracodeApplicationItem: React.FC<ApplicationItemProps> = ({ item }) => {
    const navigate = useNavigate();
    const [validationFailures, setValidationFailures] = useState<string[]>([]);

    async function onImportHandler() {
        console.log(`importing ${item.name}...`);

        const payload = {
            id: item.id,
            guid: item.guid,
            name: item.name,
            description: item.policyName,
            canonicalId: item.canonicalId
        };

        try {
            await axios.post("/veracode/apps/import", payload);
            navigate("/applications");
        } catch (err) {
            console.log(err);
            const error = err as ApiError;
            if (error.code === "ERR_BAD_REQUEST") {
                const failures = error.response.data.validationFailures;
                setValidationFailures(failures);
            }
        }
    }

    return (
        <TableRow>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.policyName}</TableCell>
            <TableCell>{item.lastCompletedScanDate}</TableCell>
            <TableCell>
                {validationFailures.length > 0 && (
                    <Table className="validation-failures">
                        <TableBody>
                            {validationFailures.map((x, index) => (
                                <TableRow key={index}>
                                    <TableCell>{x}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                {item.isImported ? (
                    "Imported"
                ) : (
                    <span 
                        className="action action-import" 
                        title="Import" 
                        onClick={onImportHandler}
                    >
                        <MdAddLink size={25}/>
                    </span>
                )}
            </TableCell>
        </TableRow>
    );
}