import React, { useState } from "react";
import { RuleItem } from "./RuleItem";
import { EnhancedSecurityRule } from "./RuleTypes";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";
import "./RulesList.scss";

type Props = {
    items: EnhancedSecurityRule[];  // Make sure this is 'items'
};

export const RulesList: React.FC<Props> = ({ items }) => {  // And this matches
    const [sortColumn, setSortColumn] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [statusFilter, setStatusFilter] = useState<string>("all");
    

    const handleColumnHeaderClick = (column: string) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    const sortedRules = [...items].sort((a, b) => {
        const columnMappings: Record<string, keyof EnhancedSecurityRule> = {
            name: "RuleName",
            type: "RuleExpressionType",
            status: "Status",
            lastModified: "LastModifiedDate",
            nextReview: "NextReviewDate"
        };

        const propertyName = columnMappings[sortColumn];

        if (propertyName) {
            const aValue = String(a[propertyName] || '');
            const bValue = String(b[propertyName] || '');

            const result = aValue.localeCompare(bValue);
            return sortOrder === "asc" ? result : -result;
        }
        return 0;
    });

    const filteredRules = sortedRules.filter(rule =>
        (rule.RuleName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            rule.Description.toLowerCase().includes(searchQuery.toLowerCase())) &&
        (statusFilter === "all" || rule.Status === statusFilter)
    );

    const getArrow = (column: string) => {
        return sortColumn === column ? (sortOrder === "asc" ? "↑" : "↓") : "↑↓";
    };

    // Pagination logic
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(filteredRules.length / itemsPerPage);
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const paginatedRules = filteredRules.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <div className="table-with-search">
            <div className="table-list-wrapper">
                <div className="table-header">
                    <div className="filter-container">
                        <input
                            type="text"
                            placeholder="Search by Name or Description"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="search-input"
                        />
                        <select
                            value={statusFilter}
                            onChange={e => setStatusFilter(e.target.value)}
                            className="status-filter"
                        >
                            <option value="all">All Statuses</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                            <option value="Pending">Pending Review</option>
                        </select>
                    </div>
                    <table>
                        <thead>
                        <tr>
                            <th onClick={() => handleColumnHeaderClick("name")}>
                                Rule Name {getArrow("name")}
                            </th>
                            <th onClick={() => handleColumnHeaderClick("type")}>
                                Type {getArrow("type")}
                            </th>
                            <th>Expression</th>
                            <th onClick={() => handleColumnHeaderClick("status")}>
                                Status {getArrow("status")}
                            </th>
                            <th onClick={() => handleColumnHeaderClick("lastModified")}>
                                Last Modified {getArrow("lastModified")}
                            </th>
                            <th onClick={() => handleColumnHeaderClick("nextReview")}>
                                Next Review {getArrow("nextReview")}
                            </th>
                            <th className="table-column-actions"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {paginatedRules.map((rule) => (
                            <RuleItem key={rule.RuleId} rule={rule}/>
                        ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <div className="pagination-buttons">
                            <span className="total-text">
                                {currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage + 1}-
                                {currentPage * itemsPerPage > filteredRules.length
                                    ? filteredRules.length
                                    : currentPage * itemsPerPage}{" "}
                                of {filteredRules.length}
                            </span>
                            <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}
                                    className="page-btn">
                                <FaArrowLeft/>
                            </button>
                            <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}
                                    className="page-btn">
                                <FaArrowRight/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};