import React, { useState, useMemo } from "react";
import { 
    Table, 
    TableContainer, 
    TableHead, 
    TableBody, 
    TableRow, 
    TableCell, 
    Paper,
    TablePagination,
    TableSortLabel,
    IconButton
} from '@mui/material';
import { FaGithub } from "react-icons/fa6";
import { MdKey, MdKeyOff } from "react-icons/md";
import { SiJira } from "react-icons/si";
import { FaBug } from "react-icons/fa";
import axios from "axios";
import "./SecretsScanner.scss";

interface Secret {
    secret_id: string;
    file_path: string;
    last_seen: string;
    line_number: number;
    repo_name: string;
    secret_type: string;
    default_branch: string;
    true_secret: boolean;
    secbug_link?: string;
}

interface SecretsScannerProps {
    secrets: Secret[];
    onRefresh: () => void;
}

type SortConfig = {
    field: keyof Secret;
    direction: 'asc' | 'desc';
};

export const SecretsScanner: React.FC<SecretsScannerProps> = ({ secrets, onRefresh }) => {
    const [sortConfig, setSortConfig] = useState<SortConfig>({ 
        field: "repo_name", 
        direction: "asc" 
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [isUpdating, setIsUpdating] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleSort = (field: keyof Secret) => {
        setSortConfig(prev => ({
            field,
            direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const handleTrueSecretToggle = async (secretId: string, currentStatus: boolean) => {
        if (isUpdating) return;
        
        const newStatus = !currentStatus;
        const confirmMessage = currentStatus 
            ? "Are you sure you want to mark this as a false positive?" 
            : "Are you sure you want to mark this as a true secret?";
            
        if (!window.confirm(confirmMessage)) return;
        
        setIsUpdating(true);
        setErrorMessage(null);
        try {
            await axios.put(
                `/secrets-scanner/update-true-secret/${secretId}`, 
                newStatus,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            onRefresh();
        } catch (error) {
            console.error('Error updating secret status:', error);
            setErrorMessage('Failed to update secret status');
        } finally {
            setIsUpdating(false);
        }
    };

    const formatJiraDescription = (secret: Secret): string => {
        const today = new Date().toISOString().split('T')[0];
        const dueDate = new Date();
        dueDate.setDate(dueDate.getDate() + 90);
        const dueDateStr = dueDate.toISOString().split('T')[0];
        
        const githubLink = getGitHubLink(secret);
        const filename = secret.file_path.split('/').pop() || secret.file_path;
        
        return `||Title|[SecretsScanner] [Repo: ${secret.repo_name}] [CWE-259] [File: ${filename}]|
||Application|Test|
||Flaw|[Flaw Link|${githubLink}]|
||Status|New|
||Scan Type|Secret Scanner|
||First Found Date|${today}|
||Last Seen Date|${secret.last_seen}|
||Due Date|${dueDateStr}|
||Severity|Sev-2|
||File Path|${secret.file_path}|
||Bug Source|DevSecOps|
||CWE|259|
||Component Filename|${filename}|
||File Line Number|${secret.line_number}|
||Category|Use of Hard-coded Password|`;
    };

    const handleCreateJiraTicket = async (secret: Secret) => {
        const filename = secret.file_path.split('/').pop() || secret.file_path;

        if (isUpdating) return;
        
        setIsUpdating(true);
        setErrorMessage(null);
        try {
            const response = await axios.post(
                '/jira/tickets',
                {
                    projectKey: "TESTPS",
                    summary: `[SecretsScanner] [Repo: ${secret.repo_name}] [CWE-259] [File: ${filename}]`,
                    description: formatJiraDescription(secret),
                    issueType: "TestSecBug"
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            const ticketKey = response.data;
            const jiraLink = `https://ceridian.atlassian.net/browse/${ticketKey}`;
            await axios.put(
                `/secrets-scanner/update-secbug-link/${secret.secret_id}`,
                { secbugLink: jiraLink },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            onRefresh();
        } catch (error) {
            console.error('Error creating Jira ticket:', error);
            setErrorMessage('Failed to create JIRA ticket');
        } finally {
            setIsUpdating(false);
        }
    };

    const sortedSecrets = useMemo(() => {
        const processed = [...secrets];
        
        if (sortConfig.field) {
            processed.sort((a, b) => {
                const aValue = a[sortConfig.field];
                const bValue = b[sortConfig.field];
                
                if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
                }
                
                const aString = String(aValue).toLowerCase();
                const bString = String(bValue).toLowerCase();
                
                return sortConfig.direction === 'asc'
                    ? aString.localeCompare(bString)
                    : bString.localeCompare(aString);
            });
        }
        
        return processed;
    }, [secrets, sortConfig]);

    const getTypeTooltip = (type: string) => {
        return type.includes("High Entropy String") 
            ? `Entropy checks detect unstructured secrets by flagging strings with high entropy scores, which indicate high randomness or unpredictability.` 
            : undefined;
    };

    const getGitHubLink = (secret: Secret) => {
        const filePath = secret.file_path.substring(secret.file_path.indexOf('/') + 1);
        return `https://github.com/DayforceGlobal/${secret.repo_name}/blob/${secret.default_branch}/${filePath}#L${secret.line_number}`;
    };

    const renderJiraIcon = (secret: Secret) => {
        if (secret.secbug_link) {
            return (
                <IconButton
                    size="small"
                    href={secret.secbug_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="jira-ticket-button"
                    title="View SECBUG"
                >
                    <SiJira />
                </IconButton>
            );
        }

        return (
            <IconButton
                size="small"
                onClick={() => handleCreateJiraTicket(secret)}
                disabled={isUpdating}
                className="jira-ticket-button"
                title="Create SECBUG"
            >
                <FaBug />
            </IconButton>
        );
    };

    return (
        <div className="application-table-container">
            {errorMessage && (
                <div className="error-message">
                    {errorMessage}
                </div>
            )}
            <TableContainer component={Paper} className="application-table-paper">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'repo_name'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('repo_name')}
                                >
                                    Repo Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'file_path'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('file_path')}
                                >
                                    File Path
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'line_number'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('line_number')}
                                >
                                    Line Number
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'secret_type'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('secret_type')}
                                >
                                    Type
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.field === 'last_seen'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('last_seen')}
                                >
                                    Last Seen
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedSecrets
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((secret) => (
                                <TableRow key={secret.secret_id} hover>
                                    <TableCell>{secret.repo_name}</TableCell>
                                    <TableCell>
                                        {secret.file_path}
                                    </TableCell>
                                    <TableCell>{secret.line_number}</TableCell>
                                    <TableCell title={getTypeTooltip(secret.secret_type)}>
                                        {secret.secret_type}
                                    </TableCell>
                                    <TableCell>{secret.last_seen}</TableCell>
                                    <TableCell>
                                        <div className="action-buttons">
                                            <IconButton
                                                size="small"
                                                href={getGitHubLink(secret)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="github-link-button"
                                                title="View on GitHub"
                                            >
                                                <FaGithub />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={() => handleTrueSecretToggle(secret.secret_id, secret.true_secret)}
                                                disabled={isUpdating}
                                                className="secret-toggle-button"
                                                title={secret.true_secret ? "Mark as False Positive" : "Mark as True Secret"}
                                            >
                                                {secret.true_secret ? 
                                                    <MdKey /> : 
                                                    <MdKeyOff />
                                                }
                                            </IconButton>
                                            {renderJiraIcon(secret)}
                                        </div>
                                    </TableCell>
                                </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            
            <TablePagination
                component="div"
                count={sortedSecrets.length}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                }}
                className="application-table-pagination"
            />
        </div>
    );
};

export default SecretsScanner;