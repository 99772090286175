import React, { useEffect } from "react";
import "./Dashboard.scss";
import axios from "axios";
import { Favorite } from "./Favorites/Favorite";
import { useQuery, useQueryClient } from "react-query";
import { FavoritesList } from "./Favorites/FavoritesList";
import { useLocation } from "react-router-dom";
import AnimatedShieldLoader from './AnimatedShieldLoader';
import { ApplicationsCard } from './DashboardCards/ApplicationsCard';
import { SecretsScannerCard } from './DashboardCards/SecretsScannerCard';
import { ThreatModelCard } from './DashboardCards/ThreatModelCard';
import { VulnerabilitiesChartCard } from './DashboardCards/VulnerabilitiesChartCard';

interface Secret {
    secret_id: string;
    file_path: string;
    last_seen: string;
    line_number: number;
    repo_name: string;
    secret_type: string;
    default_branch: string;
    true_secret: boolean;
}

interface ThreatModel {
    security_relevance_score: number;
}

const fetchData = async (): Promise<Favorite[]> => {
    let favorites = await axios.get(`/favorites`);
    return favorites.data.favorites;
}

const fetchSecrets = async (): Promise<Secret[]> => {
    try {
        const repoName = '-';
        const response = await axios.get('/secrets-scanner/' + repoName.toLowerCase());
        return response.data;
    } catch (error) {
        console.error("Error fetching secrets:", error);
        return [];
    }
};

const fetchThreatModels = async (): Promise<ThreatModel[]> => {
    try {
        const response = await axios.get('/threat-modeling');
        return response.data;
    } catch (error) {
        console.error("Error fetching threat models:", error);
        return [];
    }
};

export const Dashboard: React.FC = () => {
    const {
        data,
        isLoading: favoritesLoading
    } = useQuery("favorites", fetchData);

    const { 
        data: threatModels,
        isLoading: threatModelsLoading 
    } = useQuery('threatModels', fetchThreatModels);
    
    const { 
        data: secrets,
        isLoading: secretsLoading 
    } = useQuery('secrets', fetchSecrets);

    const queryClient = useQueryClient();
    const location = useLocation();

    useEffect(() => {
        queryClient.invalidateQueries("favorites")
    }, [location, queryClient]);

    const refreshData = async () => {
        queryClient.invalidateQueries("favorites")
    }

    const secretStats = React.useMemo(() => {
        if (!secrets) return { total: 0, keywords: 0, jwt: 0, entropy: 0, other: 0 };
        const trueSecrets = secrets.filter(s => s.true_secret);
        const keywords = trueSecrets.filter(s => s.secret_type === 'Secret Keyword').length;
        const jwt = trueSecrets.filter(s => s.secret_type === 'JSON Web Token').length;
        const entropy = trueSecrets.filter(s => s.secret_type.includes('High Entropy')).length;
        const total = trueSecrets.length;
        const other = total - (keywords + jwt + entropy);
        return {
            total,
            keywords,
            jwt,
            entropy,
            other
        };
    }, [secrets]);

    if (favoritesLoading || threatModelsLoading || secretsLoading) {
        return <div id="loader">
            <AnimatedShieldLoader />
        </div>
    }

    return (
        <div className="dashboard-container">
            <div className="dashboard-stats">
                <ApplicationsCard />
                <SecretsScannerCard secretStats={secretStats} />
                <ThreatModelCard threatModels={threatModels} />
                <VulnerabilitiesChartCard />
            </div>

            {data && data.length > 0 &&
                <FavoritesList items={data || []} refreshFn={refreshData} />
            }
        </div>
    );
};

export default Dashboard;