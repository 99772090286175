import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { Oval } from 'react-loader-spinner';
import { FaPlus } from 'react-icons/fa';
import { MdCheck, MdError } from "react-icons/md";

interface AddThreatModelModalProps {
    isOpen: boolean;
    onClose: () => void;
}

interface ValidationErrors {
    errors: Record<string, string[]>;
    status: number;
    title: string;
    traceId: string;
    type: string;
}

const isValidationError = (data: unknown): data is ValidationErrors => {
    return (
        typeof data === 'object' &&
        data !== null &&
        'errors' in data &&
        'status' in data &&
        'title' in data
    );
};

const AddThreatModelModal: React.FC<AddThreatModelModalProps> = ({ isOpen, onClose }) => {
    const [jiraTickets, setJiraTickets] = useState('');
    const [jql, setJql] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);
        setSuccessMessage(null);
    
        let analysisType: string;
        let content: string;
    
        if (jiraTickets) {
            analysisType = 'tickets';
            content = jiraTickets;
        } else if (jql) {
            analysisType = 'jql';
            content = jql;
        } else {
            setError('Please fill either Jira Ticket(s) or JQL field.');
            setIsSubmitting(false);
            return;
        }
    
        try {
            const messagePayload = {
                message: JSON.stringify({
                    ANALYSIS_TYPE: analysisType,
                    CONTENT: content
                })
            };
    
            const response = await axios.post('/threat-modeling/send-to-sns', messagePayload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            if (response.status === 200) {
                setSuccessMessage('Request sent. Please wait for email notification upon completion.');
                setJiraTickets('');
                setJql('');
                setTimeout(() => setSuccessMessage(null), 5000);
            } else {
                setError('Failed to send message to SNS. Please try again.');
            }
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                console.error('Axios error submitting threat model:', axiosError.response?.data);
                
                if (axiosError.response && isValidationError(axiosError.response.data)) {
                    const errorData = axiosError.response.data;
                    const errorMessages = Object.values(errorData.errors).flat();
                    setError(`Validation errors: ${errorMessages.join(', ')}`);
                } else {
                    setError(`Error: ${axiosError.message}`);
                }
            } else if (error instanceof Error) {
                console.error('Error submitting threat model:', error.message);
                setError(`An unexpected error occurred: ${error.message}`);
            } else {
                console.error('Unknown error submitting threat model:', error);
                setError('An unknown error occurred. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content add-threat-model-modal">
                <h3>
                    <FaPlus className="icon" />
                    Custom Threat Model
                </h3>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="jiraTickets">Jira Ticket(s):</label>
                        <input
                            type="text"
                            id="jiraTickets"
                            value={jiraTickets}
                            onChange={(e) => {
                                setJiraTickets(e.target.value);
                                setJql('');
                            }}
                            placeholder="Enter Jira ticket(s) separated by commas"
                            disabled={!!jql || isSubmitting}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="jql">JQL Query:</label>
                        <textarea
                            id="jql"
                            value={jql}
                            onChange={(e) => {
                                setJql(e.target.value);
                                setJiraTickets('');
                            }}
                            placeholder="Enter JQL query"
                            disabled={!!jiraTickets || isSubmitting}
                        />
                    </div>
                    {error && <div className="error-message"><MdError /> {error}</div>}
                    {successMessage && <div className="success-message"><MdCheck /> {successMessage}</div>}
                    <div className="button-group">
                        <button type="button" onClick={onClose} className="cancel-btn" disabled={isSubmitting}>
                            Close
                        </button>
                        <button type="submit" className="submit-btn" disabled={isSubmitting}>
                            {isSubmitting ? (
                                <>
                                    <Oval
                                        visible={true}
                                        height="16"
                                        width="16"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="loading"
                                    />
                                    Submitting...
                                </>
                            ) : (
                                <>
                                    Generate
                                </>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddThreatModelModal;