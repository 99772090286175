import React, { useEffect, useState } from 'react';
import { LineChartRequest } from './LineChartRequest';
import { MultiselectOption } from '../shared/Multiselect/MultiselectOption';
import { CartesianGrid, Label, Legend, Line, LineChart, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import JiraStatisticFilter from '../shared/JiraStatisticFilter';
import axios from 'axios';

import './VulnerabilityRemediationExpandedChart.scss';

import { JiraStatisticAppFamilyFilter, JiraStatisticScale } from '../shared/JiraStatisticFilterConstants';
import LineChartBase from './LineChartBase';
import { RemediateTime } from './RemediateTime';

interface RemediateTimeChartProps {
    applications: MultiselectOption[];
    productFamilies: MultiselectOption[];
}

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">{`Date: ${label}`}</p>
                {payload.map((entry: any, index: number) => (
                    <p key={index} style={{ color: entry.color }}>
                        {`${entry.name}: ${entry.value} days`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

const RemediateTimeChart: React.FC<RemediateTimeChartProps> = ({ applications, productFamilies }) => {
    const defaultData: RemediateTime[]= [];
    const [data, setData] = useState<RemediateTime[]>(defaultData);
    const {selectedNames, selectedScale, selectedDateRange, selectedNameType, appChanged, appFamilyChanged, scaleChanged, dateRangeChanged, customizedAxisTick} = LineChartBase();

    const requestData = async () => {
        let request: LineChartRequest = {
            scale: selectedScale ?? JiraStatisticScale.Day,
            startDate: selectedDateRange.startDate ?? new Date(),
            endDate: selectedDateRange.endDate ?? new Date(),
            nameType: selectedNameType ?? JiraStatisticAppFamilyFilter.Application,
            selectedNames: selectedNames ?? []
        }
        
        const response = await axios.post('/jirastatistic/getRemediation', request)
        setData(response.data.map((x: RemediateTime) => {
            x.averageCriticalRemediationTime = Math.ceil(x.averageCriticalRemediationTime);
            x.averageHighRemediationTime = Math.ceil(x.averageHighRemediationTime);
            x.averageMediumRemediationTime = Math.ceil(x.averageMediumRemediationTime);
            x.averageLowRemediationTime = Math.ceil(x.averageLowRemediationTime);
            return x;
        }));
    }

    useEffect(() => {
        if(selectedNames && selectedNames.length > 0){
            requestData();
        }
        // eslint-disable-next-line
    }, [selectedNames, selectedScale, selectedDateRange, selectedNameType]);

    return (
        <div className='statistic-pane'>
            <div className='chart-title'>
                <h4>Mean Time To Remediate</h4>
            </div>
            <JiraStatisticFilter 
                applications={applications} 
                applicationChangedCallback={appChanged} 
                productFamilies={productFamilies} 
                productFamilyChangedCallback={appFamilyChanged}
                scaleChangedCallback={scaleChanged}
                dateRangeChangedCallback={dateRangeChanged}
                defaultAppFamilyFilter={selectedNameType}
                defaultStartDate={selectedDateRange.startDate}
                defaultEndDate={selectedDateRange.endDate}
                defaultScale={selectedScale}
                showScale={true}
                id="remediation"
            />
            
            {data.length === 0 && 
                <div className='chart'><label className='text-info'>No data available</label></div>
            }
            {data.length > 0 &&
                <div className='chart'>
                    <div className='chart-pair'>
                        <div className='one-chart'>
                            <ResponsiveContainer height={400} width="100%">
                                <LineChart 
                                    data={data}
                                    margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Legend 
                                        height={36}
                                        wrapperStyle={{
                                            paddingTop: '20px'
                                        }}
                                    />
                                    <XAxis 
                                        dataKey="timeStamp" 
                                        padding="no-gap" 
                                        interval={0} 
                                        tick={customizedAxisTick}
                                        height={60}
                                    />
                                    <YAxis>
                                        <Label 
                                            angle={-90} 
                                            position="insideLeft"
                                            style={{ textAnchor: 'middle' }}
                                        >
                                            Days count
                                        </Label>
                                    </YAxis>
                                    <Line 
                                        name="Critical"
                                        strokeWidth={2} 
                                        type="monotone" 
                                        dataKey="averageCriticalRemediationTime" 
                                        stroke="#fd4433" 
                                        dot={false}
                                        activeDot={{ r: 6 }}
                                    />
                                    <Line 
                                        name="High"
                                        strokeWidth={2} 
                                        type="monotone" 
                                        dataKey="averageHighRemediationTime" 
                                        stroke="#FF6859" 
                                        dot={false}
                                        activeDot={{ r: 6 }}
                                    />
                                    <Line 
                                        name="Medium"
                                        strokeWidth={2} 
                                        type="monotone" 
                                        dataKey="averageMediumRemediationTime" 
                                        stroke="#FFCF44" 
                                        dot={false}
                                        activeDot={{ r: 6 }}
                                    />
                                    <Line 
                                        name="Low"
                                        strokeWidth={2} 
                                        type="monotone" 
                                        dataKey="averageLowRemediationTime" 
                                        stroke="#f1f997" 
                                        dot={false}
                                        activeDot={{ r: 6 }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default RemediateTimeChart;