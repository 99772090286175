import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { mockAppExceptions } from '../mock-data/appExceptions';
import { BsShieldFillCheck, BsShieldFillX, BsShieldFillExclamation } from "react-icons/bs";

const COLORS = {
    status: {
        Approved: '#1EB980',
        Pending: '#FFCF44',
        Rejected: '#fd4433'
    },
    severity: {
        Critical: '#fd4433',
        High: '#FF6859',
        Medium: '#FFCF44',
        Low: '#1EB980'
    }
};

const StatusIcon = ({ status }) => {
    switch (status) {
        case 'Approved':
            return <BsShieldFillCheck className="text-emerald-500" />;
        case 'Rejected':
            return <BsShieldFillX className="text-red-500" />;
        case 'Pending':
            return <BsShieldFillExclamation className="text-yellow-500" />;
        default:
            return null;
    }
};


export default function AppExceptionsView() {
    const statusData = Object.entries(mockAppExceptions.stats.byStatus).map(([name, value]) => ({
        name,
        value
    }));

    const severityData = Object.entries(mockAppExceptions.stats.bySeverity).map(([name, value]) => ({
        name,
        value
    }));
    
    return (
        <div className="p-6 max-w-7xl mx-auto">
            {/* App Information */}
            <div className="bg-gray-800 rounded-lg p-6 mb-6">
                <h1 className="text-2xl font-bold mb-4">{mockAppExceptions.name}</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className="bg-gray-700 p-4 rounded-lg">
                        <h3 className="text-gray-400 text-sm">Policy</h3>
                        <p>{mockAppExceptions.policyName}</p>
                    </div>
                    <div className="bg-gray-700 p-4 rounded-lg">
                        <h3 className="text-gray-400 text-sm">Last Scan</h3>
                        <p>{new Date(mockAppExceptions.lastScanDate).toLocaleDateString()}</p>
                    </div>
                    <div className="bg-gray-700 p-4 rounded-lg">
                        <h3 className="text-gray-400 text-sm">Risk Level</h3>
                        <p>{mockAppExceptions.riskLevel}</p>
                    </div>
                </div>
            </div>

            {/* Statistics */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                {/* Exception Status Chart */}
                <div className="bg-gray-800 rounded-lg p-6">
                    <h2 className="text-xl font-semibold mb-4">Exception Status</h2>
                    <div className="h-64">
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={statusData}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={60}
                                    outerRadius={80}
                                    paddingAngle={5}
                                    dataKey="value"
                                >
                                    {statusData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS.status[entry.name]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <div className="flex justify-center gap-4 mt-4">
                            {Object.entries(COLORS.status).map(([status, color]) => (
                                <div key={status} className="flex items-center gap-2">
                                    <div className="w-3 h-3 rounded-full" style={{ backgroundColor: color }}></div>
                                    <span className="text-sm">{status}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Exception Severity Chart */}
                <div className="bg-gray-800 rounded-lg p-6">
                    <h2 className="text-xl font-semibold mb-4">Exception Severity</h2>
                    <div className="h-64">
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={severityData}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={60}
                                    outerRadius={80}
                                    paddingAngle={5}
                                    dataKey="value"
                                >
                                    {severityData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS.severity[entry.name]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <div className="flex justify-center gap-4 mt-4">
                            {Object.entries(COLORS.severity).map(([severity, color]) => (
                                <div key={severity} className="flex items-center gap-2">
                                    <div className="w-3 h-3 rounded-full" style={{ backgroundColor: color }}></div>
                                    <span className="text-sm">{severity}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Quick Stats */}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
                <div className="bg-gray-800 rounded-lg p-6">
                    <h3 className="text-gray-400 text-sm">Total Exceptions</h3>
                    <p className="text-3xl font-bold">{mockAppExceptions.stats.totalExceptions}</p>
                </div>
                <div className="bg-gray-800 rounded-lg p-6">
                    <h3 className="text-gray-400 text-sm">Active Exceptions</h3>
                    <p className="text-3xl font-bold text-emerald-500">{mockAppExceptions.stats.activeExceptions}</p>
                </div>
                <div className="bg-gray-800 rounded-lg p-6">
                    <h3 className="text-gray-400 text-sm">Expiring Soon</h3>
                    <p className="text-3xl font-bold text-yellow-500">{mockAppExceptions.stats.expiringSoon}</p>
                </div>
            </div>

            {/* Exceptions List */}
            <div className="bg-gray-800 rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-4">Security Exceptions</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full">
                        <thead className="bg-gray-700">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Status</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Title</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Severity</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Category</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Created</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Expires</th>
                        </tr>
                        </thead>
                        <tbody className="bg-gray-800 divide-y divide-gray-700">
                        {mockAppExceptions.exceptions.map((exception) => (
                            <tr key={exception.id} className="hover:bg-gray-700">
                                <td className="px-6 py-4">
                                    <div className="flex items-center">
                                        <StatusIcon status={exception.status} />
                                        <span className="ml-2">{exception.status}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4">{exception.title}</td>
                                <td className="px-6 py-4">
                    <span
                        className={`px-2 py-1 rounded-full text-xs font-medium`}
                        style={{
                            backgroundColor: `${COLORS.severity[exception.severity]}20`,
                            color: COLORS.severity[exception.severity]
                        }}
                    >
                      {exception.severity}
                    </span>
                                </td>
                                <td className="px-6 py-4">{exception.category}</td>
                                <td className="px-6 py-4">
                                    {new Date(exception.createdDate).toLocaleDateString()}
                                </td>
                                <td className="px-6 py-4">
                                    {new Date(exception.expirationDate).toLocaleDateString()}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}