import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { motion } from "framer-motion";
import { IoIosApps } from "react-icons/io";
import { MdInfoOutline, MdEdit, MdCheck, MdError } from "react-icons/md";
import { SiJira } from "react-icons/si";
import { RiGitRepositoryFill } from "react-icons/ri";
import { Oval } from 'react-loader-spinner';
import { 
    BsShieldFillCheck, 
    BsShieldFillExclamation, 
    BsShieldFillX, 
    BsShieldSlash, 
    BsShieldFillMinus 
} from "react-icons/bs";
import { ScanResultsList } from "../ScanResults/ScanResultsList";
import AnimatedShieldLoader from '../AnimatedShieldLoader';
import { AssetRecordDetailsModel } from "./AssetRecordDetailsModel";
import SectionCard from "./SectionCard";

interface EditableFieldProps {
    value: string | string[] | boolean | undefined;
    onEdit: (value: string | boolean) => void;
    type?: 'text' | 'boolean';
}

const ARRAY_FIELDS = ['psArchitects', 'securityChampions', 'devLeads', 'productManager', 'productSecurityPM', 'tags'];

const fetchData = async (assetId: string | undefined): Promise<AssetRecordDetailsModel> => {
    const assetResponse = await axios.get(`/assets/${assetId}`);
    
    if(assetResponse?.data?.applicationId) {
        const [appResponse, scansResponse] = await Promise.all([
            axios.get(`/applications/details/${assetResponse.data.applicationId}`),
            axios.get(`/scan-results/${assetResponse.data.applicationId}`)
        ]);

        return {
            assetRecord: assetResponse.data,
            applicationDetails: {
                application: appResponse.data.app,
                scanResults: scansResponse.data.scanResults
            }
        };
    }
    
    return {
        assetRecord: assetResponse.data,
        applicationDetails: null
    };
};

const EditableField: React.FC<EditableFieldProps> = ({ value, onEdit, type = 'text' }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(() => {
        if (type === 'boolean') {
            return (value as boolean) ? 'Yes' : 'No';
        }
        return Array.isArray(value) ? value.join(', ') : value?.toString() ?? '';
    });

    const handleBlur = () => {
        setIsEditing(false);
        if (type === 'boolean') {
            onEdit(editedValue.toLowerCase() === 'yes');
        } else {
            onEdit(editedValue);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setEditedValue(e.target.value);
        if (type === 'boolean') {
            onEdit(e.target.value.toLowerCase() === 'yes');
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleBlur();
        }
    };

    return (
        <div className="editable-field">
            {isEditing || type === 'boolean' ? (
                type === 'boolean' ? (
                    <select
                        value={editedValue}
                        onChange={handleChange}
                        onBlur={() => setIsEditing(false)}
                        className="boolean-select"
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                ) : (
                    <input
                        type="text"
                        value={editedValue}
                        onChange={(e) => setEditedValue(e.target.value)}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        autoFocus
                    />
                )
            ) : (
                <div className="display-value">
                    <span>{editedValue || ''}</span>
                    <MdEdit className="edit-icon" onClick={() => setIsEditing(true)} />
                </div>
            )}
        </div>
    );
};

const AssetRecordDetails: React.FC = () => {
    const params = useParams<{ id: string }>();
    const [editedValues, setEditedValues] = useState<Record<string, any>>({});
    const [hasChanges, setHasChanges] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const { data, isLoading } = useQuery(
        `/scan-results/${params.id}`,
        () => fetchData(params.id)
    );

    const handleEdit = (field: string, value: string | boolean) => {
        setHasChanges(true);

        if (ARRAY_FIELDS.includes(field) && typeof value === 'string') {
            const arrayValue = value.split(',').map(x => x.trim());
            (data?.assetRecord as any)[field] = arrayValue;
        } else {
            (data?.assetRecord as any)[field] = value;
        }
        
        setEditedValues(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSave = async (isDraft: boolean = false) => {
        if (data?.assetRecord) {
            data.assetRecord.isDraft = isDraft;
        }

        setIsSaving(true);
        try {
            const response = await axios.post("/assets", data?.assetRecord);
            if(response.status === 200) {
                setErrorMessages([]);
                setSuccessMessage('Asset record saved successfully.');
                setTimeout(() => setSuccessMessage(null), 5000);
            }
        } catch (error: any) {
            if(error.response?.status === 400 && 
               error.response?.data?.validationFailures) {
                setErrorMessages(error.response.data.validationFailures);
                setTimeout(() => setErrorMessages([]), 10000);
            }
        } finally {
            setIsSaving(false);
        }
    };

    const handleDelete = async () => {
        if (!data?.assetRecord?.id) return;
        
        setIsDeleting(true);
        try {
            await axios.delete(`/assets/${data.assetRecord.id}`);
            window.location.href = '/assets';
        } catch (error: any) {
            setErrorMessages(['Failed to delete asset record']);
            setTimeout(() => setErrorMessages([]), 10000);
        } finally {
            setIsDeleting(false);
        }
    };

    const enableScaScan = async () => {
        if(!data?.applicationDetails?.application) return;
        await axios.post(`/applications/${data.applicationDetails.application.id}/sca-enable`);
        data.applicationDetails.application.isScaEnabled = true;
    };

    const getScaToken = async () => {
        if(!data?.applicationDetails?.application) return;
        const response = await axios.get(`/applications/${data.applicationDetails.application.id}/sca-token`);
        await navigator.clipboard.writeText(response.data.token);
    };

    const getValue = (field: string) => {
        return editedValues[field] ?? (data?.assetRecord as any)[field];
    };

    const renderInfoItem = (label: string, field: string, type: 'text' | 'boolean' = 'text') => (
        <div className="info-item">
            <span className="label">{label}</span>
            <EditableField
                value={getValue(field)}
                onEdit={(value) => handleEdit(field, value)}
                type={type}
            />
        </div>
    );

    const renderComplianceIcon = (status: string) => {
        const icons = {
            "PASSED": <BsShieldFillCheck className="status-icon passed" />,
            "DID_NOT_PASS": <BsShieldFillX className="status-icon failed" />,
            "CONDITIONAL_PASS": <BsShieldFillExclamation className="status-icon warning" />,
            "NOT_ASSESSED": <BsShieldSlash className="status-icon neutral" />,
            "DETERMINING": <BsShieldFillMinus className="status-icon neutral" />
        };
        return icons[status as keyof typeof icons] || null;
    };

    if (isLoading) {
        return (
            <div id="loader">
                <AnimatedShieldLoader />
            </div>
        );
    }

    const renderHeader = () => (
        <motion.header
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="asset-header"
        >
            <div className="header-content">
                <div className="app-icon">
                    <IoIosApps size={32} />
                </div>
                <div className="title-badges">
                    <h1>{data?.assetRecord.assetName}</h1>
                    <div className="badges">
                        {data?.assetRecord.isDraft && (
                            <div className="environment-badge draft">
                                <span>Draft</span>
                            </div>
                        )}
                        {data?.assetRecord.isProduction && (
                            <div className="environment-badge production">
                                <span>Production</span>
                            </div>
                        )}
                        {data?.assetRecord.isFlagship && (
                            <div className="environment-badge flagship">
                                <span>Flagship</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="header-actions">
                {data?.assetRecord.isDraft && (
                    <button 
                        onClick={handleDelete} 
                        className="delete-btn"
                        disabled={isDeleting || isSaving}
                    >
                        {isDeleting && (
                            <Oval
                                visible={true}
                                height="16"
                                width="16"
                                color="#ffffff"
                                secondaryColor="#ffffff"
                                ariaLabel="loading"
                                strokeWidth={4}
                            />
                        )}
                        {isDeleting ? 'Deleting...' : 'Delete'}
                    </button>
                )}
                {hasChanges && (
                    <>
                        {data?.assetRecord.isDraft && (
                            <button 
                                onClick={() => handleSave(true)} 
                                className="draft-btn"
                                disabled={isDeleting || isSaving}
                            >
                                {isSaving && (
                                    <Oval
                                        visible={true}
                                        height="16"
                                        width="16"
                                        color="#ffffff"
                                        secondaryColor="#ffffff"
                                        ariaLabel="loading"
                                        strokeWidth={4}
                                    />
                                )}
                                {isSaving ? 'Saving Draft...' : 'Save as Draft'}
                            </button>
                        )}
                        <button 
                            onClick={() => handleSave(false)} 
                            className="save-btn"
                            disabled={isDeleting || isSaving}
                        >
                            {isSaving && (
                                <Oval
                                    visible={true}
                                    height="16"
                                    width="16"
                                    color="#ffffff"
                                    secondaryColor="#ffffff"
                                    ariaLabel="loading"
                                    strokeWidth={4}
                                />
                            )}
                            {isSaving ? 'Saving...' : 'Save'}
                        </button>
                    </>
                )}
            </div>
        </motion.header>
    );

    const renderSecurityDetails = () => (
        <SectionCard title="Security Details">
            <div className="info-grid">
                {data?.applicationDetails?.application?.policyName && (
                    <div className="info-item">
                        <span className="label">Policy Name</span>
                        <span className="value">{data.applicationDetails.application.policyName}</span>
                    </div>
                )}

                {data?.applicationDetails?.application?.policyComplianceStatus && (
                    <div className="info-item">
                        <span className="label">Policy Compliance</span>
                        <div className="compliance-status">
                            {renderComplianceIcon(data.applicationDetails.application.policyComplianceStatus)}
                            <span>{data.applicationDetails.application.policyComplianceStatus}</span>
                        </div>
                    </div>
                )}

                {data?.applicationDetails?.application && (
                    <>
                        <div className="info-item">
                            <span className="label">Severity Counts</span>
                            <div className="severity-metrics">
                                <a href={`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${data.assetRecord.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done%20AND%20"Severity%5BDropdown%5D"%20%3D%20Sev-0`} 
                                className="metric critical" 
                                target="_blank" 
                                rel="noreferrer">
                                    C: {data.applicationDetails.application.criticalCount || 0}
                                </a>
                                <a href={`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${data.assetRecord.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done%20AND%20"Severity%5BDropdown%5D"%20%3D%20Sev-1`} 
                                className="metric high" 
                                target="_blank" 
                                rel="noreferrer">
                                    H: {data.applicationDetails.application.highCount || 0}
                                </a>
                                <a href={`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${data.assetRecord.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done%20AND%20"Severity%5BDropdown%5D"%20%3D%20Sev-2`} 
                                className="metric medium" 
                                target="_blank" 
                                rel="noreferrer">
                                    M: {data.applicationDetails.application.mediumCount || 0}
                                </a>
                                <a href={`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${data.assetRecord.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done%20AND%20"Severity%5BDropdown%5D"%20%3D%20Sev-3`} 
                                className="metric low" 
                                target="_blank" 
                                rel="noreferrer">
                                    L: {data.applicationDetails.application.lowCount || 0}
                                </a>
                            </div>
                        </div>

                        {data.applicationDetails.application.lastCompletedScanDate && (
                            <div className="info-item">
                                <span className="label">Last Scan</span>
                                <span className="value">
                                {new Date(data.applicationDetails.application.lastCompletedScanDate)
                                    .toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit'
                                    })
                                }
                                </span>
                            </div>
                        )}
                    </>
                )}

                {renderInfoItem('Product Family', 'productFamilyName')}
                {renderInfoItem('Application', 'applicationName')}
                {renderInfoItem('Component', 'componentName')}
            </div>
        </SectionCard>
    );

    const renderAssetDetails = () => (
        <SectionCard title="Asset Details">
            <div className="info-grid">
                {/* Row 1 */}
                <div style={{ gridColumn: "1" }}>
                    {renderInfoItem('Canonical ID', 'canonicalId')}
                </div>
                <div style={{ gridColumn: "2" }}>
                    {renderInfoItem('SAST & SCA Profile Name', 'veracodeName')}
                </div>
                <div style={{ gridColumn: "3" }}>
                    {renderInfoItem('DAST URL', 'dastUrl')}
                </div>
                <div style={{ gridColumn: "4" }}>
                    {renderInfoItem('Tags', 'tags')}
                </div>
                {/* Row 2 */}
                <div style={{ gridColumn: "1" }}>
                    {renderInfoItem('PS Architect', 'psArchitects')}
                </div>
                <div style={{ gridColumn: "2" }}>
                    {renderInfoItem('Program Manager', 'productSecurityPM')}
                </div>
                {/* Row 3 */}
                <div style={{ gridColumn: "1" }}>
                    {renderInfoItem('Dev Lead', 'devLeads')}
                </div>
                <div style={{ gridColumn: "2" }}>
                    {renderInfoItem('Product Manager', 'productManager')}
                </div>
                <div style={{ gridColumn: "3" }}>
                    {renderInfoItem('Security Champion(s)', 'securityChampions')}
                </div>
            </div>
        </SectionCard>
    );

    const renderDevSecOpsDetails = () => (
        <SectionCard title="DevSecOps Details" defaultOpen={false}>
            <div className="info-grid">
                {data?.applicationDetails?.application?.id && (
                    <div className="info-item">
                        <span className="label">ScanHub GUID</span>
                        <span className="value">{data.applicationDetails.application.id}</span>
                    </div>
                )}

                {data?.applicationDetails?.application?.externalId && (
                    <div className="info-item">
                        <span className="label">Veracode GUID</span>
                        <span className="value">{data.applicationDetails.application.externalId}</span>
                    </div>
                )}

                {data?.applicationDetails?.application && (
                    <div className="info-item">
                        <span className="label">Support Information</span>
                        <div className="value">
                            <div>Tier Level: {data.applicationDetails.application.appSecSupportTierLevel}</div>
                            <div>Policy: {data.applicationDetails.application.policyName}</div>
                        </div>
                    </div>
                )}

                {renderInfoItem('Import Results', 'importToJira', 'boolean')}
                {renderInfoItem('Production', 'isProduction', 'boolean')}
                {renderInfoItem('Flagship', 'isFlagship', 'boolean')}
                {renderInfoItem('Jira Project', 'jiraProject')}

                <div className="info-item">
                    <span className="label">Repo URL</span>
                    {data?.applicationDetails?.application?.repoUrl ? (
                        <span className="value">{data.applicationDetails.application.repoUrl}</span>
                    ) : (
                        <EditableField
                            value={getValue('githubRepoLink')}
                            onEdit={(value) => handleEdit('githubRepoLink', value)}
                        />
                    )}
                </div>
            </div>

            {data?.applicationDetails?.application && renderActionButtons()}
        </SectionCard>
    );

    const renderActionButtons = () => (
        <>
            <div className="app-actions">
                <a
                    href={data?.applicationDetails?.application?.repoUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="action-button repo"
                >
                    <RiGitRepositoryFill />
                    Repository
                </a>
                <a
                    href={`https://dayforce.atlassian.net/issues/?jql=issuetype%20%3D%20SECBUG%20AND%20%22Canonical%20ID%5BShort%20text%5D%22%20~%20%27%22${data?.assetRecord.canonicalId}%22%27%20and%20status%20%21%3D%20Closed%20AND%20status%20%21%3D%20Done`}
                    target="_blank"
                    rel="noreferrer"
                    className="action-button jira"
                >
                    <SiJira />
                    Jira Issues
                </a>
                <button
                    onClick={data?.applicationDetails?.application?.isScaEnabled ? undefined : enableScaScan}
                    disabled={data?.applicationDetails?.application?.isScaEnabled}
                    className="action-button sca"
                >
                    {data?.applicationDetails?.application?.isScaEnabled ? 'SCA-R Enabled' : 'Enable SCA-R'}
                </button>
                <button
                    onClick={getScaToken}
                    className="action-button"
                    hidden={true}
                >
                    <MdInfoOutline />
                    Print Token
                </button>
            </div>
            <div className="scan-results-container scans-list">
                <ScanResultsList items={data?.applicationDetails?.scanResults || []} />
            </div>
        </>
    );

    return (
        <div className="asset-record">
            {renderHeader()}

            {errorMessages.length > 0 && (
                <div className="error-message">
                    {errorMessages.map((message, index) => (
                        <div key={index}><MdError /> {message}</div>
                    ))}
                </div>
            )}

            {successMessage && (
                <div className="success-message">
                    <MdCheck />
                    {successMessage}
                </div>
            )}

            <div className="asset-content">
                {renderSecurityDetails()}
                {renderAssetDetails()}
                {renderDevSecOpsDetails()}
            </div>
        </div>
    );
};

export default AssetRecordDetails;