import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    // Eugene's:
    /*auth: {
        clientId: '6292c073-8908-4272-a263-3eea6c709782', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://TrialTenantboiW4QIJ.ciamlogin.com/', // Replace the placeholder with your tenant subdomain
        redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    },*/
    // Ceridian
    auth: {
        clientId: '152ca572-f949-4e2b-83f9-a51fed4927e7', // This is the ONLY mandatory field that you need to supply.
        // authority: 'https://289321e0-9db6-4644-b371-956e6056d9eb.ciamlogin.com/289321e0-9db6-4644-b371-956e6056d9eb', // Replace the placeholder with your tenant subdomain
        // authority: 'https://289321e0-9db6-4644-b371-956e6056d9eb.ciamlogin.com/289321e0-9db6-4644-b371-956e6056d9eb', // Replace the placeholder with your tenant subdomain
        authority: 'https://login.microsoftonline.com/289321e0-9db6-4644-b371-956e6056d9eb',
        redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    },
    // AUTHORITY='https://login.microsoftonline.com/289321e0-9db6-4644-b371-956e6056d9eb'
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            /**
             * Below you can configure MSAL.js logs. For more information, visit:
             * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
             */
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                //switch (level) {
                //    case LogLevel.Error:
                //        console.error(message);
                //        return;
                //    case LogLevel.Info:
                //        console.info(message);
                //        return;
                //    case LogLevel.Verbose:
                //        console.debug(message);
                //        return;
                //    case LogLevel.Warning:
                //        console.warn(message);
                //        return;
                //    default:
                //        return;
                //}
            },
        },
    },
};

type LoginRequestType = {
    scopes: string[]
}

export const loginRequest: LoginRequestType = {
    // scopes: ['api://6292c073-8908-4272-a263-3eea6c709782/sip.scope.default'],
    scopes: ['api://152ca572-f949-4e2b-83f9-a51fed4927e7/sip.scope.default'],
};

export const appRoles = {
    RoleUser: "sip.role.user",
    RoleAdmin: "sip.role.admin"
}
