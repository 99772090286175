import {ScanConfiguration} from "./ScanCongifurationTypes";
import React from "react";
import ScanConfigurationItem from "./ScanConfigurationItem";


type ScanConfigurationListProps = {
    scanConfigurations: ScanConfiguration[]
}

const ScanConfigurationList: React.FC<ScanConfigurationListProps> = ({scanConfigurations}) => {
    return (
        <div className={"table-list-wrapper"}>
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th className={"table-column-scan-type"}>Type</th>
                    <th className={"table-column-grow"}>CWE Ids (reporting)</th>
                    <th className={"table-column-actions"}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                    scanConfigurations.map(x => <ScanConfigurationItem key={x.id} scanConfiguration={x}/>)
                }
                </tbody>
            </table>
        </div>
    );
}

export default ScanConfigurationList