// src/mock-data/mockExceptions.ts

export const mockExceptions = [
    {
        id: "1",
        jiraTicketId: "SEC-1001",
        title: "Legacy Authentication Exception for Partner Integration",
        description: "Temporary exception for legacy authentication mechanism used by partner integration",
        status: "Approved",
        severity: "High",
        expirationDate: "2024-12-31T00:00:00Z",
        createdDate: "2024-01-15T10:00:00Z"
    },
    {
        id: "2",
        jiraTicketId: "SEC-1002",
        title: "TLS 1.1 Support for Legacy Client",
        description: "Exception to maintain TLS 1.1 support for legacy client systems",
        status: "Pending",
        severity: "Critical",
        expirationDate: "2024-06-30T00:00:00Z",
        createdDate: "2024-02-01T14:30:00Z"
    },
    {
        id: "3",
        jiraTicketId: "SEC-1003",
        title: "Unencrypted Data Storage for Cache",
        description: "Temporary exception for unencrypted cache storage",
        status: "Rejected",
        severity: "Medium",
        expirationDate: "2024-09-15T00:00:00Z",
        createdDate: "2024-01-20T09:15:00Z"
    }
];