import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import "./RuleCreate.scss";

export const RuleCreate: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [validationFailures, setValidationFailures] = React.useState<string[]>([]);

    useEffect(() => {
        // If we have state from the visual builder, populate the form
        if (location.state) {
            const form = document.getElementById('ruleForm') as HTMLFormElement;
            if (form) {
                form.ruleExpression.value = location.state.expression || '';
                form.ruleType.value = location.state.ruleType || 'LambdaExpression';
            }
        }
    }, [location]);

    function handleCancel(e: React.SyntheticEvent) {
        e.preventDefault();
        navigate("/rules");
    }

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const ruleName = e.currentTarget.ruleName.value;
        const description = e.currentTarget.ruleDescription.value;
        const expression = e.currentTarget.ruleExpression.value;
        const ruleType = e.currentTarget.ruleType.value;
        const actionOnSuccess = e.currentTarget.actionOnSuccess.value;
        const actionOnFailure = e.currentTarget.actionOnFailure.value;

        const payload = {
            ruleName: ruleName,
            description: description,
            expression: expression,
            ruleExpressionType: ruleType,
            actionOnSuccess: actionOnSuccess,
            actionOnFailure: actionOnFailure,
            status: "Active"
        };

        try {
            await axios.post("/rules", payload);
            navigate("/rules");
        } catch (err: any) {
            console.error(err);
            if (err.code === "ERR_BAD_REQUEST") {
                const failures = err.response.data.validationFailures;
                setValidationFailures(failures);
            }
        }
    }

    return (
        <div className="rule-create-form">
            <h1>Create Rule</h1>
            {validationFailures.length > 0 &&
                <table className="validation-failures">
                    <tbody>
                    {validationFailures.map((x, index) => (
                        <tr key={index}>
                            <td>{x}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            }
            <form id="ruleForm" autoComplete="off" onSubmit={handleSubmit}>
                <div className="form-control">
                    <label htmlFor="ruleName">Rule Name:</label>
                    <input type="text" id="ruleName" required/>
                </div>
                <div className="form-control">
                    <label htmlFor="ruleDescription">Description:</label>
                    <input type="text" id="ruleDescription" required/>
                </div>
                <div className="form-control">
                    <label htmlFor="ruleExpression">Expression:</label>
                    <textarea
                        id="ruleExpression"
                        required
                        rows={4}
                        defaultValue={location.state?.expression || ''}
                    />
                </div>
                <div className="form-control">
                    <label htmlFor="ruleType">Rule Type:</label>
                    <select
                        id="ruleType"
                        required
                        defaultValue={location.state?.ruleType || 'LambdaExpression'}
                    >
                        <option value="LambdaExpression">Lambda Expression</option>
                        <option value="JavaScript">JavaScript</option>
                    </select>
                </div>
                <div className="form-control">
                    <label htmlFor="actionOnSuccess">Action on Success:</label>
                    <select id="actionOnSuccess" required>
                        <option value="OutputExpression">Output Expression</option>
                        <option value="RequireManualReview">Require Manual Review</option>
                        <option value="AutoApprove">Auto Approve</option>
                    </select>
                </div>
                <div className="form-control">
                    <label htmlFor="actionOnFailure">Action on Failure:</label>
                    <select id="actionOnFailure" required>
                        <option value="RequireManualReview">Require Manual Review</option>
                        <option value="RejectAutomatically">Reject Automatically</option>
                    </select>
                </div>
                <div className="form-control">
                    <input type="button" id="ruleCreateCancel" value="Cancel" onClick={handleCancel}/>
                    <input type="submit" id="ruleCreate" value="Create"/>
                </div>
            </form>
        </div>
    );
};

export default RuleCreate;