import React, {useState} from "react";
import {ScanType} from "./ScanCongifurationTypes";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const scanTypes = Object.keys(ScanType)
    .map(x => parseInt(x))
    .filter(x => !isNaN(x))
    .map(x => {
        return {
            id: x,
            name: ScanType[x]
        }
    })

const ScanConfigurationCreate: React.FC = () => {
    const [validationFailures, setValidationFailures] = useState([])
    const navigate = useNavigate()

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const name = e.currentTarget.scanConfigurationName.value
        const scanType = e.currentTarget.scanConfigurationType.value
        const cweIds = e.currentTarget.scanConfigurationCweIds.value.split(",").map((x: string) => Number(x.trim())).filter((x: number) => !isNaN(x))

        // TODO: validate ^^

        const payload = {
            name: name,
            scanType: scanType,
            commonWeaknessEnumerationIds: cweIds
        }

        await axios.post("/scanConfigurations", payload)
            .then(() => {
                navigate("/scanConfigurations")
            })
            .catch(err => {
                console.error(e)

                if (err.code === "ERR_BAD_REQUEST") {
                    const failures = err.response.data.validationFailures
                    setValidationFailures(failures)
                }
            })
    }

    function handleCancel(e: React.SyntheticEvent) {
        e.preventDefault()
        navigate("/scanConfigurations")
    }

    return (
        <>
            <h1>Create Scan Configuration</h1>
            {/*TODO: extract to component*/}
            {validationFailures.length > 0 &&
                <table className={"validation-failures"}>
                    <tbody>
                    {validationFailures.map(x => <tr>
                        <td>{x}</td>
                    </tr>)}
                    </tbody>
                </table>
            }
            <form autoComplete={"off"} onSubmit={handleSubmit}>
                <div className={"form-control"}>
                    <label htmlFor="#scanConfigurationName">Name:</label>
                    <input
                        type="text"
                        id={"scanConfigurationName"}
                        name={"scanConfigurationName"}
                    />
                </div>
                <div className={"form-control"}>
                    <label htmlFor="#scanConfigurationType">Type:</label>
                    <select
                        id={"scanConfigurationType"}
                        name={"scanConfigurationType"}
                    >
                        {scanTypes.map(x => <option key={x.id} value={x.id}>{x.name}</option>)}
                    </select>
                </div>
                <div className={"form-control"}>
                    {/*TODO: make something better*/}
                    <label htmlFor="#scanConfigurationCweIds">CWE Ids:</label>
                    <input
                        type="text"
                        id={"scanConfigurationCweIds"}
                        name={"scanConfigurationCweIds"}
                        placeholder={"1, 2, 3"}
                    />
                </div>
                <div className={"form-control"}>
                    <input type="button" id={"scanConfigurationCreateCancel"} value={"Cancel"} onClick={handleCancel}/>
                    <input type="submit" id={"scanConfigurationCreate"} value={"Create"}/>
                </div>
            </form>
        </>
    );
};

export default ScanConfigurationCreate;