import React, { useState } from 'react';
import { FaTicketAlt, FaUsers, FaExclamationCircle, FaUserAlt, FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { MdSecurity, MdOutlineViewTimeline, MdDateRange, MdModeEdit, MdSave, MdEditOff } from 'react-icons/md';
import { SiJira } from "react-icons/si";
import { LuBrainCog } from "react-icons/lu";
import axios from 'axios';
import './ThreatModelDetails.scss';

interface ThreatModel {
    jira_issue_id: string;
    assignee: string;
    jira_release: string;
    jira_title: string;
    last_updated: string;
    priority: string;
    product_team: string;
    security_relevance_score: number;
    threat_model: string;
    feedback: 'Liked' | 'Disliked' | 'Neutral';
}

interface ThreatModelDetailsProps {
    model: ThreatModel;
    onClose: () => void;
    onModelUpdate: (model: ThreatModel) => void;
    onRegenerateRequest: () => void;
}

const MetadataItem = ({ icon, label, value }: { icon: React.ReactNode; label: string; value: string | number }) => (
    <div className="metadata-item">
        <div className="metadata-label">
            {icon}
            <span>{label}</span>
        </div>
        <div className="metadata-value">{value}</div>
    </div>
);

const ActionButton = ({ 
    onClick, 
    icon, 
    label, 
    variant 
}: { 
    onClick: () => void; 
    icon: React.ReactNode; 
    label: string; 
    variant?: string;
}) => (
    <button
        onClick={onClick}
        className={`action-button ${variant || ''}`}
    >
        {icon}
        <span>{label}</span>
    </button>
);

export const ThreatModelDetails: React.FC<ThreatModelDetailsProps> = ({
    model,
    onClose,
    onModelUpdate,
    onRegenerateRequest
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedThreatModel, setEditedThreatModel] = useState(model.threat_model);

    const handleFeedbackClick = async (feedback: 'Liked' | 'Disliked' | 'Neutral') => {
        const newFeedback = model.feedback === feedback ? 'Neutral' : feedback;
        try {
            const params = new URLSearchParams({
                columnName: 'feedback',
                newValue: newFeedback
            });
            await axios.put(`/threat-modeling/${model.jira_issue_id}?${params.toString()}`, null);
            onModelUpdate({ ...model, feedback: newFeedback });
        } catch (error) {
            // console.error("Error updating feedback:", error);
        }
    };

    const handleSave = async () => {
        try {
            const params = new URLSearchParams({
                columnName: 'threat_model',
                newValue: editedThreatModel
            });
            await axios.put(`/threat-modeling/${model.jira_issue_id}?${params.toString()}`, null);
            onModelUpdate({ ...model, threat_model: editedThreatModel });
            setIsEditing(false);
        } catch (error) {
            console.error("Error updating threat model:", error);
        }
    };

    const metadataItems = [
        { icon: <SiJira className="icon" />, label: "Jira Issue ID", value: model.jira_issue_id },
        { icon: <MdOutlineViewTimeline className="icon" />, label: "Fix Version", value: model.jira_release },
        { icon: <FaUsers className="icon" />, label: "Product Team", value: model.product_team },
        { icon: <FaExclamationCircle className="icon" />, label: "Priority", value: model.priority },
        { icon: <FaUserAlt className="icon" />, label: "Assignee", value: model.assignee },
        { icon: <MdSecurity className="icon" />, label: "Score", value: model.security_relevance_score },
        { icon: <MdDateRange className="icon" />, label: "Last Updated", value: model.last_updated.split('T')[0] }
    ];

    return (
        <div className="threat-model-details">
            <header className="header">
                <div className="header-container">
                    <div className="title-section">
                        <FaTicketAlt className="title-icon" />
                        <h1>{model.jira_title}</h1>
                    </div>
                    <div className="header-actions">
                        <div className="actions-section">
                            <ActionButton
                                onClick={() => handleFeedbackClick('Liked')}
                                icon={<FaThumbsUp />}
                                label="Like"
                                variant={`feedback ${model.feedback === 'Liked' ? 'liked' : ''}`}
                            />
                            <ActionButton
                                onClick={() => handleFeedbackClick('Disliked')}
                                icon={<FaThumbsDown />}
                                label="Dislike"
                                variant={`feedback ${model.feedback === 'Disliked' ? 'disliked' : ''}`}
                            />
                            
                            {isEditing ? (
                                <>
                                    <ActionButton
                                        onClick={() => setIsEditing(false)}
                                        icon={<MdEditOff />}
                                        label="Cancel"
                                        variant="cancel"
                                    />
                                    <ActionButton
                                        onClick={handleSave}
                                        icon={<MdSave />}
                                        label="Save"
                                        variant="primary"
                                    />
                                </>
                            ) : (
                                <>
                                    <ActionButton
                                        onClick={() => setIsEditing(true)}
                                        icon={<MdModeEdit />}
                                        label="Edit"
                                        variant="primary"
                                    />
                                    <ActionButton
                                        onClick={onRegenerateRequest}
                                        icon={<LuBrainCog />}
                                        label="Regenerate"
                                        variant="primary"
                                    />
                                </>
                            )}
                        </div>
                        <button className="close-button" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                </div>
            </header>

            <main className="content">
                <aside className="metadata">
                    <div className="metadata-container">
                        {metadataItems.map((item) => (
                            <MetadataItem
                                key={item.label}
                                icon={item.icon}
                                label={item.label}
                                value={item.value}
                            />
                        ))}
                    </div>
                </aside>

                <section className="model-content">
                    {isEditing ? (
                        <textarea
                            value={editedThreatModel}
                            onChange={(e) => setEditedThreatModel(e.target.value)}
                            className="model-editor"
                            spellCheck="false"
                            aria-label="Threat model content"
                        />
                    ) : (
                        <pre className="model-display">{model.threat_model}</pre>
                    )}
                </section>
            </main>
        </div>
    );
};

export default ThreatModelDetails;